import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSave,
  faCopy,
  faDownload,
  faTrash,
  faShareAlt,
  faBars,
  faChevronDown,
  faChevronUp,
  faTimes,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL, ENDPOINTS } from "../../apiConfig";
import DoctorSidebar from "./DoctorSidebar";
import ReportsView from "./ReportsView";

const HistoryDetails = () => {
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [patientThread, setPatientThread] = useState(null);
  const [patientSessions, setPatientSessions] = useState([]);
  const [patientPlan, setPatientPlan] = useState();
  const [isEditingPlan, setIsEditingPlan] = useState(false);
  const [expandedSessionId, setExpandedSessionId] = useState(null);
  const [generatedContent, setGeneratedContent] = useState(null);
  const [isEditingGenerated, setIsEditingGenerated] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isReportsSidebarOpen, setIsReportsSidebarOpen] = useState(false);
  const [visibleSessionsCount, setVisibleSessionsCount] = useState(5);
  const [transcriptWidth, setTranscriptWidth] = useState(256);
  const [transcript, setTranscript] = useState();
  const [recordings, setRecordings] = useState([]);

  useEffect(() => {
    fetchHistoryDetails();
  }, [id]);

  const fetchHistoryDetails = async () => {
    setLoading(true);

    if (!token) {
      console.error("No token found. Please login first.");
      setLoading(false);
      return;
    }
    try {
      const threadResponse = await axios.get(ENDPOINTS.GET_PATIENT_THREAD, {
        params: {
          patient_id: id,
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      setPatientThread(threadResponse.data);
      setPatientPlan(threadResponse.data.patient_plan || "N/A");

      const sessionsResponse = await axios.get(ENDPOINTS.GET_PATIENT_SESSIONS, {
        params: {
          patient_id: id,
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      setPatientSessions(sessionsResponse.data.results);
      setExpandedSessionId(sessionsResponse.data.results[0]?.id || null);
    } catch (error) {
      console.error("Error fetching history details:", error);
    } finally {
      setLoading(false);
    }
  };

  console.log("Patient Thread:\n", patientThread);
  console.log("Patient Sessions:\n", patientSessions);

  const convertDOBToAge = (date) => {
    const dob = new Date(date);
    const currentDate = new Date();

    let ageInYears = currentDate.getFullYear() - dob.getFullYear();
    const ageInMonths = currentDate.getMonth() - dob.getMonth();
    const ageInDays = currentDate.getDate() - dob.getDate();

    if (ageInMonths < 0 || (ageInMonths === 0 && ageInDays < 0)) {
      ageInYears--;
    }

    if (ageInYears === 0) {
      if (ageInMonths > 0) {
        return `${ageInMonths} Months`;
      } else if (ageInMonths === 0 && ageInDays > 0) {
        return `${ageInDays} Days`;
      } else {
        return "Less than a month";
      }
    }

    return `${ageInYears} Years`;
  };

  const toggleSessionExpansion = (sessionId) => {
    setExpandedSessionId(expandedSessionId === sessionId ? null : sessionId);
  };

  const toggleTranscriptionVisibility = (sessionText, sessionRecordigs) => {
    setIsSidebarOpen(!isSidebarOpen);
    setTranscript(sessionText);
    setRecordings(sessionRecordigs);
  };

  const toggleReportsView = () => {
    setIsReportsSidebarOpen(!isReportsSidebarOpen);
  };

  const handleSavePatientPlan = async () => {
    setIsEditingPlan(false);

    try {
      if (!token) {
        Swal.fire("Error", "Authentication required. Please login.", "error");
        return;
      }

      const updateData = {
        ...patientThread,
        patient_plan: patientPlan,
      };

      const response = await axios.put(
        `${API_BASE_URL}speech_history/${patientThread.id}/update/`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Patient plan updated successfully!", {
          position: "top-right",
          autoClose: 500,
        });
        setPatientThread(response.data);
      } else {
        throw new Error("Failed to update patient plan");
      }
    } catch (error) {
      console.error("Error updating patient plan:", error);
      Swal.fire(
        "Error",
        "Failed to update patient plan. Please try again later.",
        "error"
      );
    }
  };

  const handleSaveGeneratedText = async (session) => {
    if (generatedContent) {
      setIsEditingGenerated(false);

      try {
        if (!token) {
          Swal.fire("Error", "Authentication required. Please login.", "error");
          return;
        }

        const sanitizedContent = generatedContent
          .replace(/<p><br><\/p>/g, "")
          .replace(/<p>/g, "")
          .replace(/<\/p>/g, "<br>")
          .replace(/(<br\s*\/?>){2,}/g, "<br>")
          .replace(/<div>\s*<\/div>/g, "")
          .replace(/<div>/g, "")
          .replace(/<\/div>/g, "<br>")
          .replace(/&nbsp;/g, " ")
          .replace(/<br>\s*<\/b><br>/g, "</b>")
          .trim();

        const updateData = {
          ...session,
          notes: sanitizedContent,
          status: "Completed",
        };

        const response = await axios.put(
          `${API_BASE_URL}speech_history/${session.id}/update_session/`,
          updateData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          toast.success("Content saved successfully!", {
            position: "top-right",
            autoClose: 500,
          });
          setPatientSessions((prevDetails) =>
            prevDetails.map((s) =>
              s.id === session.id
                ? { ...s, notes: sanitizedContent }
                : s
            )
          );
        } else {
          toast.error("Failed to save note!", {
            position: "top-right",
            autoClose: 500,
          });
        }
      } catch (error) {
        console.error("Error saving generated content:", error);
        Swal.fire(
          "Error",
          "Failed to save content. Please try again later.",
          "error"
        );
      }
    }
  };

  const handleCopyContent = (content) => {
    // Create a temporary container to hold the HTML content
    const tempElement = document.createElement("div");
    tempElement.innerHTML = content; // Set the HTML content to be copied
    tempElement.style.position = "absolute";
    tempElement.style.left = "-9999px"; // Move it off-screen

    // Append the element to the body
    document.body.appendChild(tempElement);

    // Select the content inside the element
    const range = document.createRange();
    range.selectNodeContents(tempElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    // Try to copy the content
    try {
      document.execCommand("copy");
      toast.success("Content copied!", {
        position: "top-right",
        autoClose: 500,
      });
    } catch (err) {
      console.error("Failed to copy:", err);
      toast.error("Failed to copy the content. Please try again.", {
        position: "top-right",
        autoClose: 500,
      });
    }

    // Remove the temporary element from the DOM
    document.body.removeChild(tempElement);
  };

  const handleDownloadNote = async (session) => {
    setIsDownloading(true);

    const formData = new FormData();
    formData.append("text", session.notes);
    formData.append("speechthread_id", patientThread.id);
    formData.append("session_id", session.id);

    try {
      const response = await axios.post(ENDPOINTS.GENERATE_PDF, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      });
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "generated-note.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success("PDF generated successfully!", {
        position: "top-right",
        autoClose: 500,
      });
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to generate PDF. Please try again.", {
        position: "top-right",
        autoClose: 500,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDeleteNote = async (session) => {
    setIsDeleting(true);

    try {
      await axios.delete(ENDPOINTS.DELETE_NOTE, {
        params: {
          session_id: session.id,
          speechthread_id: patientThread.id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Session deleted successfully!", {
        position: "top-right",
        autoClose: 500,
      });
      setTimeout(() => {
        fetchHistoryDetails();
      }, 1000);
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to delete session. Please try again.", {
        position: "top-right",
        autoClose: 500,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleMouseDown = () => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const newWidth = window.innerWidth - e.clientX;
    setTranscriptWidth(Math.max(200, Math.min(newWidth, 600)));
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleLoadMoreSessions = () => {
    setVisibleSessionsCount((prevCount) => prevCount + 5);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100 overflow-hidden">
        <div className="w-7 h-7 border-4 border-t-4 border-t-transparent border-[#f2911b] rounded-full animate-spin"></div>
      </div>
    );
  }

  if (!patientThread) {
    return <div>No History detail found</div>;
  }

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-white">
      {/* <DoctorSidebar sessions={historyDetails.sessions} /> */}

      <div
        className="flex-1 overflow-auto scrollbar-custom"
        style={{ maxHeight: "100vh" }}
      >
        <div className="flex flex-col h-full bg-gray-100 mx-auto p-6 lg:p-12">
          <div className="w-full pt-4 mb-6">
            <div className="flex flex-wrap gap-2">
              <p className="flex items-center justify-center text-xs md:text-sm text-white font-bold bg-[#f2911b] rounded-md px-4 py-2">
                {patientThread.patient.firstname +
                  " " +
                  patientThread.patient.lastname}
              </p>
              <p className="flex items-center justify-center text-xs md:text-sm text-white font-bold bg-[#f2911b] rounded-md px-4 py-2">
                Age: {convertDOBToAge(patientThread.patient.date_of_birth)}
              </p>
              <p className="flex items-center justify-center text-xs md:text-sm text-white font-bold bg-[#f2911b] rounded-md px-4 py-2">
                {moment(patientThread.created_at).format(
                  "MMM DD, YYYY hh:mm A"
                )}
              </p>
              <button
                onClick={() => toggleReportsView()}
                className="flex items-center justify-center text-xs md:text-sm text-white font-bold bg-[#f2911b] border-2 border-[#f2911b] hover:bg-white hover:text-[#F2911B] rounded-md px-4 py-2 ml-auto"
                title="View Reports"
              >
                <FontAwesomeIcon icon={faEye} />
                &nbsp;View Reports
              </button>
            </div>
          </div>

          <div className="bg-white p-4 lg:p-6 rounded-lg shadow-md mb-6">
            <h2 className="text-lg lg:text-xl font-bold mb-4">Patient Plan</h2>
            {isEditingPlan ? (
              <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-4 lg:space-y-0 lg:space-x-4">
                <textarea
                  value={patientPlan}
                  onChange={(e) => setPatientPlan(e.target.value)}
                  className="border rounded p-2 flex-1 resize-none h-32 w-full lg:w-auto focus:outline-none focus:border-[#F2911B]"
                  placeholder="Enter patient plan..."
                />
                <button
                  onClick={handleSavePatientPlan}
                  className="flex items-center justify-center w-full lg:w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                >
                  <FontAwesomeIcon icon={faSave} />
                </button>
              </div>
            ) : (
              <div className="flex flex-col lg:flex-row items-start lg:items-center space-y-4 lg:space-y-0 lg:space-x-4">
                <p className="w-full text-justify">{patientPlan}</p>
                <button
                  onClick={() => setIsEditingPlan(true)}
                  className="flex items-center justify-center w-full lg:w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              </div>
            )}
          </div>

          <div className="bg-white p-4 lg:p-6 rounded-lg shadow-md">
            {patientSessions.length === 0 ? (
              <button className="bg-[#F2911B] text-white px-4 py-2 rounded w-full md:w-auto">
                Create New Session
              </button>
            ) : (
              <div>
                {patientSessions
                  .slice(0, visibleSessionsCount)
                  .map((session) => (
                    <div
                      key={session.id}
                      className="mb-4 border rounded-md p-4"
                    >
                      <div
                        className="flex justify-between items-center cursor-pointer py-2 sm:px-2 sm:py-3"
                        onClick={() => toggleSessionExpansion(session.id)}
                      >
                        <h3 className="text-sm sm:text-base md:text-lg font-semibold w-[85%]">
                          {moment(session.created_at).format(
                            "MMM DD, YYYY hh:mm A"
                          ) +
                            " - " +
                            session.title}
                        </h3>
                        <FontAwesomeIcon
                          className="text-sm sm:text-base md:text-lg ml-2 flex-shrink-0"
                          icon={
                            expandedSessionId === session.id
                              ? faChevronUp
                              : faChevronDown
                          }
                        />
                      </div>
                      {expandedSessionId === session.id && (
                        <div className="mt-4">
                          <div className="grid grid-cols-5 md:flex md:justify-start gap-2 mb-4">
                            <button
                              onClick={() => {
                                if (isEditingGenerated) {
                                  handleSaveGeneratedText(session);
                                } else {
                                  setGeneratedContent(session.notes);
                                  setIsEditingGenerated(true);
                                }
                              }}
                              className="flex items-center justify-center w-8 h-8 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                              title="Edit Note"
                            >
                              {isEditingGenerated ? (
                                <FontAwesomeIcon icon={faSave} />
                              ) : (
                                <FontAwesomeIcon icon={faEdit} />
                              )}
                            </button>
                            <button
                              onClick={() => handleCopyContent(session.notes)}
                              className="flex items-center justify-center w-8 h-8 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                              title="Copy Note"
                            >
                              <FontAwesomeIcon icon={faCopy} />
                            </button>
                            <button
                              onClick={() => handleDownloadNote(session)}
                              className="flex items-center justify-center w-8 h-8 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                              title="Generate PDF"
                            >
                              {isDownloading ? (
                                <BeatLoader size={2} color="white" />
                              ) : (
                                <FontAwesomeIcon icon={faDownload} />
                              )}
                            </button>
                            <button
                              onClick={() => handleDeleteNote(session)}
                              className="flex items-center justify-center w-8 h-8 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                              title="Delete Note"
                            >
                              {isDeleting ? (
                                <BeatLoader size={2} color="white" />
                              ) : (
                                <FontAwesomeIcon icon={faTrash} />
                              )}
                            </button>
                            {/* <button className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]">
                              <FontAwesomeIcon icon={faShareAlt} />
                            </button> */}
                            <button
                              onClick={() =>
                                toggleTranscriptionVisibility(
                                  session.text,
                                  session.recording_files
                                )
                              }
                              className="flex items-center justify-center w-8 h-8 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                              title="View Transcript"
                            >
                              <FontAwesomeIcon icon={faBars} />
                            </button>
                          </div>

                          <div
                            className={`bg-gray-100 p-4 rounded ${
                              isEditingGenerated ? "border border-gray-300" : ""
                            }`}
                          >
                            {isEditingGenerated ? (
                              <ReactQuill
                                value={generatedContent || session.notes}
                                onChange={(content) =>
                                  setGeneratedContent(content)
                                }
                              />
                            ) : (
                              <div
                                className="prose max-w-none leading-tight mt-4 space-y-0"
                                dangerouslySetInnerHTML={{
                                  __html: session.notes,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                {visibleSessionsCount < patientSessions.length && (
                  <button
                    onClick={handleLoadMoreSessions}
                    className="bg-[#f2911b] text-white px-4 py-2 rounded mt-4"
                  >
                    Load More
                  </button>
                )}
              </div>
            )}
          </div>

          {isReportsSidebarOpen && (
            <ReportsView
              speechThreadID={patientThread?.id}
              setIsReportsSidebarOpen={setIsReportsSidebarOpen}
            />
          )}

          {/* Transcription Sidebar */}
          {isSidebarOpen && (
            <div
              className="fixed top-0 right-0 h-full p-4 overflow-y-auto bg-white transition-transform duration-300"
              style={{ width: `${transcriptWidth}px`, maxWidth: "80vw" }}
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-bold">Transcript</h3>
                <button
                  onClick={() => setIsSidebarOpen(false)}
                  className="text-[#F2911B] hover:text-black"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div>
                <p className="text-gray-700 mb-4">{transcript}</p>
              </div>
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-bold">Listen in Audio</h3>
              </div>
              <div className="flex justify-between items-center mb-4">
                {recordings?.map((file, index) => (
                  <audio
                    key={index}
                    src={file.file_url}
                    controls
                    aria-label={`Recording ${index + 1}`}
                  />
                ))}
              </div>

              <div
                className="absolute top-0 left-0 h-full w-1 cursor-ew-resize"
                onMouseDown={handleMouseDown}
                style={{ backgroundColor: "#E8ECF4" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HistoryDetails;
