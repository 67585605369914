export const API_BASE_URL = "https://astrascribe-india-be.azurewebsites.net/";

export const ENDPOINTS = {
  LOGIN: `${API_BASE_URL}login/`,
  SIGNUP: `${API_BASE_URL}signup/`,
  
  GOOGLE_REDIRECT: `${API_BASE_URL}googleredirect/`,
  GOOGLE_CALLBACK: `${API_BASE_URL}googlecallback/`,

  GET_PATIENT_PROFILE_LIST: `${API_BASE_URL}patient_profile_list/`,
  POST_PATIENT_PROFILE_LIST: `${API_BASE_URL}patient_profile_list/`,

  GET_SPEECH_HISTORY: `${API_BASE_URL}speech_history/`,
  GET_PATIENT_THREAD: `${API_BASE_URL}speech_history/retrieve-patient-thread/`,
  GET_PATIENT_SESSIONS: `${API_BASE_URL}speech_history/retrieve-sessions/`,

  GET_AZURE_SPEECH_TOKEN: `${API_BASE_URL}azure_speech_token/`,
  TRANSCRIBE: `${API_BASE_URL}transcribe/`,
  
  GENERATE_PDF: `${API_BASE_URL}generate_pdf/`,
  DELETE_NOTE: `${API_BASE_URL}speech_history/delete_session/`,

  GET_PATIENT_FILES: `${API_BASE_URL}patient_file/`,
  UPLOAD_PATIENT_FILE: `${API_BASE_URL}patient_file/`,

  POST_TRANSCRIBE: `${API_BASE_URL}transcribe/`,

  POST_RECORDING_FILE: `${API_BASE_URL}session_recording/add_recording_files/`,
};
