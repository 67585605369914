import { useHistory , useLocation } from 'react-router-dom'; 
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { toast } from "react-toastify";
const FhirRedirect = () => {
  const history = useHistory();




  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [iss , setIss] = useState("");
  const [launch , setlaunch] = useState("");
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // get the iss and launch from url
    // const iss = searchParams.get('iss');
    let tempiss = searchParams.get('iss');
    let templaunch = searchParams.get('launch');
    setIss(tempiss);
    setlaunch(templaunch);
    // const launch = searchParams.get('launch');
    console.log("iss", tempiss);
    console.log("launch", templaunch);
    
    // if any of them is not present, alert the user and ask to try again
    const Dytoken = localStorage.getItem("token")
    console.log("dy token outside : " , Dytoken);
    if (tempiss && templaunch) {
      localStorage.setItem('iss', tempiss);
      const data= {
        iss: tempiss,
        launch : templaunch

      }
      // data.append('iss', tempiss);
      // data.append('launch', templaunch);
      console.log("dy token inside iss launch : " , Dytoken)
      console.log("data of auth url : " , data)
    
    axios.post(`https://astrascribe-india-be.azurewebsites.net/fhir_auth_url/`, 
      data,
      { headers: { Authorization: `Bearer ${Dytoken}` , 'Content-Type': 'application/json' } },
      
    ) 
      .then((response) => {
        // if the response is success, show the data  
        console.log(response);
        const url = response.data.url;
        // redirect to the url
        window.location.href = url;
      })
      .catch((error) => {
        // if the response is error, show the error
        console.log(error);
        alert('Error', error);
      })
      .finally(() => {
        // set loader to false
        setLoader(false);
      });
      
    }
    console.log("dy token outside dytoken condition : " , Dytoken)
    if (!Dytoken) {
      console.log("dy token inside : " , Dytoken)
      toast.warn("Session Expired. Please log in again.", {
        onClose: () => {
          window.location.href = `/?next=${encodeURIComponent(window.location.href)}&iss=${tempiss}&launch=${templaunch}`;

        },
      });
    }




  }, []);


  return (
    <div className="relative flex items-center justify-center min-h-screen ">

      <div className="text-center">
        <h1
          className="text-6xl font-bold mb-6"
        
        >
          Loading Application...
        </h1>
             <p>iss is that : {iss} </p>
             <p>launch is that : {launch} </p>
      </div>
    </div>
  );
};

export default FhirRedirect;