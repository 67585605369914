import React, { useEffect, useRef, useState } from 'react';
import { RealtimeTranscriber } from 'assemblyai/streaming';
import * as RecordRTC from 'recordrtc';


const App = () => {
  const realtimeTranscriber = useRef(null);
  const recorder = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [error, setError] = useState(null);

  const getToken = async () => {
    try {
      const Dytoken = localStorage.getItem('token');
      const response = await fetch('https://astrascribe-india-be.azurewebsites.net/assemblyai_token/', {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
          // Add Authorization header if needed
          'Authorization': `Bearer ${Dytoken}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch token');
      const data = await response.json();
      return data.token;
    } catch (err) {
      console.error('Error fetching token:', err);
      setError('Failed to fetch token');
      return null;
    }
  };

  const startTranscription = async () => {
    const token = await getToken();
    if (!token) return; // Exit if token fetching fails

    realtimeTranscriber.current = new RealtimeTranscriber({
      token,
      sampleRate: 16000,
    });

    const texts = {};
    realtimeTranscriber.current.on('transcript', (transcript) => {
      let msg = '';
      texts[transcript.audio_start] = transcript.text;
      const keys = Object.keys(texts).sort((a, b) => a - b);
      for (const key of keys) {
        if (texts[key]) {
          msg += ` ${texts[key]}`;
        }
      }
      setTranscript(msg);
    });
    realtimeTranscriber.current.on('session_information', (event) => {
        console.log('Session information:', event);
        console.log('Data:', event.data);
        });
    realtimeTranscriber.current.on('error', (event) => {
      console.error(event);
      setError('An error occurred');
      endTranscription();
    });

    realtimeTranscriber.current.on('close', (code, reason) => {
      // console.log(Connection closed: ${code} ${reason});
      realtimeTranscriber.current = null;
    });

    await realtimeTranscriber.current.connect();

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        recorder.current = new RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/webm;codecs=pcm',
          recorderType: RecordRTC.StereoAudioRecorder,
          timeSlice: 250,
          desiredSampRate: 16000,
          numberOfAudioChannels: 1,
          bufferSize: 4096,
          audioBitsPerSecond: 128000,
          ondataavailable: async (blob) => {
            if (!realtimeTranscriber.current) return;
            const buffer = await blob.arrayBuffer();
            realtimeTranscriber.current.sendAudio(buffer);
          },
        });
        recorder.current.startRecording();
      })
      .catch((err) => {
        console.error('Error accessing microphone:', err);
        setError('Error accessing microphone');
      });

    setIsRecording(true);
  };

  const endTranscription = async () => {
    setIsRecording(false);
    await realtimeTranscriber.current?.close();
    realtimeTranscriber.current = null;

    if (recorder.current) {
      recorder.current.stopRecording();
      recorder.current = null; // Reset recorder reference
    }
  };

  useEffect(() => {
    return () => {
      endTranscription(); // Clean up on unmount
    };
  }, []);

  return (
    <div className="App">
      <header>
        <h1 className="header__title">Real-Time Transcription</h1>
      </header>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div className="real-time-interface">
        <p id="real-time-title" className="real-time-interface__title">
          Click start to begin recording!
        </p>
        {isRecording ? (
          <button className="real-time-interface__button" onClick={endTranscription}>
            Stop Recording
          </button>
        ) : (
          <button className="real-time-interface__button" onClick={startTranscription}>
            Record
          </button>
        )}
      </div>
      <div className="real-time-interface__message">
        {transcript}
      </div>
    </div>
  );
};

export default App;