import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faShareAlt,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Summary from "../../Assets/images/summary1.png";
import MeetingNotes from "../../Assets/images/whitepaper1.png";
import { useHistory } from "react-router-dom";

const ShareHistory = ({ speechThreadId, viewMode = false }) => {
  const [sharableId, setSharableId] = useState(null);
  const [sharedLink, setSharedLink] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [generatedContent, setGeneratedContent] = useState(null);
  const [generatedStatus, setGeneratedStatus] = useState({
    summary: false,
    meeting_notes: false,
  });
  const [isStep1Open, setIsStep1Open] = useState(true);
  const [isStep2Open, setIsStep2Open] = useState(true);
  const generatedPostRef = useRef(null);

  const token = localStorage.getItem("token");
  const history = useHistory();

  useEffect(() => {
    if (viewMode && speechThreadId) {
      fetchSharedHistory(speechThreadId);
    }
  }, [speechThreadId, viewMode]);

  const fetchSharedHistory = async (id) => {
    try {
      const formData = new FormData();
      formData.append("id", id);

      const response = await axios.post(
        "https://astrascribe-india-be.azurewebsites.net/shared_speech_thread_detail/retrieve/",
        formData
      );

      setHistoryData(response.data);
      updateGeneratedStatus(response.data.speechthread_data);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Failed to fetch shared history. Please try again later.",
        confirmButtonColor: "#f2911b",
        confirmButtonText: "ok",
        customClass: {
          popup: 'max-w-xs',
          confirmButton: 'w-24',
          cancelButton: 'w-24',
        },
      });
    }
  };

  const updateGeneratedStatus = (data) => {
    setGeneratedStatus({
      summary: data.summary !== null,
      meeting_notes: data.meeting_notes !== null,
    });
  };

  const handleGenerateContent = async (type, title, isRegeneration = false) => {
    if (!token) {
      Swal.fire("Error", "Authentication required. Please login.", "error");
      return;
    }

    if (!isRegeneration && generatedStatus[type]) {
      setGeneratedContent({
        type,
        title,
        content: historyData.speechthread_data[type],
      });
      scrollToGeneratedPost();
      return;
    }

    try {
      Swal.fire({
        title: `Generating ${title}...`,
        html: `Generating content, please wait...`,
        allowOutsideClick: false,
        showConfirmButton: false,
        customClass: {
          popup: "w-96 h-64 flex flex-col items-start justify-start p-4",
          title: "text-lg",
          htmlContainer:
            "flex flex-col items-center justify-center w-full h-full",
        },
      });

      const apiUrls = {
        summary:
          "https://astrascribe-india-be.azurewebsites.net/generate_summary/",
        meeting_notes:
          "https://astrascribe-india-be.azurewebsites.net/generate_meeting_notes/",
      };

      const formData = new FormData();
      formData.append("text", historyData.speechthread_data.text);
      formData.append("SpeechThread_id", speechThreadId);

      const response = await axios.post(apiUrls[type], formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data) {
        setHistoryData({
          ...historyData,
          speechthread_data: {
            ...historyData.speechthread_data,
            [type]: response.data[type],
          },
        });

        setGeneratedStatus((prevStatus) => ({
          ...prevStatus,
          [type]: true,
        }));

        setGeneratedContent({
          type,
          title,
          content: response.data[type],
        });
        Swal.close();
        scrollToGeneratedPost();
      }
    } catch (error) {
      Swal.fire(
        "Error",
        `Failed to generate ${title}. Please try again later.`,
        "error"
      );
    }
  };

  const scrollToGeneratedPost = () => {
    setTimeout(() => {
      if (generatedPostRef.current) {
        generatedPostRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }, 100);
  };

  const getSharableId = async () => {
    if (!token) {
      Swal.fire("Error", "Authentication required. Please login.", "error");
      return;
    }

    if (!speechThreadId) {
      console.error("SpeechThread_id is undefined.");
      Swal.fire("Error", "SpeechThread_id is undefined.", "error");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("SpeechThread_id", speechThreadId);

      const response = await axios.post(
        "https://astrascribe-india-be.azurewebsites.net/share_speech_thread/share/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const sharableId = response.data.id;
      setSharableId(sharableId);
      shareHistory(sharableId);
    } catch (error) {
      console.error("Error getting sharable ID:", error);
      Swal.fire(
        "Error",
        "Failed to get sharable ID. Please try again later.",
        "error"
      );
    }
  };

  const shareHistory = async (id) => {
    try {
      const formData = new FormData();
      formData.append("id", id);

      const response = await axios.post(
        "https://astrascribe-india-be.azurewebsites.net/shared_speech_thread_detail/retrieve/",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
   // const link = `https://astrascribe-india-be.azurewebsites.net/shared/${response.data.id}`;  
      const link = `https://dev-clinical-notes-ggg7dpexhnhkccas.eastus-01.azurewebsites.net/shared/${response.data.id}`;
      // const link = `http://localhost:3000/shared/${response.data.id}`;

      setSharedLink(link);

      Swal.fire({
        title: "Shareable Link",
        text: link,
        confirmButtonText: "Copy Link",
        showCancelButton: true,
        customClass: {
          confirmButton: 'bg-[#F2911B] text-white hover:bg-orange-600',
          cancelButton: 'bg-gray-300 text-gray-800 hover:bg-gray-400',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          navigator.clipboard.writeText(link);
        }
      });
    } catch (error) {
      console.error("Error sharing history:", error);
      Swal.fire(
        "Error",
        "Failed to share history. Please try again later.",
        "error"
      );
    }
  };

  const generateOptions = [
    { title: "Summary", type: "summary", image: Summary },
    { title: "Notes", type: "meeting_notes", image: MeetingNotes },
  ];

  if (viewMode && historyData) {
    const { speechthread_data } = historyData;

    return (
      <div className="container mx-auto px-0 relative">
        <div className="flex flex-col items-center mt-4 py-10 bg-[#E8ECF4] rounded-2xl">
          <div className="space-y-6 mx-auto w-full max-w-5xl px-0">
            {/* Title, Date, and Message Section */}
            <div className="text-left mb-6">
              <h1 className="text-3xl font-bold">{speechthread_data.title}</h1>
              <p className="text-gray-500">
                {new Date(speechthread_data.created_at).toLocaleDateString()}
              </p>
              <div className="mt-2 p-4 bg-orange-50 bg-opacity-75 text-gray-700 rounded-md flex items-start">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="w-5 h-5 text-[#cfbebe] mr-2"
                />
                <p>
                  This conversation may reflect the link creator’s personalized
                  data, which isn’t shared and can meaningfully change how the
                  model responds.
                </p>
              </div>
              <hr className="mt-4 border-gray-300" />
            </div>

            {/* Displaying ICD Codes */}
            {speechthread_data.icd10_codes?.length > 0 && (
              <div className="relative bg-white shadow-md rounded-3xl p-6 mb-6">
                <div className="mb-4">
                  <p className="font-bold text-lg">ICD-10 Codes</p>
                </div>
                <ul>
                  {speechthread_data.icd10_codes.map((code, index) => (
                    <li key={index} className="flex justify-between items-center">
                      <div>
                        <strong>{code.code}</strong>: {code.description}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Displaying CPT Codes */}
            {speechthread_data.cpt_codes?.length > 0 && (
              <div className="relative bg-white shadow-md rounded-3xl p-6 mb-6">
                <div className="mb-4">
                  <p className="font-bold text-lg">CPT Codes</p>
                </div>
                <ul>
                  {speechthread_data.cpt_codes.map((code, index) => (
                    <li key={index} className="flex justify-between items-center">
                      <div>
                        <strong>{code.code}</strong>: {code.description}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Displaying Notes */}
            <div className="relative bg-white shadow-md rounded-3xl p-6 mb-6">
              <div className="mb-4">
                <p className="font-bold text-lg">Notes</p>
              </div>
              <div className="p-4 rounded-lg overflow-auto text-sm">
                <div
                  className="prose max-w-none"
                  dangerouslySetInnerHTML={{
                    __html: speechthread_data.notes,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <button
          onClick={() => history.push("/")}
          className="fixed bottom-0 left-0 w-full bg-[#2e2b2b] bg-opacity-30 text-white py-3 shadow-black shadow-2xl text-center "
        >
          <span className="text-black hover:text-white p-2 hover:bg-orange-400 rounded-full hover:border">
            Get started with Clinical Notes
          </span>
        </button>
      </div>
    );
  }

  return (
    <div>
      {!viewMode && (
        <button
          onClick={getSharableId}
          title="Share Your History"
          className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
        >
          <FontAwesomeIcon className="mr-2" icon={faShareAlt} />
        </button>
      )}
    </div>
  );
};

export default ShareHistory;
