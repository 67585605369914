

import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as speechsdk from "microsoft-cognitiveservices-speech-sdk";
import {
  faAngleDown,
  faAngleUp,
  faCopy,
  faWandMagicSparkles,
  faPause,
  faRedo,
  faTimes,
  faEdit,
  faDownload,
  faShareAlt,
  faSave,
  faMicrophone,
  faStop,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Summary from "../../Assets/images/summary1.png";
import eBook from "../../Assets/images/ebook1.png";
import Blog from "../../Assets/images/blog1.png";
import MeetingNotes from "../../Assets/images/whitepaper.png";
import Facebook from "../../Assets/images/facebook1.png";
import Twitter from "../../Assets/images/twitter1.png";
import LinkedIn from "../../Assets/images/linkedin1.png";
import Instagram from "../../Assets/images/instagram1.png";
import "../../App.css";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { FacebookButton, FacebookCount } from "react-social";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WaveSurfer from "wavesurfer.js";

const RecordScreen = () => {
  const token = localStorage.getItem("token");
  const history = useHistory();
  useEffect(() => {
    if (!token) {
      history.push("/");
    }
  }, [history, token]);
  const [isPostGenerated, setIsPostGenerated] = useState(false);
  const [postTitle, setPostTitle] = useState("");
const [postPatientName, setPostPatientName] = useState("");
const [createdDate, setCreatedDate] = useState("");
const [dateOfBirth, setDateOfBirth] = useState(""); // D.O.B
  const [isRecordingSaved, setIsRecordingSaved] = useState(false);
  const [patientName, setPatientName] = useState("");
  const [isPatientNamePopupVisible, setIsPatientNamePopupVisible] =
    useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [icd10Codes, setIcd10Codes] = useState([]);
  const [recognizer, setRecognizer] = useState(null);
  const [realTimeTranscript, setRealTimeTranscript] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const currentUrl = window.location.href;
  const [files, setFiles] = useState([]);
  const [isLiveRecordOpen, setIsLiveRecordOpen] = useState(true);
  const [isTranscriptOpen, setIsTranscriptOpen] = useState(true);
  const [isGenerateOpen, setIsGenerateOpen] = useState(false);
  const [isImgOpen, setIsImgOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [time, setTime] = useState(0);
  const [transcript, setTranscript] = useState([]);

  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isEditing, setIsEditing] = useState(true);
  const [speechThreadId, setSpeechThreadId] = useState(null);
  const [generatedStatus, setGeneratedStatus] = useState([]);
  const [generatedContent, setGeneratedContent] = useState("");
  const [isStepCompleted, setIsStepCompleted] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [savedGeneratedPosts, setSavedGeneratedPosts] = useState({});
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);
  const [profilePictureName, setProfilePictureName] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  const [coverImageName, setCoverImageName] = useState("");
  const uploadedFile = useRef(null);
  const [dummyState, setDummyState] = useState(false);
  const [isEditingGenerated, setIsEditingGenerated] = useState(false);
  const [isEditingSavedGenerated, setIsEditingSavedGenerated] = useState(false);
  const [currentTitle, setCurrentTitle] = useState("");
  const [generatedPost, setGeneratedPost] = useState(null);
  const [showButtons, setShowButtons] = useState(false);
  const intervalRef = useRef(null);
  const inputRef = useRef(null);
  const coverImageInputRef = useRef(null);
  const profilePictureInputRef = useRef(null);
  const audioRef = useRef(null);
  const Username = localStorage.getItem("Username") || "User";
  const generatedPostRef = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [currentSlide, setCurrentSlide] = useState("Notes");
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const switchSlide = (slide) => {
    setCurrentSlide(slide);

    // Check if the post for the current slide has already been generated
    if (slide === "Notes" && savedGeneratedPosts["Notes"]) {
      setGeneratedPost({
        title: "Notes",
        content: savedGeneratedPosts["Notes"],
        isHtmlContent: true,
      });
    } else if (slide === "Summary" && savedGeneratedPosts["Summary"]) {
      setGeneratedPost({
        title: "Summary",
        content: savedGeneratedPosts["Summary"],
        isHtmlContent: true,
      });
    } else {
      setGeneratedPost(null);
    }
  };

  const scrollToGeneratedPost = () => {
    setTimeout(() => {
      if (generatedPostRef.current) {
        generatedPostRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }, 100);
  };

  useEffect(() => {
    if (!token) {
      console.error("No token found. Please login first.");
    }
  }, [token]);

  // useEffect(() => {
  //   setProfilePictureUrl(null);
  //   if (!token) {
  //     console.error("No token found. Please login first.");
  //   } else {
  //     axios
  //       .get(
  //         "https://astrascribe-india-be.azurewebsites.net/profile_picture/",
  //         {
  //           headers: {
  //             Authorization: "Bearer " + token,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         // setProfilePictureUrl(response.data.image);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching profile picture:", error);
  //       });
  //   }
  // }, [token]);

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    uploadedFile.current = event.target.files[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      if (validImageTypes.includes(file.type)) {
        setProfilePictureName(file.name);
        const formData = new FormData();
        formData.append("image", file);
        formData.append("user", 1);

        axios
          .post(
            "https://astrascribe-india-be.azurewebsites.net/profile_picture/",
            formData,
            {
              headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            setProfilePictureUrl(response.data.image);
          })
          .catch((error) => {
            console.error("Error uploading profile picture:", error);
          });
      } else {
        console.error("Invalid file type. Please select an image file.");
      }
    }
  };

  const handleSaveGeneratedText = async () => {
    const sanitizedContent = generatedPost.content
      .replace(/<p><br><\/p>/g, "") // Remove empty paragraph tags
      .replace(/<p>/g, "<div>") // Replace paragraph tags with divs
      .replace(/<\/p>/g, "</div>"); // Close div tags instead of paragraphs

    setGeneratedPost({ ...generatedPost, content: sanitizedContent });
    setSavedGeneratedPosts((prev) => ({
      ...prev,
      [generatedPost.title]: sanitizedContent,
    }));
    setIsEditingGenerated(false);

    try {
      if (!token) {
        Swal.fire("Error", "Authentication required. Please login.", "error");
        return;
      }

      const dataToUpdate = {
        youtube_links: [],
        recording_file_names: files.map((file) => file.name),
        multiple_speakers: false,
        status: "completed",
        text: transcript,
        twitter_post:
          generatedPost.title === "Twitter" ? generatedPost.content : "",
        facebook_post:
          generatedPost.title === "Facebook" ? generatedPost.content : "",
        instagram_post:
          generatedPost.title === "Instagram" ? generatedPost.content : "",
        linkedin_post:
          generatedPost.title === "LinkedIn" ? generatedPost.content : "",
        meeting_notes:
          generatedPost.title === "Notes" ? generatedPost.content : "",
        summary: generatedPost.title === "Summary" ? generatedPost.content : "",
        whitepaper: "",
        blog_post: generatedPost.title === "Blog" ? generatedPost.content : "",
        ebook: generatedPost.title === "eBook" ? generatedPost.content : "",
        title: "Generated Post",
        user: 1,
        pdf_file: 0,
        picture_file: 0,
      };

      const response = await axios.put(
        `https://astrascribe-india-be.azurewebsites.net/speech_history/${speechThreadId}/update/`,
        dataToUpdate,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        toast.success("Content saved successfully!", {
          position: "top-right",
          autoClose: 500,
        });
      } else {
        throw new Error("Failed to save content");
      }
    } catch (error) {
      console.error("Error saving generated content:", error);
    }
  };

  const handleFileChange = (event) => {
    setFiles([...files, ...event.target.files]);
  };

  // Fetch Athena Token
  const getAthenaToken = async () => {
    try {
      const response = await axios.get('https://astrascribe-india-be.azurewebsites.net/athena_token/', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-CSRFToken': 'zlSnHrrhqim1cTn3jS8f3DFAB7XithUXbJnPWyEokqsJVXPpesY9R4ntXI1aiBQG',
        },
      });
      const athenaToken = response.data.access_token;
      localStorage.setItem('athenaToken', athenaToken);
      return athenaToken;
    } catch (error) {
      console.error('Error fetching Athena token:', error);
      Swal.fire("Error", "Unable to fetch Athena token.", "error");
      return null;
    }
  };

  // Patient Search
  const searchPatient = async (firstName, lastName) => {
    const athenaToken = await getAthenaToken();
    if (!athenaToken) return;
    try {
      const response = await axios.get(`https://astrascribe-india-be.azurewebsites.net/athena_patient_search/?firstname=${firstName}&lastname=${lastName}&athena_token=${athenaToken}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-CSRFToken': 'zlSnHrrhqim1cTn3jS8f3DFAB7XithUXbJnPWyEokqsJVXPpesY9R4ntXI1aiBQ',
        },
      });
      setPatients(response.data); // Store the search results
    } catch (error) {
      console.error('Error fetching patient data:', error);
      Swal.fire("Error", "Unable to search for patients.", "error");
    }
  };

  // Transcription Submission
  const submitTranscription = async (transcriptionText) => {
    const athenaToken = await getAthenaToken();
    if (!athenaToken) return;

    let formData = new FormData();
    formData.append("transcription_text", transcriptionText);
    formData.append("patient_name", selectedPatient?.name || patientName);
    formData.append("patient_date_of_birth", selectedPatient?.dob || dateOfBirth);

    try {
      const response = await axios.post('https://astrascribe-india-be.azurewebsites.net/transcribe/', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-CSRFToken': 'zlSnHrrhqim1cTn3jS8f3DFAB7XithUXbJnPWyEokqsJVXPpesY9R4ntXI1aiBQG',
        },
      });
      console.log("Transcription submitted:", response.data);
    } catch (error) {
      console.error('Error submitting transcription:', error);
      Swal.fire("Error", "Unable to submit transcription.", "error");
    }
  };

  // Patient Search Trigger
  const handlePatientSearch = async (firstName, lastName) => {
    try {
      console.log("Searching for patients...");
      
      const athenaToken = await getAthenaToken(); // Assume you have a function to get Athena Token
      const token = localStorage.getItem("token"); // Authorization Token
  
      // Only perform the search if firstName or lastName is provided (this step happens inside Swal now)
      const response = await axios.get(
        `https://astrascribe-india-be.azurewebsites.net/athena_patient_search/?firstname=${firstName}&lastname=${lastName}&athena_token=${athenaToken}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const fetchedPatients = response.data;
      console.log("Patients found: ", fetchedPatients);
  
      // Update the state with the fetched patients
      setPatients(fetchedPatients);
      if (fetchedPatients.length === 0) {
        Swal.fire('No patients found', 'Try again with different details.',);
      } else {
        return fetchedPatients;
        // Optionally show a success message
      }
    } catch (error) {
      console.error("Error fetching patient data:", error);
      Swal.fire('Error', 'Failed to fetch patient data. Please try again.', 'error');
    }
  };
  

  const handlePatientSelect = (e) => {
    const selectedPatientId = e.target.value;
    const selected = patients.find(patient => patient.id === selectedPatientId);
    setSelectedPatient(selected);
  };
  // Example of submission integration
  const handleSubmitTranscription = async () => {
    const transcriptionText = transcript.join(" ");
    await submitTranscription(transcriptionText);
  };



  useEffect(() => {
    if (selectedPatient) {
      console.log("Selected patient updated: ", selectedPatient);
    }
  }, [selectedPatient]);
  

  const handleStart = async () => {
    if (!isRecording) {
      try {
        let fetchedPatients = [];
        let selectedPatientId = null;
        let selectedPatient = null;
        let isSearchDone = false; // Track if the search has been done
        let selectHTML = ''; // Declare selectHTML outside
        const isMobile = window.innerWidth <= 768;
  
        // Show the initial Swal popup with first and last name inputs
        await Swal.fire({
          title: 'Search Patient',
          html: `
            <input id="firstName" class="swal2-input w-48 text-sm" placeholder="First Name">
            <input id="lastName" class="swal2-input w-48 text-sm" placeholder="Last Name">
            <div id="searchResults" class="text-sm">Waiting for search results...</div>
          `,
          focusConfirm: false,
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Search',
          position: isMobile ? 'top' : 'center',
          customClass: {
            popup: 'w-72 max-w-full', // Make the popup smaller
            title: 'text-lg',
            confirmButton: 'bg-[#F2911B] text-white hover:bg-[#e07e10] text-sm',
            cancelButton: 'text-sm'
          },
          preConfirm: async () => {
            if (isSearchDone) return; // Prevent another search after final done
  
            const firstName = Swal.getPopup().querySelector('#firstName').value;
            const lastName = Swal.getPopup().querySelector('#lastName').value;
  
            if (!firstName && !lastName) {
              Swal.showValidationMessage('Please enter first name or last name to search for patients.');
              return;
            }
  
            // Perform patient search
            fetchedPatients = await handlePatientSearch(firstName, lastName);
            console.log("Patients fetched: ", fetchedPatients);
  
            // If patients are found, update the Swal popup with a dropdown for selection
            if (fetchedPatients && fetchedPatients.length > 0) {
              selectHTML = `
                <select id="patientSelect" class="swal2-select">
                  ${fetchedPatients.map(patient => `
                    <option value="${patient.id}">
                      ${patient.name[0].given[0]} ${patient.name[0].family} - ${new Date(patient.birthDate).toLocaleDateString()}
                    </option>
                  `).join('')}
                </select>
              `;
  
              // Update Swal to show the dropdown and change button text to "Final Done" and "Final Cancel"
              Swal.update({
                title: 'Patients Found!',
                html: `
                  <input id="firstName" class="swal2-input w-48 text-sm" placeholder="First Name" value="${firstName}">
                  <input id="lastName" class="swal2-input w-48 text-sm" placeholder="Last Name" value="${lastName}">
                  <div id="searchResults" class="text-xs">${selectHTML}</div>
                `,
                confirmButtonText: 'Done', // Custom "Done" button text
                cancelButtonText: 'Cancel', // Custom "Cancel" button text
                showCancelButton: true, // Show the cancel button
                position: isMobile ? 'top' : 'center',
                customClass: {
                  popup: 'w-72 max-w-full', // Make the popup smaller
                  title: 'text-lg',
                  confirmButton: 'bg-[#F2911B] text-white hover:bg-[#e07e10] text-sm',
                  cancelButton: 'text-sm'
                },
                preConfirm: () => {
                  // Get selected patient ID from the dropdown
                  selectedPatientId = Swal.getPopup().querySelector('#patientSelect')?.value;
  
                  // If no patient is selected, show a validation message
                  if (!selectedPatientId) {
                    Swal.showValidationMessage('Please select a patient.');
                    return false; // Prevent the popup from closing
                  }
  
                  // Find the selected patient from the fetched patients
                  selectedPatient = fetchedPatients.find(patient => patient.id === selectedPatientId);
                  console.log('Selected Patient ID:', selectedPatientId);
                  console.log('Selected Patient Name:', selectedPatient.name[0].given[0], selectedPatient.name[0].family);
                  console.log('Selected Patient DOB:', selectedPatient.birthDate);
                  setSelectedPatient(selectedPatient);
                  console.log("Patient state updated in handleStart: ", selectedPatient);
  
                  return true; // Close the modal and proceed
                }
              });
  
              // Mark search as done to avoid re-execution
              isSearchDone = true;
              return false; // Prevents the popup from closing
            } else {
              Swal.update({
                title: 'No Patients Found',
                html: `
                  <input id="firstName" class="swal2-input w-48 text-sm" placeholder="First Name" value="${firstName}">
                  <input id="lastName" class="swal2-input w-48 text-sm" placeholder="Last Name" value="${lastName}">
                  <div id="searchResults" class="text-sm">No patients found</div>
                `,
                icon: null,
                position: isMobile ? 'top' : 'center',
                customClass: {
                  popup: 'w-72 max-w-full', // Decreased width and prevents overflow
                  title: 'text-lg',
                  confirmButton: 'bg-[#F2911B] text-white hover:bg-[#e07e10]  text-sm',
                  cancelButton: 'text-sm'
                },
              });
  
              return false;
            }
          },
        });
  
        // Proceed once the search is done and "Final Done" is clicked
      if (isSearchDone && selectedPatient) {
  const accessToken = await getAthenaToken();
  const encounters = await getAthenaEncounters(accessToken, selectedPatient.id);
  console.log("Fetched Encounters: ", encounters);

  if (encounters && encounters.length > 0) {
    const firstEncounter = encounters[0]; // Auto-select the first encounter
    console.log("Auto-selected Encounter ID:", firstEncounter.id);

    // Start the recording process directly
    console.log("Starting recording process...");
    const Dytoken = localStorage.getItem("token");
    console.log("Fetching Azure Speech token...");

    axios.get('https://astrascribe-india-be.azurewebsites.net/azure_speech_token/', {
      headers: {
        Authorization: `Bearer ${Dytoken}`,
      },
    }).then((tokenResponse) => {
      const { token, region } = tokenResponse.data;
      console.log(`Fetched token and region: ${token}, ${region}`);

      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(audioStream => {
          console.log("Microphone access granted:", audioStream);

          setIsRecording(true);
          setIsPaused(false);
          setTime(0);

          intervalRef.current = setInterval(() => {
            setTime((prevTime) => prevTime + 1);
          }, 1000);

          const newMediaRecorder = new MediaRecorder(audioStream);
          setMediaRecorder(newMediaRecorder);

          newMediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              setAudioChunks((prevChunks) => [...prevChunks, event.data]);
              saveRecordingLocally([...audioChunks, event.data]);
            }
          };

          newMediaRecorder.start();
          console.log("Setting up Azure Speech Translation configuration...");
          const speechTranslationConfig = speechsdk.SpeechTranslationConfig.fromAuthorizationToken(token, region);

          speechTranslationConfig.speechRecognitionLanguage = "hi-IN";
          speechTranslationConfig.addTargetLanguage("en");

          const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
          const autoDetectSourceLanguageConfig = speechsdk.AutoDetectSourceLanguageConfig.fromLanguages([
            "en-US"
          ]);

          const translator = speechsdk.TranslationRecognizer.FromConfig(
            speechTranslationConfig, autoDetectSourceLanguageConfig, audioConfig
          );

          setRecognizer(translator);
          
          const getSpeakerDetails = (speakerId) => {
            const normalizedId = speakerId?.toLowerCase(); // Normalize for case-insensitivity
            let speakerRole = 'Unknown'; 
            let bgColor = '#f0f0f0'; 
          
            if (normalizedId === 'guest-1') {
              speakerRole = 'Doctor';
              bgColor = '#e0f7fa';
            } else if (normalizedId === 'guest-2') {
              speakerRole = 'Patient';
              bgColor = '#ffe0b2';
            }
          
            return { role: speakerRole, bgColor };
          };
          let speakerCounter = 1; // Initialize speaker counter

          const getSpeakerId = (e) => {
            const currentTime = new Date().getTime();
          
            if (!e.result.speakerId) {
              // Temporary fallback to a unique identifier
              return `Guest-${currentTime % 2 === 0 ? 1 : 2}`;
            }
          
            return e.result.speakerId;
          };
          
          
          
          translator.recognizing = (s, e) => {
            
            const speakerId = getSpeakerId(e);
            // const { role, bgColor } = getSpeakerDetails(speakerId);
          
            console.log(`Recognizing: ${e.result.text} (Speaker: ${speakerId})`);
          
            // Update the real-time transcript to show only the current line
            setRealTimeTranscript([
              { speakerId, text: e.result.text },
            ]);
          };
          
          
          translator.recognized = (s, e) => {
            const speakerId = getSpeakerId(e);
            const { role, bgColor } = getSpeakerDetails(speakerId);
          
            console.log(`Recognized: ${e.result.text} (Speaker: ${speakerId})`);
          
            // Add the recognized text to the full transcript
            setTranscript((prev) => [
              ...(Array.isArray(prev) ? prev : []),
              { speakerId, text: e.result.text, role, bgColor },
            ]);
          };
          
          
          

          translator.canceled = (s, e) => {
            console.error(`Translation canceled: ${e.reason}`);
            if (e.reason === speechsdk.CancellationReason.Error) {
              console.error(`Error details: ${e.errorDetails}`);
            }
            translator.stopContinuousRecognitionAsync();
          };

          translator.sessionStopped = (s, e) => {
            console.log("Translation session stopped.");
            translator.stopContinuousRecognitionAsync();
          };

          translator.startContinuousRecognitionAsync(
            () => console.log("Speech translation started"),
            (err) => console.error("Error starting speech translation:", err)
          );

          console.log("Started media recording.");
        })
        .catch(error => {
          console.error("Error accessing microphone: ", error);
        });
    }).catch(error => {
      console.error("Error fetching Azure Speech token: ", error);
    });
  } else {
    Swal.fire({
      title: "No Encounters Found",
      text: "No encounters were found for the selected patient.",
      icon: null, // No icon displayed
      customClass: {
        popup: 'max-w-xs', // Tailwind class for a smaller popup
        confirmButton: 'bg-[#f2911b] text-white py-2 px-4 rounded hover:bg-[#d07b15]' // Styled confirm button
      },
      buttonsStyling: false, // Disable default button styling
      confirmButtonText: "OK"
    });
  }
} else {
  console.log("Search operation canceled or no patient selected.");
}

      } catch (error) {
        console.error("Error accessing microphone or initializing speech translation:", error);
        alert("Error accessing microphone. Please ensure the microphone is available and permission is granted.");
      }
    }
  };
  
  
  
  
  
  

 

  
  
 
  async function getAthenaEncounters(access_token, patient_id) {
    const url = 'https://astrascribe-india-be.azurewebsites.net/encounter/retrieve-all-athenaone-encounters/';
    const Dytoken = localStorage.getItem("token");
    const response = await fetch(url, {
        method: 'POST',
        headers: {
           Authorization: `Bearer ${Dytoken}`,
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
            access_token: access_token,
            patient_id: patient_id
        })
    });

    const result = await response.json();
    return result.encounters.encounters;
}
function createEncounterTable(encounters, patientName, onEncounterSelect) {
  let tableHtml = `<h3 class="text-lg font-semibold mb-4">Encounters for ${patientName}</h3>
<div class="overflow-x-auto">
  <div class="min-w-full max-h-96 overflow-y-auto border border-gray-200 rounded-md">
    <table class="min-w-full table-auto">
      <thead class="bg-gray-100 sticky top-0">
        <tr>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Encounter ID</th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Encounter Date</th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Provider Last Name</th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Status</th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Select</th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        ${encounters.map(encounter => `
        <tr>
          <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">${encounter.encounterid}</td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${encounter.encounterdate}</td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${encounter.providerlastname}</td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${encounter.status}</td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <input type="radio" name="encounter" value="${encounter.encounterid}" id="encounter-${encounter.encounterid}">
          </td>
        </tr>`).join('')}
      </tbody>
    </table>
  </div>
</div>
`;
  document.getElementById('encounterTable').innerHTML = tableHtml;

  // Attach event listeners for each radio button after rendering the table
  encounters.forEach(encounter => {
    const radioButton = document.getElementById(`encounter-${encounter.encounterid}`);
    if (radioButton) {
      radioButton.addEventListener('click', () => onEncounterSelect(
        encounter.encounterid, 
        encounter.encounterdate, 
        encounter.providerlastname, 
        encounter.status, 
        patientName
      ));
    }
  });
}







// Function to fetch and display encounters for the selected patient
// Function to fetch and display encounters for the selected patient


const fetchAndDisplayEncounters = async (accessToken, selectedPatient) => {
  const { id: patientId, name } = selectedPatient;
  const patientName = `${name[0].given[0]} ${name[0].family}`;

  try {
    const response = await fetch('https://astrascribe-india-be.azurewebsites.net/encounter/retrieve-all-athenaone-encounters/', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        access_token: accessToken,
        patient_id: patientId
      })
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Fetched Encounters Data:", data);

      createEncounterTable(data.encounters.encounters, patientName, selectEncounter);
      // In the handleStart or anywhere you use createEncounterTable

      
    } else {
      console.error("Failed to fetch encounters:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching encounters:", error);
  }
};


function selectEncounter(encounterId, encounterDate, providerLastName, status, patientName) {
  console.log(`Selected Encounter ID: ${encounterId}`);
  console.log(`Encounter Date: ${encounterDate}`);
  console.log(`Provider Last Name: ${providerLastName}`);
  console.log(`Status: ${status}`);
  console.log(`Patient Name: ${patientName}`);
}





// Define the selectEncounter function



// Call this function inside your handleStart function


useEffect(() => {
  const handleStartButton = document.getElementById('handleStart');
  const handleStartClick = async () => {
      console.log("handleStart clicked");

      const accessToken = await getAthenaToken(); // Your logic to get Athena token
      console.log("Access Token:", accessToken);

      // Use the selectedPatient from your component's state
      if (selectedPatient) {
          console.log("Selected Patient in handleStart:", selectedPatient);

          // Fetch and display encounters for the selected patient
          await fetchAndDisplayEncounters(accessToken, selectedPatient);
      } else {
          console.error("No patient selected.");
      }
  };

  if (handleStartButton) {
      handleStartButton.addEventListener('click', handleStartClick);
  } else {
      console.error("Element with ID 'handleStart' not found.");
  }

  // Clean up event listener when component unmounts
  return () => {
      if (handleStartButton) {
          handleStartButton.removeEventListener('click', handleStartClick);
      }
  };
}, [selectedPatient]); // Make sure to update this based on the dependencies like selectedPatient





  //Encounter Id Scenerio End
  
  
  

  const getCaretPosition = (el) => {
    let caretOffset = 0;
    const doc = el.ownerDocument || el.document;
    const win = doc.defaultView || doc.parentWindow;
    const sel = win.getSelection();
    if (sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(el);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
    return caretOffset;
  };

  const setCaretPosition = (el, offset) => {
    const doc = el.ownerDocument || el.document;
    const win = doc.defaultView || doc.parentWindow;
    if (offset >= 0) {
      const sel = win.getSelection();
      const range = doc.createRange();
      range.setStart(el, 0);
      range.setEnd(el, 0);
      let charIndex = 0,
        nodeStack = [el],
        node,
        foundStart = false;
      while ((node = nodeStack.pop())) {
        if (node.nodeType === 3) {
          const nextCharIndex = charIndex + node.length;
          if (!foundStart && offset >= charIndex && offset <= nextCharIndex) {
            range.setStart(node, offset - charIndex);
            range.setEnd(node, offset - charIndex);
            foundStart = true;
          }
          charIndex = nextCharIndex;
        } else {
          let i = node.childNodes.length;
          while (i--) {
            nodeStack.push(node.childNodes[i]);
          }
        }
      }
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const handlePause = () => {
    if (isRecording && !isPaused) {
      setIsPaused(true);
      clearInterval(intervalRef.current);
      mediaRecorder.pause();

      if (recognizer) {
        recognizer.stopContinuousRecognitionAsync(
          () => {
            console.log("Speech recognition paused");
          },
          (err) => {
            console.error("Error pausing speech recognition:", err);
          }
        );
      }
    } else if (isRecording && isPaused) {
      setIsPaused(false);
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
      mediaRecorder.resume();

      if (recognizer) {
        recognizer.startContinuousRecognitionAsync(
          () => {
            console.log("Speech recognition resumed");
          },
          (err) => {
            console.error("Error resuming speech recognition:", err);
          }
        );
      }
    }
  };

  const handleSave = async () => {
    if (!isRecording) {
      return; // Guard clause to prevent double execution
    }
    if (audioChunks.length > 0) {
      const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
      const file = new File([audioBlob], "recording.wav", {
        type: "audio/wav",
      });
      setFiles([file]);
      setDummyState((prev) => !prev);
    }

    // toast.success("Your recording is saved", {
    //   position: "top-right",
    //   autoClose: 500,
    // });
    setIsRecordingSaved(true);

    setIsRecording(false);
    setIsPaused(false);
    clearInterval(intervalRef.current);
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setMediaRecorder(null);
    }
    setAudioChunks([]);
    if (audioRef.current) {
      audioRef.current.src = "";
    }

    setTranscript((prev) => prev);
    // console.log(realTimeTranscript);
    setRealTimeTranscript("");

    if (recognizer) {
      recognizer.stopContinuousRecognitionAsync(
        () => {
          // console.log('Speech recognition stopped');
        },
        (err) => {
          console.error("Error stopping speech recognition:", err);
        }
      );
    }

    setIsSubmitDisabled(false);
  };

  const handleTranscriptChange = (event) => {
    const caretPosition = getCaretPosition(event.target);
    setTranscript(event.target.innerText);
    requestAnimationFrame(() => setCaretPosition(event.target, caretPosition));
  };

  const handleReset = () => {
    if (isRecording || isPaused) {
      setIsRecording(false);
      setIsPaused(false);
      clearInterval(intervalRef.current);
      if (mediaRecorder) {
        mediaRecorder.stop();
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
        setMediaRecorder(null);
      }
      setAudioChunks([]);
      if (audioRef.current) {
        audioRef.current.src = "";
      }
    }

    setTime(0);
    setFiles([]);
    setDummyState((prev) => !prev);
    setIsSubmitDisabled(false);
    setAudioChunks([]);

    // Reset the transcript
    setTranscript("");

    // Refresh the page
    window.location.reload();
  };

  const toggleLiveRecordSection = () => {
    setIsLiveRecordOpen(!isLiveRecordOpen);
  };

  const toggleGenerateSection = () => {
    setIsGenerateOpen(!isGenerateOpen);
  };

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };
  const handleGenerateContent = async (
    url,
    text,
    speechThreadId,
    index,
    title
  ) => {
    if (!token) {
      console.error("No token found. Please login first.");
      alert("Please login to perform this action.");
      return;
    }

    if (!speechThreadId) {
      Swal.fire(
        "Error",
        "Oops! Please upload the correct audio. Thank you!",
        "error"
      );
      return;
    }

    try {
      // Display a loading animation or message while the content is being generated
      const customLoader = `<div class="relative flex items-center justify-center overflow-hidden mt-4">
            <div class="w-16 h-16 border-4 border-solid border-transparent border-t-orange-500 rounded-full animate-spin"></div>
            <div class="absolute flex items-center justify-center">
              <div class="w-12 h-12 border-4 border-solid border-transparent border-t-black rounded-full animate-spin m-2"></div>
              <div class="absolute w-8 h-8 border-4 border-solid border-transparent border-t-orange-500 rounded-full animate-spin m-2"></div>
            </div>
          </div>`;

      const customHeader = `<div class="flex justify-between items-center w-full">
            <div class="text-lg">Generating ${title}</div>
            <button id="close-btn" class="text-gray-500 hover:text-gray-700 focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
          <hr class="border-gray-300 w-full my-2">
          <p class="text-gray-400 text-xs"> Please hold on for a moment, we are diligently processing your request and ensuring everything is accurate...</p>`;

      // Create form data to send the text and speechThreadId as form fields
      let formData = new FormData();
      formData.append("text", text);
      formData.append("SpeechThread_id", speechThreadId);

      // Make the POST request to the server with the correct headers
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data", // Ensure the correct content type
        },
      });
      // Handle the response and update the UI
      const generatedContent = response.data.notes || response.data.summary; // Adjust according to actual response structure
      setGeneratedContent(generatedContent);
      // console.log(generatedContent);
      // Update the saved generated posts
      setSavedGeneratedPosts((prev) => ({
        ...prev,
        [title]: generatedContent,
      }));

      // Set the generated post in the state
      setGeneratedPost({
        title,
        content: generatedContent,
        isHtmlContent: true,
      });
      setIsEditingGenerated(false);

      // Scroll to the generated post
      scrollToGeneratedPost();

      Swal.close();

  
    } catch (error) {
      console.error("Error in generating content:", error);

      if (error.response && error.response.status === 415) {
        Swal.fire(
          "Error",
          `The server could not process the request. Please check if the content type is correct.`,
          "error"
        );
      } else {
        Swal.fire(
          "Error",
          "Failed to generate content: " +
            (error.response?.data?.error || error.message),
          "error"
        );
      }
    }
  };

  // const Submit = async () => {
  //   // First, save the current recording
  //   await handleSave();
  
  //   // Then, upload the recording
  //   await handleUploadClick(selectedPatient);
  
  //   // After both operations are completed, reset the live listening section
  //   setIsRecording(false);
  //   setIsPaused(false);
  //   setTime(0);
  //   setTranscript([]);
  //     setRealTimeTranscript(""); // Clear the real-time transcript
  //   setAudioChunks([]);
  //   setIsSubmitDisabled(false);
  //   setIsSubmitted(false); // Reset submission state to allow new recording
  //   setIsRecordingSaved(false); // Reset recording saved state
  
  //   // Optionally, clear or reset other states
  //   if (audioRef.current) {
  //     audioRef.current.src = ""; // Clear audio preview
  //   }
  
  //   // Reset UI elements for a new recording
 
    
  //   // You can also reset the display if needed
  //   setIsLiveRecordOpen(true); // Re-open the live listening section if it was closed
  // };
  
  
  // Example usage within the handleUploadClick function
  const Submit = async () => {
    try {
      // Show toast notification for submission process
     // Add custom styling for the toast
const customToastStyle = {
  width: '500px', // Adjust the width as needed
};

// Inside your Submit function
toast.info("Processing request, check sidebar for updates.", {
  position: "top-center",
  autoClose: 2000,  // 3 seconds display time
  style: customToastStyle, // Apply the custom width
});

  
      // First, save the current recording
      await handleSave();
  
      // Then, upload the recording
      await handleUploadClick(selectedPatient);
  
      // Reset the live listening section
      setIsRecording(false);
      setIsPaused(false);
      setTime(0);
      setTranscript([]);
      setRealTimeTranscript(""); // Clear the real-time transcript
      setAudioChunks([]);
      setIsSubmitDisabled(false);
      setIsSubmitted(false); // Reset submission state to allow new recording
      setIsRecordingSaved(false); // Reset recording saved state
  
      // Optionally, clear or reset other states
      if (audioRef.current) {
        audioRef.current.src = ""; // Clear audio preview
      }
  
      // Reset UI elements for a new recording
      setIsLiveRecordOpen(true); // Re-open the live listening section if it was closed
  
      toast.success("Processing request, check sidebar for updates.", {
        position: "top-center",
        autoClose: 2000,  // 3 seconds display time
        style: customToastStyle, // Apply the custom width
      });
      
    } catch (error) {
      // Handle any errors during the submission process
      console.error("Error during submission:", error);
      toast.error("There was an error processing your request. Please try again.", {
        position: "top-center",
        autoClose: 3000,  // 3 seconds display time
      });
    }
  };
  
  const handleUploadClick = async (patient) => {
    if (!token) {
      console.error("No token found. Please login first.");
      alert("Please login to perform this action.");
      return;
    }
    const transcriptString = Array.isArray(transcript)
    ? transcript.map((item) => item.translated || item.original || item.text).join(" ")
    : transcript;

    if (transcriptString.trim().length === 0) {
      Swal.fire({
        title: "<span class='text-xl font-semibold text-[#f2911b] '>Error</span>", // Tailwind classes for title
        html: "Transcription text is empty. Please provide a transcription. Restart it?", // Tailwind classes for content
        icon: false, // Disable the default icon
        buttonsStyling: false,
        customClass: {
          title: 'text-lg font-semibold mb-2', // Tailwind classes for title
          content: 'text-sm', // Tailwind classes for content
          confirmButton: 'bg-[#f2911b] hover:bg-gray-400 text-white font-bold py-2 px-4 rounded', // Custom class for OK button
          popup: 'max-w-xs', 
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(); // Refresh the current page
        }
      });
      
      
      return;
    }
    const customLoader = `
  <div class="relative flex items-center justify-center overflow-hidden mt-4">
    <div class="w-16 h-16 border-4 border-solid border-transparent border-t-orange-500 rounded-full animate-spin"></div>
    <div class="absolute flex items-center justify-center">
      <div class="w-12 h-12 border-4 border-solid border-transparent border-t-black rounded-full animate-spin m-2"></div>
      <div class="absolute w-8 h-8 border-4 border-solid border-transparent border-t-orange-500 rounded-full animate-spin m-2"></div>
    </div>
  </div>
`;

    const customHeader = `
  <div class="flex justify-between items-center w-full">
    <div class="text-lg">Processing Your Recording</div>
    <button id="close-btn" class="text-gray-500 hover:text-gray-700 focus:outline-none">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
      </svg>
    </button>
  </div>
  <hr class="border-gray-300 w-full my-2">
  <p class="text-gray-400 text-xs"> Please hold on for a moment, we are diligently processing your request and ensuring everything is accurate...</p>
`;

    // Swal.fire({
    //   html: `
    //   ${customHeader}
    //   ${customLoader}
    // `,
    //   allowOutsideClick: false,
    //   showConfirmButton: false,
    //   customClass: {
    //     popup: "w-96 h-64 flex flex-col items-start justify-start p-4",
    //     title: "text-lg",
    //     htmlContainer:
    //       "flex flex-col items-center justify-center w-full h-full",
    //   },
    //   didOpen: () => {
    //     document
    //       .getElementById("close-btn")
    //       .addEventListener("click", () => Swal.close());
    //   },
    // });

    let formData = new FormData();

    if (profilePictureUrl) {
      formData.append("profile_picture", profilePictureUrl);
    }

    // if (files.length > 0) {
    //   formData.append("file", files[0]);
    // } else if (audioChunks.length > 0) {
    //   const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
    //   formData.append("file", audioBlob, "recording.wav");
    // } else {
    //   console.error("No files or audio chunks to upload.");
    //   toast.error("No files or audio chunks to upload.", {
    //     position: "top-right",
    //     autoClose: 5000,
    //   });
    //   return;
    // }
     // Add the patient data if available

     let patientName = '';
let patientDOB = '';
     if (patient) {
      patientName = `${patient.name[0].given[0]} ${patient.name[0].family}`;
      patientDOB = patient.birthDate;
    
      formData.append("patient_name", patientName);
      formData.append("patient_date_of_birth", patientDOB);
    
      // Set the post patient name and date of birth
      setPostPatientName(patientName);
      setDateOfBirth(patientDOB);
    } else {
      console.error("No patient selected.");
    }
    
    if (transcriptString.trim().length > 0) {
      formData.append("transcription_text", transcriptString);
    } else {
      console.error("Transcription text is empty.");
      toast.error("Transcription text is empty.", {
        position: "top-right",
        autoClose: 500,
      });
      return;
    }

    formData.append("patient_name", patientName);
    try {
      const response = await axios.post(
        "https://astrascribe-india-be.azurewebsites.net/transcribe/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );

      setTranscript(response.data.SpeechThread.text || []);
    setSpeechThreadId(response.data.SpeechThread.id);
    const icd10Codes = response.data.SpeechThread.icd10_codes || [];
    setIcd10Codes(icd10Codes);

    const speechThread = response.data.SpeechThread;
    setPostTitle(speechThread.title);
    setCreatedDate(speechThread.created_at);
    // setPostPatientName(speechThread.patient_name);
    // setDateOfBirth(speechThread.patient_date_of_birth);
    setPostPatientName(patientName);
    setDateOfBirth(patientDOB);
  
    // Log patient name and date of birth after setting state
    console.log("Final Patient Name: ", patientName);
    console.log("Final Patient Date of Birth: ", patientDOB);
    setIsPostGenerated(true);
      // Stop the recording and speech recognition
      if (mediaRecorder) {
        mediaRecorder.stop();
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
        setMediaRecorder(null);
      }

      // Stop the speech recognizer if it's running
      if (recognizer) {
        recognizer.stopContinuousRecognitionAsync();
      }

      setIsRecording(false);
      setIsPaused(false);
      clearInterval(intervalRef.current);
      setAudioChunks([]);

      // Generate summary
      // await handleGenerateContent(
      //   "https://astrascribe-india-be.azurewebsites.net/generate_summary",
      //   response.data.SpeechThread.text,
      //   response.data.SpeechThread.id,
      //   0,
      //   "Summary"
      // );

      // Generate notes
      // await handleGenerateContent(
      //   "https://astrascribe-india-be.azurewebsites.net/generate_notes/",
      //   response.data.SpeechThread.text,
      //   response.data.SpeechThread.id,
      //   1,
      //   "Notes"
      // );

      Swal.close(); // Close the Swal popup after generating the posts

      localStorage.setItem("transcriptionSuccess", "true");
      console.log("Response Data : " ,response.data )
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    } catch (error) {
      console.error("Error in transcription:", error);
   Swal.fire({
  title: "Error",
  text: "Transcription failed: " + (error.response?.data?.error || error.message),
  icon: null, // No icon
  confirmButtonColor: '#f2911b', // Orange button color
  customClass: {
    popup: 'max-w-xs', 
  },
});


      
    }

    setIsLiveRecordOpen(!isLiveRecordOpen);
    setIsImgOpen(!isImgOpen);
    setIsSubmitted(true); // Set to true after the submission


    
    
  };

  const handleCopyContent = (content) => {
    // Create a temporary div element to hold the HTML content
    const tempElement = document.createElement("div");
    tempElement.innerHTML = content; // Set the innerHTML to the content
    tempElement.style.position = "absolute";
    tempElement.style.left = "-9999px"; // Move it off-screen
    
    // Append the temporary element to the body
    document.body.appendChild(tempElement);
  
    // Select the content inside the element
    const range = document.createRange();
    range.selectNodeContents(tempElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  
    // Try to copy the content
    try {
      document.execCommand("copy"); // Copy the HTML content
      toast.success("Copied! The content has been copied to the clipboard.", {
        position: "top-right",
        autoClose: 500,
      });
    } catch (err) {
      console.error("Failed to copy:", err);
      toast.error("Failed to copy the content. Please try again.", {
        position: "top-right",
        autoClose: 500,
      });
    }
  
    // Remove the temporary element from the DOM
    document.body.removeChild(tempElement);
  };
  

  const handleDownloadTextContent = async (title, content, speechThreadId) => {
    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("Authentication required. Please login.");
      return;
    }
    console.log("content", content);
    const plainTextContent = stripHtmlTags(content);
    console.log("content", content);

    const apiUrl = "https://astrascribe-india-be.azurewebsites.net/generate_pdf/";
    const formData = new FormData();
    formData.append("text", content);
    formData.append("SpeechThread_id", speechThreadId);

    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      });

      if (response.status === 200 && response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.success("PDF downloaded successfully!", {
          position: "top-right",
          autoClose: 500,
        });
      } else {
        throw new Error("Failed to generate PDF");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  // const handleShareContent = (title, content) => {
  //   // Create a temporary div element to hold the HTML content
  //   const tempElement = document.createElement("div");
  //   tempElement.innerHTML = content; // Set the innerHTML to the content
  
  //   // Remove all <style> and <script> elements
  //   const styleElements = tempElement.querySelectorAll('style, script');
  //   styleElements.forEach(el => el.remove());
  
  //   // Replace <br> tags with newlines for readability
  //   tempElement.innerHTML = tempElement.innerHTML.replace(/<br\s*\/?>/gi, '\n');
  
  //   // Replace paragraph (<p>) and div tags with newlines for better structure
  //   tempElement.innerHTML = tempElement.innerHTML.replace(/<\/?p>/gi, '\n\n');
  //   tempElement.innerHTML = tempElement.innerHTML.replace(/<\/?div>/gi, '\n\n');
  
  //   // Handle list items by adding bullet points
  //   tempElement.innerHTML = tempElement.innerHTML.replace(/<li>/gi, '• ');
  //   tempElement.innerHTML = tempElement.innerHTML.replace(/<\/li>/gi, '\n');
  
  //   // Remove any remaining HTML tags and get the clean text
  //   const formattedText = tempElement.textContent || tempElement.innerText || '';
  
  //   // Check if the Web Share API is supported and share the formatted text
  //   if (navigator.share) {
  //     navigator.share({
  //       title: title,
  //       text: formattedText, // Share the plain text version
  //     }).then(() => {
  //       toast.success("Content shared successfully!", {
  //         position: "top-right",
  //         autoClose: 5000,
  //       });
  //     }).catch((error) => {
  //       console.error("Error sharing content:", error);
  //       toast.error("Failed to share the content. Please try again.", {
  //         position: "top-right",
  //         autoClose: 5000,
  //       });
  //     });
  //   } else {
  //     alert("Sharing not supported in this browser.");
  //   }
  // };
  
  
  
  
  const handleShareContent = (title, content) => {
    // Create a temporary div element to hold the HTML content
    const tempElement = document.createElement("div");
    tempElement.innerHTML = content; // Set the innerHTML to the content
  
    // Remove all <style> and <script> elements
    const styleElements = tempElement.querySelectorAll('style, script');
    styleElements.forEach(el => el.remove());
  
    // Replace <br> tags with newlines for readability
    tempElement.innerHTML = tempElement.innerHTML.replace(/<br\s*\/?>/gi, '\n');
  
    // Replace paragraph (<p>) and div tags with newlines for better structure
    tempElement.innerHTML = tempElement.innerHTML.replace(/<\/?p>/gi, '\n\n');
    tempElement.innerHTML = tempElement.innerHTML.replace(/<\/?div>/gi, '\n\n');
  
    // Handle list items by adding bullet points
    tempElement.innerHTML = tempElement.innerHTML.replace(/<li>/gi, '• ');
    tempElement.innerHTML = tempElement.innerHTML.replace(/<\/li>/gi, '\n');
  
    // Trim any leading or trailing whitespace
    let formattedText = tempElement.textContent || tempElement.innerText || '';
  
    // Remove any extra newlines or spaces at the beginning of each line
    formattedText = formattedText.replace(/^\s+/gm, '');
  
    // Remove extra newlines to ensure clean formatting
    formattedText = formattedText.replace(/\n{3,}/g, '\n\n'); // Max two newlines
  
    // Check if the Web Share API is supported and share the formatted text
    if (navigator.share) {
      navigator.share({
        title: title,
        text: formattedText.trim(), // Share the plain text version
      }).then(() => {
        toast.success("Content shared successfully!", {
          position: "top-right",
          autoClose: 500,
        });
      }).catch((error) => {
        console.error("Error sharing content:", error);
        toast.error("Failed to share the content. Please try again.", {
          position: "top-right",
          autoClose: 500,
        });
      });
    } else {
      alert("Sharing not supported in this browser.");
    }
  };
  
  
  

  const generateOptions = [
    {
      title: "Summary",
      description: "A brief summary to encapsulate the essence.",
      image: Summary,
      url: "https://astrascribe-india-be.azurewebsites.net/generate_summary/",
      speechThreadId: speechThreadId,
    },
    {
      title: "Notes",
      description: "Generate concise and informative meeting notes.",
      image: MeetingNotes,
      url: "https://astrascribe-india-be.azurewebsites.net/generate_meeting_notes/",
      speechThreadId: speechThreadId,
    },
  ];

  const getStepClassName = (stepIndex) => {
    return isStepCompleted[stepIndex]
      ? "bg-[#F2911B] text-white"
      : "bg-gray-400 text-white";
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [profilePicture, setProfilePicture] = useState("");

  useEffect(() => {
    const storedUsername = localStorage.getItem("Username");
    const storedProfilePicture = localStorage.getItem("ProfilePicture");
    setUsername(storedUsername || "User");
    setProfilePicture(storedProfilePicture || "");
  }, []);

  const stripHtmlTags = (html) => {
    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const saveRecordingLocally = (chunks) => {
    const audioBlob = new Blob(chunks, { type: "audio/wav" });
    const url = URL.createObjectURL(audioBlob);
    localStorage.setItem("recording", url);
  };
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  
  return (
    <div className="container mx-auto px-0">



{/* 
<h2>Search Patient</h2>
      <div>
        <input 
          type="text" 
          id="firstName" 
          placeholder="First Name" 
          value={firstName} 
          onChange={(e) => setFirstName(e.target.value)} 
        />
        <input 
          type="text" 
          id="lastName" 
          placeholder="Last Name" 
          value={lastName} 
          onChange={(e) => setLastName(e.target.value)} 
        />
        <button onClick={handlePatientSearch}>Search Patients</button>
      </div>

      {patients.length > 0 && (
        <select onChange={handlePatientSelect}>
          {patients.map((patient, index) => (
            <option key={index} value={patient.id}>
              {patient.name[0].given[0]} {patient.name[0].family} - {new Date(patient.birthDate).toLocaleDateString()}
            </option>
          ))}
        </select>
      )}

      {selectedPatient && (
        <div>
          <h3>Selected Patient:</h3>
          <p>Name: {selectedPatient.name[0].given[0]} {selectedPatient.name[0].family}</p>
          <p>DOB: {new Date(selectedPatient.birthDate).toLocaleDateString()}</p>
        </div>
      )} */}












      <p
        className="text-lg font-semibold my-4 cursor-pointer"
        onClick={() =>
          profilePictureInputRef.current &&
          profilePictureInputRef.current.click()
        }
      >
        {profilePictureName && (
          <span className="ml-2 text-sm text-gray-600">
            {profilePictureName}
          </span>
        )}
        {profilePictureName && (
          <img
            alt="not found"
            src={URL.createObjectURL(uploadedFile.current)}
          />
        )}
      </p>
      <input
        type="file"
        ref={profilePictureInputRef}
        accept="image/*"
        className="hidden"
        onChange={handleProfilePictureChange}
      />

      <div className="flex flex-col items-center  mt-2  p-4  bg-[#E8ECF4] rounded-2xl">
        <div className="space-y-6 mx-auto w-full px-0">
          <div className="flex items-start">
            <div className="relative bg-white shadow-md rounded-3xl p-6 overflow-hidden w-full">
              <div
                className={`flex items-center justify-between mb-4 cursor-pointer ${
                  isLiveRecordOpen ? "bg-transparent" : ""
                }`}
                // onClick={toggleLiveRecordSection}
              >
                <div className="flex items-center space-x-2">
                  <p className="font-bold text-lg ">Press Start for Seamless Live Recording</p>
                </div>
                {/* <FontAwesomeIcon
                  icon={isLiveRecordOpen ? faAngleUp : faAngleDown}
                /> */}
              </div>
              {isLiveRecordOpen && (
                <div className="flex flex-col">
                  <div className="p-4 md:p-8 flex flex-col items-center justify-center w-full rounded-lg">
                    <div className="text-center w-full">
                      <div className="flex items-center justify-center mb-2">
                        <span className="text-xl font-bold">
                          {formatTime(time)}
                        </span>
                      </div>

                      <div className="flex flex-col items-center justify-center space-y-4 ">
                        <div className="flex items-center justify-center -space-x-3 lg:space-x-8 xl:space-x-8 2xl:space-x-8">
                          {!isSubmitted ? (
                            !isRecording && audioChunks.length === 0 ? (
                              <button
                                onClick={handleStart}
                                className="w-36 h-12 flex items-center justify-center bg-[#F2911B] rounded-full text-white text-2xl shadow-lg hover:bg-white hover:border-2 hover:border-[#F2911B] hover:text-[#F2911B] relative"
                                title="Click to start"
                                disabled={isRecordingSaved} // Disable if saved
                              >
                                <FontAwesomeIcon icon={faMicrophone} />
                                <span className="ml-2">Start</span>
                              </button>
                            ) : (
                              <>
                                <button
                                  onClick={handlePause}
                                  disabled={isRecordingSaved} // Disable after recording is saved
                                  title={isPaused ? "Resume" : "Pause"}
                                  className={`flex items-center justify-center w-40 h-12 text-gray-700 text-xl transition-opacity rounded-full hover:bg-gray-200 border border-gray-500  ${
                                    isPaused
                                      ? "opacity-50 animate-pulse-2 bg-gray-200"
                                      : "opacity-100 bg-transparent"
                                  } ${
                                    isRecordingSaved
                                      ? "opacity-50 cursor-not-allowed"
                                      : ""
                                  }`}
                                >
                                  <FontAwesomeIcon icon={faPause} />
                                  <span className="ml-2">
                                    {isPaused ? "Resume" : "Pause"}
                                  </span>
                                </button>
                                <div className="relative flex items-center justify-center">
                                  {isRecording && !isPaused && (
                                    <div className="absolute inset-0 flex items-center justify-center">
                                      {/* <span className="absolute w-20 h-20 rounded-full bg-[#F2911B] opacity-25 animate-pulse"></span>
              <span className="absolute w-12 h-12 rounded-full bg-[#F2911B] opacity-25 animate-pulse delay-200"></span>
              <span className="absolute w-8 h-8 rounded-full bg-[#F2911B] opacity-25 animate-pulse delay-400"></span> */}
                                    </div>
                                  )}
                                  <button
                                    onClick={Submit}
                                    className={`w-40 h-12  flex items-center justify-center bg-[#F2911B] rounded-full text-white text-xl shadow-lg z-10 mx-4 hover:animate-pulse hover:delay-200 ${
                                      isRecordingSaved
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                    }`}
                                    disabled={isRecordingSaved} // Disable Save button after saving
                                  >
                                    <FontAwesomeIcon icon={faStop} />
                                    <span className="ml-2">Save</span>
                                  </button>
                                </div>
                                {/* <button
         title="Reset It"
          onClick={handleReset}
          className="flex items-center justify-center w-10 h-10 text-gray-700 bg-transparent text-2xl hover:text-[#F2911B]"
        >
          <FontAwesomeIcon icon={faRedo} />
        </button> */}
                              </>
                            )
                          ) : (
                            // Display the Reset button after submission
                            <button
                              onClick={handleReset}
                              className="w-40 h-12 flex items-center justify-center bg-[#F2911B] rounded-lg text-white text-xl shadow-lg z-10 hover:bg-white hover:border-2 hover:border-[#F2911B] hover:text-[#F2911B]"
                            >
                              <FontAwesomeIcon icon={faRedo} />
                              <span className="ml-2">Reset It</span>
                            </button>
                          )}
                        </div>

                        {audioChunks.length > 0 && (
                          <div className="mt-4 pt-8 hidden">
                            <button
                              onClick={handleUploadClick}
                              className={`bg-${
                                isSubmitDisabled
                                  ? "gray-400 cursor-not-allowed"
                                  : "[#F2911B]"
                              } text-white px-6 py-2 rounded-lg w-36 h-12 text-xl border-2 hover:text-[#f2911b] hover:bg-white hover:border-[#f2911b]`}
                              disabled={isSubmitDisabled} // Only disable if submission is in process
                            >
                              Submit
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    <audio
                      ref={audioRef}
                      controls
                      className="mt-4 w-full hidden"
                    ></audio>
                  </div>
                  {isRecording ? (
  <div>
    {realTimeTranscript && realTimeTranscript.length > 0 ? (
      realTimeTranscript.map((entry, index) => (
        <div
    key={index}
    style={{
      backgroundColor: entry.bgColor,
      padding: '5px',
      margin: '2px 0',
      fontStyle: entry.role === 'Unknown' ? 'italic' : 'normal',
    }}
  >
    <strong>{entry.role}</strong> {entry.text}
  </div>
      ))
    ) : (
      <div>Loading transcription...</div>
    )}
  </div>
) : (
  <div className="transcript-section">
    {Array.isArray(transcript) && transcript.length > 0 ? (
      <div>
        {transcript.map((entry, index) => (
          <div
            key={index}
            style={{ backgroundColor: entry.bgColor, padding: '5px', margin: '2px 0' }}
          >
            <strong>{entry.role}:</strong> {entry.text}
          </div>
        ))}
      </div>
    ) : (

                  <div>{transcript ? transcript : "No transcription available yet."}</div>
    )}
  </div>
)}
                  <div className="flex flex-col items-center w-full">
                    <input
                      type="file"
                      ref={inputRef}
                      className="hidden"
                      onChange={handleFileChange}
                    />
                    <div className="flex flex-wrap  space-x-2 w-full ">
                      {files.map((file, index) => (
                        <div
                          key={index}
                          className="relative flex flex-col items-center mb-2"
                        ></div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className={`flex items-start hidden ${
              isAnimating ? "animate-zoomIn" : ""
            }`}
          >
            <div className="relative bg-white shadow-md rounded-3xl p-6 overflow-hidden w-full">
              <div
                className={`flex items-center justify-between mb-4 cursor-pointer ${
                  isGenerateOpen ? "bg-transparent" : ""
                }`}
                onClick={toggleGenerateSection}
              >
                <div className="flex items-center space-x-2">
                  <span
                    className={`h-10 w-10 lg:h-8 lg:w-8 flex items-center justify-center text-base rounded-full ${getStepClassName(
                      2
                    )}`}
                  >
                    3
                  </span>
                  <p className="font-bold text-lg">Generate</p>
                </div>
                <FontAwesomeIcon
                  icon={isGenerateOpen ? faAngleUp : faAngleDown}
                />
              </div>
              {isGenerateOpen && (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                  {generateOptions.map((option, index) => (
                    <div
                      key={index}
                      className="relative p-4 border-solid border border-black rounded-lg group cursor-pointer overflow-hidden text-sm"
                      onClick={() =>
                        handleGenerateContent(
                          option.url,
                          transcript,
                          option.speechThreadId,
                          index,
                          option.title
                        )
                      }
                    >
                      <div
                        className={`absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 transition-opacity ${
                          generatedStatus[index]
                            ? "opacity-100"
                            : "opacity-0 group-hover:opacity-100"
                        }`}
                      >
                        <a
                          href={`#${option.title}`}
                          className="px-4 py-2 flex"
                          id={`generateBtn${option.title}`}
                        ></a>
                        <button
                          className={`text-white px-4 py-2 rounded-3xl transition-colors duration-300 ${
                            generatedStatus[index]
                              ? "bg-gray-700"
                              : "bg-[#F2911B] hover:bg-[#e57d0e]"
                          }`}
                        >
                          {generatedStatus[index] ? "Generated" : "Generate"}
                          <FontAwesomeIcon
                            icon={faWandMagicSparkles}
                            className="text-white ml-2"
                          />
                        </button>
                      </div>
                      <div className="flex justify-between items-center mb-2">
                        <p className="font-bold text-sm">{option.title}</p>
                        <img
                          src={option.image}
                          alt={option.title}
                          className="h-6 w-6"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-center mb-6 hidden">
            <button
              className={`px-4 py-2 text-lg font-bold rounded-l-lg ${
                currentSlide === "Notes"
                  ? "bg-[#F2911B] text-white"
                  : "bg-gray-300"
              }`}
              onClick={() => switchSlide("Notes")}
            >
              Notes
            </button>
            <button
              className={`px-4 py-2 text-lg font-bold rounded-r-lg ${
                currentSlide === "Summary"
                  ? "bg-[#F2911B] text-white"
                  : "bg-gray-300"
              }`}
              onClick={() => switchSlide("Summary")}
            >
              Summary
            </button>
          </div>

     



          {currentSlide === "Notes" &&
            generatedPost &&
            generatedPost.title === "Notes" && (
              <div
                className="flex flex-col mt-8 hidden"
                id="Notes"
                ref={generatedPostRef}
              >
                <div className="relative bg-white shadow-md rounded-b-3xl p-6 mb-0 overflow-hidden w-full ">
                  <div className="flex items-center justify-between mb-0 ">

        <p className="text-sm font-bold hidden lg:block md:block">{postTitle}</p> {/* Title */}
      

                    <p></p>
                    <div className="flex items-center space-x-2">
                      {isEditingGenerated ? (
                        <button
                          onClick={handleSaveGeneratedText}
                          title="Save"
                          className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                        >
                          <FontAwesomeIcon icon={faSave} className="" />
                        </button>
                      ) : (
                        <button
                          onClick={() => setIsEditingGenerated(true)}
                          title="Edit"
                          className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                        >
                          <FontAwesomeIcon icon={faEdit} className="" />
                        </button>
                      )}

                      <button
                        onClick={() => handleCopyContent(generatedPost.content)}
                        title="Copy"
                        className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                      >
                        <FontAwesomeIcon icon={faCopy} className="" />
                      </button>
                      <button
                        onClick={() =>
                          handleDownloadTextContent(
                            generatedPost.title,
                            generatedPost.content,
                            speechThreadId
                          )
                        }
                        title="Download"
                        className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                      >
                        <FontAwesomeIcon icon={faDownload} className="" />
                      </button>

                      <button
                        onClick={() =>
                          handleShareContent(
                            generatedPost.title,
                            generatedPost.content
                          )
                        }
                        title="Share"
                        className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                      >
                        <FontAwesomeIcon icon={faShareAlt} className="" />
                      </button>
                    </div>
                  </div>
                  <p className="text-sm ml-4 font-bold md:hidden lg:hidden">{postTitle}</p> {/* Title */}
                  <div className= " flex ml-4 space-x-4 mb-8 text-xs text-gray-600"> {/* Minimum font styling */}
    <p>Patient: <strong>{postPatientName}</strong></p>
    <p>Date: <strong>{new Date(createdDate).toLocaleDateString()}</strong></p>
    <p>D.O.B: <strong>{new Date(dateOfBirth).toLocaleDateString()}</strong></p>
</div>
                  <div className="p-4 -mt-10 rounded-lg overflow-auto text-sm">
                    {isEditingGenerated ? (
                      <ReactQuill
                        value={generatedPost.content}
                        onChange={(content) => {
                          setGeneratedPost({ ...generatedPost, content });
                        }}
                      />
                    ) : generatedPost.isHtmlContent ? (
                      <div
                        className="prose max-w-none"
                        dangerouslySetInnerHTML={{
                          __html: generatedPost.content,
                        }}
                      />
                    ) : (
                      <div className="whitespace-pre-wrap">
                        {stripHtmlTags(generatedPost.content)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

          {currentSlide === "Summary" &&
            generatedPost &&
            generatedPost.title === "Summary" && (
              <div
                className="flex flex-col mt-8"
                id="Summary"
                ref={generatedPostRef}
              >
                <div className="relative bg-white shadow-md rounded-3xl p-6 mb-6 overflow-hidden w-full max-w-5xl">
                  <div className="flex items-center justify-between mb-4">
                    <p className=""></p>
                    <div className="flex items-center space-x-2 ">
                      {isEditingGenerated ? (
                        <button
                          onClick={handleSaveGeneratedText}
                          title="Save"
                          className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                        >
                          <FontAwesomeIcon icon={faSave} className="" />
                        </button>
                      ) : (
                        <button
                          onClick={() => setIsEditingGenerated(true)}
                          title="Edit"
                          className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                        >
                          <FontAwesomeIcon icon={faEdit} className="" />
                        </button>
                      )}

                      <button
                        onClick={() => handleCopyContent(generatedPost.content)}
                        title="Copy"
                        className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                      >
                        <FontAwesomeIcon icon={faCopy} className="" />
                      </button>
                      <button
                        onClick={() =>
                          handleDownloadTextContent(
                            generatedPost.title,
                            generatedPost.content,
                            speechThreadId
                          )
                        }
                        title="Download"
                        className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                      >
                        <FontAwesomeIcon icon={faDownload} className="" />
                      </button>

                      <button
                        onClick={() =>
                          handleShareContent(
                            generatedPost.title,
                            generatedPost.content
                          )
                        }
                        title="Share"
                        className="flex items-center justify-center w-10 h-10 bg-[#F2911B] rounded-full hover:bg-white text-white hover:text-[#F2911B] border-2 border-[#F2911B]"
                      >
                        <FontAwesomeIcon icon={faShareAlt} className="" />
                      </button>
                    </div>
                  </div>
                  <div className="p-4 rounded-lg overflow-auto text-sm">
                    {isEditingGenerated ? (
                      <ReactQuill
                        value={generatedPost.content}
                        onChange={(content) => {
                          setGeneratedPost({ ...generatedPost, content });
                        }}
                      />
                    ) : generatedPost.isHtmlContent ? (
                      <div
                        className="prose max-w-none"
                        dangerouslySetInnerHTML={{
                          __html: generatedPost.content,
                        }}
                      />
                    ) : (
                      <div className="whitespace-pre-wrap ">
                        {stripHtmlTags(generatedPost.content)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}



{isPostGenerated && (
        <div className="relative bg-white shadow-md rounded-3xl p-6 mb-6 overflow-hidden w-full hidden">
          <h3 className="text-lg font-bold mb-2">ICD-10 Codes</h3>
          {icd10Codes.length > 0 ? (
            <ul className="list-disc pl-5">
              {icd10Codes.map((codeItem, index) => (
                <li key={index}>
                  <strong>{codeItem.code}</strong>: {codeItem.description}
                </li>
              ))}
            </ul>
          ) : (
            <p>No ICD-10 codes available.</p>
          )}
        </div>
      )}
        </div>
      </div>
    </div>
  );
};

export default RecordScreen;
