import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPause,
  faSpinner,
  faMicrophone,
  faStop,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ENDPOINTS } from "../../apiConfig";

const WhisperRecord = () => {
  const token = localStorage.getItem("token");
  const history = useHistory();
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  const [selectedPat, setSelectedPat] = useState(null);
  const [time, setTime] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [patientThread, setPatientThread] = useState(null);
  const [patientSessions, setPatientSessions] = useState([]);
  const [patientPlan, setPatientPlan] = useState();
  const [expandedSessionId, setExpandedSessionId] = useState(null);
  const [visibleSessionsCount, setVisibleSessionsCount] = useState(5);

  useEffect(() => {
    const tempPatient = JSON.parse(localStorage.getItem("Patient"));

    if (!tempPatient) {
      history.push("/search-patient");
    }
    if (!selectedPat) {
      setSelectedPat(tempPatient);
    }

    getPatientHistory(tempPatient.id);
  }, [selectedPat, history]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      audioChunksRef.current = [];
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        const url = URL.createObjectURL(audioBlob);
        setAudioURL(url);
        await uploadAudio(audioBlob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setIsPaused(false);

      setTime(0);
      timerRef.current = setInterval(() => {
        setTime((prev) => prev + 1);
      }, 1000);
    } catch (error) {
      console.error("Error accessing microphone:", error);
      toast.error("Error accessing microphone!", {
        position: "top-right",
        autoClose: 500,
      });
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsPaused(false);

      clearInterval(timerRef.current);
    }
  };

  const pauseResumeRecording = () => {
    if (mediaRecorderRef.current) {
      if (isPaused) {
        mediaRecorderRef.current.resume();
        setIsPaused(false);

        timerRef.current = setInterval(() => {
          setTime((prev) => prev + 1);
        }, 1000);
      } else {
        mediaRecorderRef.current.pause();
        setIsPaused(true);
        clearInterval(timerRef.current);
      }
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const uploadAudio = async (blob) => {
    try {
      setIsSubmitting(true);
      const sessionDuration = formatTime(time);

      const formData = new FormData();
      formData.append("patient_id", `${selectedPat?.id}`);
      formData.append("session_duration", sessionDuration);
      formData.append("recording_files", blob, "recording.wav");

      const response = await axios.post(ENDPOINTS.POST_TRANSCRIBE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      });
      localStorage.removeItem("Patient");
      console.log("Audio uploaded successfully:", response.data);
      toast.success("Audio uploaded successfully!", {
        position: "top-right",
        autoClose: 500,
      });

      history.push(`/conversation/${selectedPat?.id}`);
    } catch (error) {
      console.error("Error uploading audio:", error);
      toast.error("Error uploading audio!", {
        position: "top-right",
        autoClose: 500,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // PATIENT HISTORY DISPLAY

  const getPatientHistory = async (id) => {
    setIsLoading(true);

    try {
      const threadResponse = await axios.get(ENDPOINTS.GET_PATIENT_THREAD, {
        headers: { Authorization: `Bearer ${token}` },
        params: { patient_id: id },
      });

      setPatientThread(threadResponse.data);
      setPatientPlan(threadResponse.data.patient_plan || "N/A");

      const sessionsResponse = await axios.get(ENDPOINTS.GET_PATIENT_SESSIONS, {
        params: {
          patient_id: id,
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      setPatientSessions(sessionsResponse.data.results);
      setExpandedSessionId(sessionsResponse.data.results[0]?.id || null);
    } catch (error) {
      console.error("Error fetching history details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleSessionExpansion = (sessionId) => {
    setExpandedSessionId(expandedSessionId === sessionId ? null : sessionId);
  };

  const handleLoadMoreSessions = () => {
    setVisibleSessionsCount((prevCount) => prevCount + 5);
  };

  return (
    <div className="container mx-auto pe-0 ps-8">
      {selectedPat && (selectedPat.firstname || selectedPat.lastname) ? (
        <div
          className="patient-info bg-[#F2911B] text-white py-2 px-4 flex justify-center items-center"
          style={{
            backgroundColor: "#f2911b",
          }}
        >
          <div className="flex flex-col lg:flex-row items-center text-sm sm:text-base lg:gap-8 gap-1">
            <span>
              <strong>Patient:</strong>{" "}
              {selectedPat.firstname + " " + selectedPat.lastname}
            </span>
            <span>
              <strong>DOB:</strong> {selectedPat.date_of_birth}
            </span>
          </div>
        </div>
      ) : null}

      <div className="flex flex-col items-center mt-2 p-4 bg-[#E8ECF4] rounded-2xl">
        <div className="space-y-6 mx-auto w-full px-0">
          <div className="flex items-start">
            <div className="relative bg-white shadow-md rounded-3xl p-6 overflow-hidden w-full">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center justify-center w-full">
                  <p className="font-bold text-lg text-center">
                    {!isRecording
                      ? "Press Start for Listening"
                      : "Press Submit to Generate Clinical Notes"}
                  </p>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="p-4 md:p-8 flex flex-col items-center justify-center w-full rounded-lg">
                  <div className="text-center w-full">
                    <div className="flex items-center justify-center mb-2">
                      <span className="text-xl font-bold">
                        {formatTime(time)}
                      </span>
                    </div>

                    <div className="flex flex-col items-center justify-center space-y-4 ">
                      <div className="flex items-center justify-center -space-x-3 lg:space-x-8 xl:space-x-8 2xl:space-x-8">
                        {!isRecording ? (
                          <button
                            onClick={startRecording}
                            className="w-36 h-12 flex items-center justify-center bg-[#F2911B] rounded-full text-white text-2xl shadow-lg hover:bg-white hover:border-2 hover:border-[#F2911B] hover:text-[#F2911B] relative"
                            title="Click to start"
                          >
                            {isSubmitting ? (
                              <FontAwesomeIcon icon={faSpinner} />
                            ) : (
                              <>
                                <FontAwesomeIcon icon={faMicrophone} />
                                <span className="ml-2">Start</span>
                              </>
                            )}
                          </button>
                        ) : (
                          <>
                            <button
                              onClick={pauseResumeRecording}
                              title={isPaused ? "Resume" : "Pause"}
                              className={`flex items-center justify-center w-40 h-12 text-gray-700 text-xl transition-opacity rounded-full hover:bg-gray-200 border border-gray-500  ${
                                isPaused
                                  ? "opacity-50 animate-pulse-2 bg-gray-200"
                                  : "opacity-100 bg-transparent"
                              }`}
                            >
                              <FontAwesomeIcon icon={faPause} />
                              <span className="ml-2">
                                {isPaused ? "Resume" : "Pause"}
                              </span>
                            </button>
                            <div className="relative flex items-center justify-center">
                              <button
                                onClick={stopRecording}
                                className="w-40 h-12  flex items-center justify-center bg-[#F2911B] rounded-full text-white text-xl shadow-lg z-10 mx-4 hover:animate-pulse hover:delay-200"
                              >
                                <FontAwesomeIcon icon={faStop} />
                                <span className="ml-2">Submit</span>
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <audio
                    src={audioURL}
                    controls
                    className="mt-4 w-full hidden"
                  ></audio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading && !patientThread && !patientSessions ? (
        <div className="w-7 h-7 border-4 border-t-4 border-t-transparent border-[#f2911b] rounded-full animate-spin"></div>
      ) : (
        <div className="flex flex-col items-center my-4 p-4 bg-[#E8ECF4] rounded-2xl">
          <div className="space-y-6 mx-auto w-full">
            <div className="relative bg-white shadow-md rounded-3xl p-6 overflow-hidden w-full">
              <h2 className="text-xl font-bold mb-4">Patient Plan</h2>
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-4">
                  <p className="w-full text-justify">{patientPlan}</p>
                </div>
              </div>
            </div>

            <div className="relative bg-white shadow-md rounded-3xl p-6 overflow-hidden w-full">
              <div className="w-full">
                <div>
                  {patientSessions.length !== 0 ? (
                    patientSessions
                      ?.slice(0, visibleSessionsCount)
                      .map((session) => (
                        <div
                          key={session.id}
                          className="mb-4 border rounded-md p-4"
                        >
                          <div
                            className="flex justify-between items-center cursor-pointer"
                            onClick={() => toggleSessionExpansion(session.id)}
                          >
                            <h3 className="text-lg font-semibold">
                              {moment(session.created_at).format(
                                "MMM DD, YYYY hh:mm A"
                              ) +
                                " - " +
                                session.title}
                            </h3>
                            <FontAwesomeIcon
                              icon={
                                expandedSessionId === session.id
                                  ? faChevronUp
                                  : faChevronDown
                              }
                            />
                          </div>
                          {expandedSessionId === session.id && (
                            <div
                              className="mt-4 w-full bg-gray-100 p-4 rounded"
                              style={{ minWidth: "full" }}
                            >
                              {/* Session Content */}
                              <div
                                className="prose max-w-none leading-tight mt-4 space-y-0"
                                style={{ color: "black" }}
                                dangerouslySetInnerHTML={{
                                  __html: session.notes,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ))
                  ) : (
                    <p className="text-gray-400 my-4">No Sessions</p>
                  )}
                  {visibleSessionsCount < patientSessions.length && (
                    <button
                      onClick={handleLoadMoreSessions}
                      className="bg-[#f2911b] text-white px-4 py-2 rounded mt-4"
                    >
                      Load More
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WhisperRecord;
