import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import "react-confirm-alert/src/react-confirm-alert.css";
import { API_BASE_URL, ENDPOINTS } from "../../apiConfig";
import Logo from "../../Assets/images/astra-scribe-logo.png";
import { FiMenu, FiTrash2, FiSearch } from "react-icons/fi";
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import { RiMagicLine } from "react-icons/ri";
import {
  AiOutlineQuestionCircle,
  AiOutlineLogout,
  AiOutlineSetting,
  AiOutlineClose,
} from "react-icons/ai";
import { CiUser } from "react-icons/ci";
import { FiChevronRight, FiChevronDown } from "react-icons/fi"; // Import arrow icons

import ShareHistory from "../History/ShareHistory";

const Sidebar = () => {
  const [shouldReload, setShouldReload] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(
    () => window.innerWidth < 1024
  );
  const [isOpen, setIsOpen] = useState(false);
  const [titles, setTitles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isNavbarDropdownVisible, setIsNavbarDropdownVisible] = useState(false);
  const searchInputRef = useRef(null);
  const location = useLocation();
  const history = useHistory();

  const Username = localStorage.getItem("Username") || "User";
  const GoogleProfileURL = localStorage.getItem("GoogleProfileURL");
  const [statuses, setStatuses] = useState(() => {
    // Load statuses from local storage on initial load
    const storedStatuses = JSON.parse(localStorage.getItem("statuses")) || {};
    return storedStatuses;
  });

  const navigateToDefault = () => {
    window.location.href = "/search-patient";
  };

  const toggleSidebarCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const checkLocalStorage = () => {
      const isTranscriptionSuccess = localStorage.getItem(
        "transcriptionSuccess"
      );
      if (isTranscriptionSuccess === "true") {
        setShouldReload(true);
        localStorage.removeItem("transcriptionSuccess");
      }
    };

    const intervalId = setInterval(() => {
      checkLocalStorage();
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    // Combine conditions to ensure `fetchHistoryTitles` is not called redundantly
    if (shouldReload || location.pathname) {
      fetchHistoryTitles();
      setShouldReload(false);
    }
  }, [shouldReload, location.pathname]);

  const toggleSearchBar = () => {
    setIsSearchBarVisible(!isSearchBarVisible);
    if (!isSearchBarVisible) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 100);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const toggleNavbarDropdown = () => {
    setIsNavbarDropdownVisible(!isNavbarDropdownVisible);
  };

  const fetchHistoryTitles = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please login first.");
      return;
    }

    try {
      const response = await axios.get(ENDPOINTS.GET_SPEECH_HISTORY, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      const completeData = response.data;
      console.log("Complete Data\n", completeData);

      if (completeData && Array.isArray(completeData.data)) {
        const fetchedTitles = completeData.data;

        // Set "Pending" status for new history items if not already stored
        const updatedStatuses = { ...statuses };
        fetchedTitles.forEach((title) => {
          if (!updatedStatuses[title.id]) {
            updatedStatuses[title.id] = title.status || "Pending";
          }
        });

        // Sort by updated_at in descending order (newest first)
        const sortedTitles = fetchedTitles.sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });

        setTitles(sortedTitles); // Set sorted titles (newest first)
        setStatuses(updatedStatuses);
        localStorage.setItem("statuses", JSON.stringify(updatedStatuses));
      } else {
        setTitles([]);
      }
    } catch (error) {
      setTitles([]);
    } finally {
      setLoading(false);
    }
  };

  const handleHistoryClick = (id) => {
    if (window.innerWidth < 1024) {
      setIsCollapsed(!isCollapsed);
    }

    // Update the status to "Ready"
    setStatuses((prevStatuses) => {
      const newStatuses = { ...prevStatuses, [id]: "Ready" };
      // Save to local storage
      localStorage.setItem("statuses", JSON.stringify(newStatuses));
      return newStatuses;
    });
  };

  const Logout = () => {
    setIsDropdownVisible(false);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to log out?",
      showCancelButton: true,
      confirmButtonColor: "#f2911b",
      cancelButtonColor: "gray",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        popup: "max-w-xs",
        confirmButton: "w-24", // Tailwind class for setting button width
        cancelButton: "w-24",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("Username"); // Remove only the Username
        localStorage.removeItem("token"); // Remove only the token
        localStorage.clear();
        history.push("/");
      }
    });
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }

    try {
      const response = await axios.delete(
        `${API_BASE_URL}speech_history/${id}/delete/`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      );

      if (
        response.data &&
        response.data.message === "Speech thread deleted successfully"
      ) {
        setTitles(titles.filter((title) => title.id !== id));
      }
    } catch (error) {
      // Handle error
    }
  };

  const confirmDelete = (id) => {
    Swal.fire({
      title: "Confirm to delete",
      text: "Are you sure you want to delete this speech history?",
      showCancelButton: true,
      confirmButtonColor: "#f2911b",
      cancelButtonColor: "gray",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        popup: "max-w-xs",
        confirmButton: "w-24", // Tailwind class for setting button width
        cancelButton: "w-24",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
        Swal.fire({
          title: "Deleted",
          text: "Your speech has been history deleted",
          confirmButtonColor: "#f2911b",
          confirmButtonText: "OK",
          customClass: {
            popup: "max-w-xs",
            confirmButton: "w-24", // Tailwind class for setting button width
            cancelButton: "w-24",
          },
        });
      }
    });
  };

  const clearAllHistory = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }

    Swal.fire({
      title: "clear all?",
      text: "Are you sure you want to clear all speech history?",
      showCancelButton: true,
      confirmButtonColor: "#f2911b",
      cancelButtonColor: "gray",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        popup: "max-w-xs",
        confirmButton: "w-24", // Tailwind class for setting button width
        cancelButton: "w-24",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            "https://astrascribe-india-be.azurewebsites.net/speech_history/clear/",
            { headers: { Authorization: `Bearer ${token}` } }
          );
          await fetchHistoryTitles();
          Swal.fire({
            title: "Cleared!",
            text: "All history has been cleared.",
            icon: null, // Removes the success icon
            customClass: {
              popup: "max-w-xs", // Tailwind class to set the max-width of the popup
              confirmButton:
                "bg-[#f2911b] text-white py-2 px-4 rounded hover:bg-[#d07b15]", // Tailwind classes for the button
            },
            buttonsStyling: false, // Disable default button styling
            confirmButtonText: "OK",
          });
        } catch (error) {
          console.error("Error clearing history:", error);

          Swal.fire({
            title: "Error!",
            text: "Failed to clear history.",
            icon: null, // Removes the success icon
            customClass: {
              popup: "max-w-xs", // Tailwind class to set the max-width of the popup
              confirmButton:
                "bg-[#f2911b] text-white py-2 px-4 rounded hover:bg-[#d07b15]", // Tailwind classes for the button
            },
            buttonsStyling: false, // Disable default button styling
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const handleCloseDropdown = () => {
    setIsDropdownVisible(false);
  };

  const truncateTitle = (title, maxLength) => {
    return title.length > maxLength
      ? title.substring(0, maxLength) + "..."
      : title;
  };

  const categorizeHistoryByDate = (titles) => {
    const categorizedTitles = {};

    titles.forEach((title) => {
      const dateKey = moment
        .utc(title.updated_at)
        .local()
        .format("MMM DD, YYYY");

      if (!categorizedTitles[dateKey]) {
        categorizedTitles[dateKey] = [];
      }
      categorizedTitles[dateKey].push(title);
    });

    return categorizedTitles;
  };

  const filteredTitles = searchQuery
    ? titles.filter(
        (title) =>
          title.patient.firstname &&
          title.patient.lastname &&
          (title.patient.firstname + " " + title.patient.lastname)
            .toLowerCase()
            .includes(searchQuery.trim().toLowerCase())
      )
    : titles;
  const [expandedDates, setExpandedDates] = useState({}); // Track expanded dates

  // Function to toggle date history visibility
  const toggleDateVisibility = (date) => {
    setExpandedDates((prevState) => ({
      ...prevState,
      [date]: !prevState[date], // Toggle the visibility for the clicked date
    }));
  };

  console.log("filteredTitles\n", filteredTitles);

  const categorizedTitles = categorizeHistoryByDate(filteredTitles);

  useEffect(() => {
    // Ensure expandedDates are set for all categorized titles
    if (categorizedTitles) {
      const updatedExpandedDates = { ...expandedDates };

      // Check if any date keys are missing in expandedDates and set them to expanded by default
      Object.keys(categorizedTitles).forEach((date) => {
        if (updatedExpandedDates[date] === undefined) {
          updatedExpandedDates[date] = true; // Set new dates to expanded
        }
      });

      // Only update if there are new changes to avoid unnecessary updates
      if (
        JSON.stringify(updatedExpandedDates) !== JSON.stringify(expandedDates)
      ) {
        setExpandedDates(updatedExpandedDates);
      }
    }
  }, [categorizedTitles]);

  const handleUpgradePlan = () => {
    history.push("/pricing");
  };
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
        setIsNavbarDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //filter opn date grouping

  return (
    <>
      <div className="hidden fixed top-0 left-0 w-full z-50 bg-transparent p-4 bg-white flex justify-between items-center">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="text-black focus:outline-none space-x-2"
        >
          <FiMenu className="h-6 w-6 inline-block hidden" />
          <img
            src={Logo}
            alt="Astra Scribe Logo"
            className="h-8 w-auto cursor-pointer inline-block"
          />
        </button>

        <div className="flex items-center space-x-4">
          <div
            onClick={toggleNavbarDropdown}
            className="flex items-center space-x-2 border border-gray-600 rounded-full px-4 py-2 hover:bg-gray-700 cursor-pointer"
          >
            {GoogleProfileURL ? (
              <img
                src={GoogleProfileURL}
                alt="Profile"
                className="h-7 w-7 rounded-full"
              />
            ) : (
              <div className="h-7 w-7 border-[#f2911b] border rounded-full bg-transparent flex items-center justify-center text-sm font-bold text-black">
                {Username.charAt(0).toUpperCase()}
              </div>
            )}
          </div>

          <button
            onClick={handleUpgradePlan}
            className="flex items-center border border-orange-400 rounded-full px-4 py-2 text-black text-sm hover:bg-gray-700 transition duration-300"
          >
            <div className="flex items-center space-x-2">
              <div className="bg-[#1E232C] p-1 rounded-full text-orange-400">
                <RiMagicLine className="h-5 w-5" />
              </div>
            </div>
          </button>
        </div>

        {isNavbarDropdownVisible && (
          <div
            ref={dropdownRef}
            className="absolute right-4 top-14 w-40 bg-[#ffffff] border-x-2 border-y border-gray-700 rounded-xl flex flex-col space-y-2 z-10"
          >
            <div className="flex justify-between items-center px-4 py-2">
              <div
                className="flex items-center text-black cursor-pointer hover:text-[#f2911b]"
                onClick={() => {
                  history.push("/setting");
                  setIsNavbarDropdownVisible(false);
                }}
              >
                <CiUser className="mr-2" />
                <span>Account</span>
              </div>
              <AiOutlineClose
                className="text-black cursor-pointer hover:bg-gray-700 hover:text-white"
                onClick={() => setIsNavbarDropdownVisible(false)}
              />
            </div>
            <div
              className="px-4 py-2 text-black cursor-pointer hover:text-[#f2911b] flex items-center"
              onClick={() => {
                history.push("/setting");
                setIsNavbarDropdownVisible(false);
              }}
            >
              <AiOutlineSetting className="mr-2" />
              <span>Setting</span>
            </div>
            <div
              className="px-4 py-2 text-black cursor-pointer hover:text-[#f2911b] flex items-center"
              onClick={Logout}
            >
              <AiOutlineLogout className="mr-2" />
              <span>Log Out</span>
            </div>
          </div>
        )}
      </div>

      {/* Main Sidebar */}
      {!isCollapsed ? (
        <div
          className={`w-64 bg-[#1E232C] text-white flex flex-col justify-between fixed lg:relative inset-y-0 left-0 transform lg:translate-x-0 transition-transform duration-300 ease-in-out z-50`}
          style={{ height: "100vh" }}
        >
          <div className="flex-1 overflow-y-auto overflow-x-hidden scrollbar-sidebar">
            <div className="flex items-center justify-between px-6 py-4">
              <img
                src={Logo}
                alt="Astra Scribe Logo"
                className="h-6 w-auto cursor-pointer"
                onClick={navigateToDefault}
              />
              <button
                onClick={toggleSidebarCollapse}
                className="text-black bg-[#f2911b] rounded-full p-1 focus:outline-none"
              >
                <MdOutlineKeyboardDoubleArrowLeft className="h-5 w-5" />
              </button>
            </div>

            <div className="px-4 flex items-center justify-between">
              <a
                onClick={() => setIsOpen(!isOpen)}
                href="/search-patient"
                className="bg-[rgb(242,145,27)] py-1 px-6 lg:px-8 border-2 border-[#f2911b] rounded-full text-white font-regular flex items-center hover:border-2 hover:border-[#f2911b] hover:bg-transparent no-underline hover:no-underline"
              >
                <span className="mr-2">+</span> Next Patient
              </a>
              <button
                title="Search"
                className="text-white bg-gray-600 p-2 rounded-full flex items-center justify-center ml-2 hover:bg-white hover:text-gray-600"
                onClick={toggleSearchBar}
              >
                <FiSearch className="h-4 w-4" />
              </button>
            </div>
            {isSearchBarVisible && (
              <div className="px-4 mt-2 relative">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-800 text-white rounded-full focus:outline-none"
                  placeholder="Search conversations..."
                  ref={searchInputRef}
                />
                {/* Cross icon to clear the search and close the search bar */}
                <AiOutlineClose
                  title="Clear Search"
                  className="absolute right-6 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                  onClick={() => {
                    setSearchQuery(""); // Clear the search query
                    setIsSearchBarVisible(false); // Close the search bar
                  }}
                />
              </div>
            )}

            <nav className="mt-4 pl-4 space-y-2">
              {loading ? (
                <div className="text-gray-500 text-xm mt-4 pt-4">
                  Loading...
                </div>
              ) : filteredTitles.length === 0 ? (
                <div className="text-gray-500 text-xm mt-4 pt-4">
                  No History Available
                </div>
              ) : (
                <>
                  <div className=" flex -mb-4 items-end justify-end mx-4 ">
                    {" "}
                    <span
                      title="Clear all history"
                      className="cursor-pointer hover:text-[#f2911b]"
                      onClick={clearAllHistory}
                    >
                      clear all
                    </span>{" "}
                  </div>
                  {Object.keys(categorizedTitles).map((date) => (
                    <div key={date}>
                      <div
                        className="text-white text-xs flex items-center cursor-pointer"
                        title={
                          expandedDates[date]
                            ? "Click to collapse history for this date"
                            : "Click to expand history for this date"
                        }
                        onClick={() => toggleDateVisibility(date)} // Toggle on click
                      >
                        {/* Arrow Icon (right or down based on expanded state) */}
                        {expandedDates[date] ? (
                          <FiChevronDown className="mr-2" />
                        ) : (
                          <FiChevronRight className="mr-2" />
                        )}
                        {date} {/* Display the date */}
                      </div>

                      {/* Show titles if the date is expanded */}
                      {expandedDates[date] && (
                        <div className="">
                          {categorizedTitles[date].map((item, index) => (
                            <Link
                              to={`/conversation/${item.patient.id}`}
                              title={`${
                                item.patient.firstname +
                                " " +
                                item.patient.lastname
                              }`}
                              key={index}
                              className={`flex flex-col p-4 mt-2 mr-2 text-white hover:bg-gray-700 rounded-lg border border-gray-500 shadow-md ${
                                location.pathname === `/conversation/${item.patient.id}`
                                  ? "border-2 border-orange-400 bg-gray-800"
                                  : ""
                              }`}
                              style={{ textDecoration: "none" }}
                              onClick={() => handleHistoryClick(item.id)}
                            >
                              <div className="flex items-center justify-between space-x-2">
                                <div className="flex items-center space-x-2">
                                  <span className="text-sm truncate font-bold text-gray-200">
                                    {item.patient.firstname &&
                                    item.patient.lastname
                                      ? (
                                          item.patient.firstname +
                                          " " +
                                          item.patient.lastname
                                        ).length > 10
                                        ? (
                                            item.patient.firstname +
                                            " " +
                                            item.patient.lastname
                                          ).substring(0, 10) + "..."
                                        : item.patient.firstname +
                                          " " +
                                          item.patient.lastname
                                      : "Unknown Patient"}
                                  </span>
                                  {/* <span className="text-xs text-gray-400">{moment.utc(item.updated_at).local().format("hh:mm A")}</span> */}
                                </div>
                                <div className="flex items-center">
                                  {/* Time Display */}
                                  <span className="text-xs text-gray-400 mr-2">
                                    {moment
                                      .utc(item.updated_at)
                                      .local()
                                      .format("hh:mm A")}
                                  </span>
                                  <button
                                    className="hover:text-orange-500 text-white focus:outline-none"
                                    title="Delete"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      confirmDelete(item.id);
                                    }}
                                  >
                                    <FiTrash2 className="h-4 w-4" />
                                  </button>
                                </div>
                              </div>

                              <hr className="my-2 border-t border-gray-500" />

                              <div className="flex flex-col justify-between">
                                <div className="text-gray-300 text-xs flex justify-between items-center">
                                  <div>
                                    <strong>D.O.B: </strong>
                                    {item.patient.date_of_birth
                                      ? item.patient.date_of_birth
                                      : "Null"}
                                  </div>
                                  {/* <div
                                    className={`text-xs ml-4 ${
                                      statuses[item.id] === "Completed"
                                        ? "text-gray-400"
                                        : "text-[#f2911b]"
                                    }`}
                                  >
                                    {statuses[item.id] === "Pending"
                                      ? statuses[item.id]
                                      : ""}
                                  </div> */}
                                </div>
                              </div>
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
            </nav>
          </div>

          <div className="relative mt-2 px-4 mb-20 sm:mb-10 md:mb-4 lg:mb-3 ">
            <div
              onClick={toggleDropdown}
              title={`(${Username}) Your Profile and setting`}
              className="flex items-center space-x-2 border border-gray-600 rounded-full px-4 py-2 hover:bg-gray-700 cursor-pointer"
            >
              {GoogleProfileURL ? (
                <img
                  src={GoogleProfileURL}
                  alt="Profile"
                  className="h-7 w-7 rounded-full"
                />
              ) : (
                <div className="h-7 w-7 border-[#f2911b] border rounded-full bg-transparent flex items-center justify-center text-xs font-bold text-white">
                  {Username.charAt(0).toUpperCase()}
                </div>
              )}
              <span className="text-white text-lg">
                {Username.length > 15
                  ? Username.substring(0, 15) + "..."
                  : Username}
              </span>
            </div>
            {isDropdownVisible && (
              <div
                ref={dropdownRef}
                className="absolute right-2 bottom-24 w-40 bg-[#E8ECF4] border-t border-gray-700 rounded-xl flex flex-col space-y-2 z-10"
              >
                <div
                  className="flex justify-between items-center px-4 py-2"
                  onClick={() => {
                    history.push("/setting");
                    setIsDropdownVisible(false);
                  }}
                >
                  <div className="flex items-center text-black cursor-pointer hover:text-[#f2911b]">
                    <CiUser className="mr-2" />
                    <span>Account</span>
                  </div>
                  <AiOutlineClose
                    className="text-black cursor-pointer hover:bg-gray-700 hover:text-white"
                    onClick={handleCloseDropdown}
                  />
                </div>
                <div
                  className="px-4 py-2 text-black cursor-pointer hover:text-[#f2911b] flex items-center"
                  onClick={() => {
                    history.push("/setting");
                    setIsDropdownVisible(false);
                  }}
                >
                  <AiOutlineSetting className="mr-2" />
                  <span>Setting</span>
                </div>
                <div
                  className="px-4 py-2 text-black cursor-pointer hover:text-[#f2911b] flex items-center"
                  onClick={Logout}
                >
                  <AiOutlineLogout className="mr-2" />
                  <span>Log Out</span>
                </div>
              </div>
            )}

            <button
              onClick={handleUpgradePlan}
              title="Upgrade your Plan"
              className="flex hidden items-center w-full border border-orange-400 rounded-full px-4 py-2 text-white text-lg hover:bg-gray-700 transition duration-300 mt-2"
            >
              <div className="flex items-center space-x-2 w-4/5">
                <div className="bg-[#1E232C] p-1 rounded-full text-orange-400">
                  <RiMagicLine className="h-5 w-5" />
                </div>
                <span>Upgrade Plan</span>
              </div>
              <div className="flex justify-center items-center w-1/5">
                <AiOutlineQuestionCircle className="text-gray-400 h-7 w-7" />
              </div>
            </button>
          </div>
        </div>
      ) : (
        // Collapsed Sidebar
        <div
          className="w-auto px-2 bg-[#1E232C] flex items-start justify-center fixed lg:relative inset-y-0 left-0 transform lg:translate-x-0 transition-transform duration-300 ease-in-out z-50"
          style={{ height: "100vh", paddingTop: "0.8rem" }}
        >
          <button
            onClick={toggleSidebarCollapse}
            className="text-black bg-[#f2911b] rounded-full p-1 focus:outline-none ml-auto"
          >
            <MdOutlineKeyboardDoubleArrowRight className="h-5 w-5" />
          </button>
        </div>
      )}

      {isOpen && (
        <div
          className="absolute inset-0 opacity-100 z-10"
          onClick={() => setIsOpen(!isOpen)}
        ></div>
      )}
    </>
  );
};

export default Sidebar;
