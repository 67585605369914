import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ENDPOINTS } from "../apiConfig";
import { ToastContainer, toast } from "react-toastify";

function AddPatientPopup({ isOpen, onClose, onPatientAdded }) {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    date_of_birth: "",
    identification_number: "",
    gender: "",
    marital_status: "",
    address: "",
  });
  const [age, setAge] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [aadharError, setAadharError] = useState("");
  const [selectedPatient, setSelectedPatient] = useState("");

  useEffect(() => {}, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Calculate age if DOB is changed
    if (name === "date_of_birth" && value) {
      const dob = new Date(value);
      const currentDate = new Date();
      const calculatedAge = currentDate.getFullYear() - dob.getFullYear();
      setAge(calculatedAge);
    }
  };

  const validateAadharSimple = (aadhar) => {
    if (!aadhar) return "";
    if (aadhar.length !== 12) {
      return "Aadhar number must be exactly 12 digits.";
    }
    if (!/^\d+$/.test(aadhar)) {
      return "Aadhar number must contain only numeric digits.";
    }
    return "";
  };

  const handleAadharChangeSimple = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const error = validateAadharSimple(value);
    setAadharError(error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (aadharError) {
      toast.error("Please fix the errors in the ID.");
      setIsSubmitting(false);
      return;
    }

    const dataToSubmit = {
      ...formData,
      identification_number: formData.identification_number || null,
    };

    console.log("formData\n", dataToSubmit);
    try {
      const response = await axios.post(ENDPOINTS.POST_PATIENT_PROFILE_LIST,
        dataToSubmit,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Patient added successfully!");
      setFormData({
        firstname: "",
        lastname: "",
        date_of_birth: "",
        identification_number: "",
        gender: "",
        marital_status: "",
        address: "",
      });
      setAge("");
      setAadharError("");
      onPatientAdded(response.data);
      setTimeout(() => {
        localStorage.setItem("Patient", JSON.stringify(response.data));
        history.push("/transcription");
      }, [2000]);
    } catch (err) {
      toast.error("Failed to add patient. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto">
      <ToastContainer />
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-lg font-semibold mb-4">Add New Patient</h2>
        <form onSubmit={handleSubmit}>
          {/* First Name */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              First Name*
            </label>
            <input
              type="text"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              className="w-full border px-3 py-2 rounded-md"
              required
              placeholder="First Name"
            />
          </div>

          {/* Last Name */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Last Name*</label>
            <input
              type="text"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              className="w-full border px-3 py-2 rounded-md"
              required
              placeholder="Last Name"
            />
          </div>

          {/* Date of Birth */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Date of Birth*
            </label>
            <input
              type="date"
              name="date_of_birth"
              value={formData.date_of_birth}
              onChange={handleChange}
              className="w-full border px-3 py-2 rounded-md"
              required
            />
          </div>

          {/* Age (Calculated) */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Age</label>
            <input
              type="text"
              value={age || ""}
              disabled
              className="w-full border px-3 py-2 rounded-md"
              placeholder="Calculated based on DOB"
            />
          </div>

          {/* Identification Number */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">ID</label>
            <input
              type="text"
              name="identification_number"
              value={formData.identification_number}
              onChange={(e) => handleAadharChangeSimple(e)}
              className="w-full border px-3 py-2 rounded-md"
              placeholder="Aadhar Card No."
            />
            {aadharError && (
              <p className="text-red-500 text-sm mt-1">{aadharError}</p>
            )}
          </div>

          {/* Gender */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Gender</label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className="w-full border px-3 py-2 rounded-md"
            >
              <option value="">Select...</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>

          {/* Marital Status */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Marital Status
            </label>
            <select
              name="marital_status"
              value={formData.marital_status}
              onChange={handleChange}
              className="w-full border px-3 py-2 rounded-md"
            >
              <option value="">Select...</option>
              <option value="single">Single</option>
              <option value="married">Married</option>
              <option value="divorced">Divorced</option>
            </select>
          </div>

          {/* Address */}
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Address</label>
            <textarea
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="w-full border px-3 py-2 rounded-md"
              placeholder="Address"
            />
          </div>

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-[#f2911b] text-white rounded-md"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Adding..." : "Add Patient"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddPatientPopup;
