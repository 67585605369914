import React, { useState, useEffect } from "react";
import "./PatientTableListing.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import AddPatientPopup from "./AddPatientPopup.jsx";
import PolyDiarize from "./Screen/PolyDiarize.jsx";

function PatientTableListing() {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [patientData, setPatientData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState([]);
  //   const [isPatientSelected, setIsPatientSelected] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const fetchData = async (url = null, search = "") => {
    try {
      const response = await axios.get(
        "https://astrascribe-india-be.azurewebsites.net/patient_profile_list/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: search ? { search } : {},
        }
      );
      setData(response.data);
      setPatientData(
        url
          ? [...patientData, ...response.data.results.data]
          : response.data.results.data
      );
      setIsLoading(false);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    fetchData(null, e.target.value);
  };

  const handlePatientSelect = (patientId) => {
    const selectedPat = patientData.find((patient) => patient.id === patientId);
    // setSelectedPatient(selectedPat);
    // console.log("Selected Patient:", selectedPatient);

    localStorage.setItem("Patient", JSON.stringify(selectedPat));

    // console.log()
    history.push("/transcription");
    // setIsPatientSelected(true);
  };

  const handlePatientAdded = (newPatient) => {
    setPatientData((prev) => [newPatient, ...prev]);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 overflow-hidden">
      {isLoading && !patientData.length ? (
        <div className="w-7 h-7 border-4 border-t-4 border-t-transparent border-[#f2911b] rounded-full animate-spin"></div>
      ) : (
        <div className="w-full max-w-4xl">
          <div className="flex mb-4 w-full items-center gap-2">
            <input
              type="text"
              className="flex-grow px-4 py-2 border rounded-md"
              placeholder="Search Patient..."
              value={searchTerm}
              onChange={handleSearch}
            />
            <button
              className="bg-[#f2911b] hover:bg-[#f2911be5] text-white font-bold w-auto py-2 px-4 rounded-md"
              title="Add New Patient"
              onClick={() => setIsPopupOpen(true)}
            >
              + Add New Patient
            </button>
          </div>
          <div className="bg-white shadow-md rounded-lg flex flex-col justify-center items-center">
            <div className="w-full max-h-[calc(100vh-300px)] overflow-y-scroll hidden-scrollbar">
              <table className="min-w-full divide-y divide-white">
                <thead className="bg-[#f2911b] text-white">
                  <tr>
                    <th className="px-6 py-3 text-left text-sm font-semibold">
                      Patient Name
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-semibold">
                      Date of Birth
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-semibold">
                      Select
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-100">
                  {patientData.map((item, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {item.firstname + " " + item.lastname}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item.date_of_birth}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-4 py-1 inline-flex text-sm font-medium rounded-full">
                          <button
                            className="select-patient-btn px-3 py-1 bg-[#f2911b] hover:bg-gray-400 text-white font-bold rounded-sm"
                            data-id={item.id}
                            onClick={() => handlePatientSelect(item.id)}
                          >
                            Select
                          </button>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {data?.next && (
              <button
                id="loadMoreBtn"
                className="mt-4 bg-[#f2911b] hover:bg-[#f2911be5] text-white font-bold py-2 px-4 rounded-sm mb-4 flex items-center justify-center"
                onClick={async () => {
                  setIsButtonLoading(true);
                  try {
                    const response = await axios.get(data.next, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                      },
                    });

                    setData(response.data);

                    setPatientData((prevData) => {
                      const previousDataArray = Array.isArray(prevData)
                        ? prevData
                        : [];
                      return [
                        ...previousDataArray,
                        ...response.data.results.data,
                      ];
                    });
                  } catch (err) {
                    console.error("Error loading more data:", err.message);
                  } finally {
                    setIsButtonLoading(false);
                  }
                }}
              >
                {isButtonLoading ? (
                  <div className="w-5 h-5 border-4 border-t-4 border-t-transparent border-white rounded-full animate-spin"></div>
                ) : (
                  "Load More"
                )}
              </button>
            )}
          </div>
        </div>
      )}
      {error && (
        <div className="text-red-500 font-bold text-center mt-4">
          Error: {error}
        </div>
      )}

      <AddPatientPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onPatientAdded={handlePatientAdded}
      />
    </div>
  );
}

export default PatientTableListing;
