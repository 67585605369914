import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTimes,
  faDownload,
  faTrash,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileImage,
  faFileAudio,
  faFileText,
  faFolderOpen,
  faCheck,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL, ENDPOINTS } from "../../apiConfig";

const ReportsView = ({ speechThreadID, setIsReportsSidebarOpen }) => {
  const token = localStorage.getItem("token");
  const [patientFiles, setPatientFiles] = useState([]);
  const [formattedFiles, setFormattedFiles] = useState([]);

  const [isUploading, setIsUploading] = useState(false);
  const [showUploadBox, setShowUploadBox] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const formatted = patientFiles?.map((patient) => ({
      ...patient,
      uploaded_at: moment(patient.uploaded_at).format("MMM DD, YYYY hh:mm A"),
    }));
    setFormattedFiles(formatted);
  }, [patientFiles]);

  const fetchPatientFiles = async () => {
    try {
      const response = await axios.get(
        `${ENDPOINTS.GET_PATIENT_FILES}?page=${currentPage}&speechthread_id=${speechThreadID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { results, next, previous, count } = response.data;
      setPatientFiles(results.reverse() || []);
      setNextPage(next || null);
      setPreviousPage(previous || null);
      setTotalCount(count || 0);
    } catch (error) {
      console.error("Error fetching files:", error);
      toast.error("Failed to fetch files. Please try again.", {
        position: "top-right",
        autoClose: 500,
      });
    }
  };

  useEffect(() => {
    fetchPatientFiles();
  }, [currentPage]);

  const handleFileChange = (e) => {
    setSelectedFile(Array.from(e.target.files));
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast.info("Please select a file to upload.", {
        position: "top-right",
        autoClose: 500,
      });
      return;
    }
    setIsUploading(true);
    const formData = new FormData();
    formData.append("speechthread_id", speechThreadID);
    selectedFile.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await axios.post(
        ENDPOINTS.UPLOAD_PATIENT_FILE,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const uploadedFiles = response.data;
      if (Array.isArray(uploadedFiles)) {
        uploadedFiles.forEach((file) => {
          setPatientFiles((prevFiles) => [...prevFiles, file]);
        });
      }
      fetchPatientFiles();
      toast.success("File(s) uploaded successfully!", {
        position: "top-right",
        autoClose: 500,
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Failed to upload file. Please try again.", {
        position: "top-right",
        autoClose: 500,
      });
    } finally {
      setIsUploading(false);
      setShowUploadBox(false);
      setSelectedFile([]);
    }
  };

  const handleDeleteFile = async (fileID) => {
    try {
      await axios.delete(`${API_BASE_URL}patient_file/${fileID}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setPatientFiles((prevFiles) =>
        prevFiles.filter((file) => file.id !== fileID)
      );
      toast.success("File deleted successfully!", {
        position: "top-right",
        autoClose: 500,
      });
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to delete file. Please try again.", {
        position: "top-right",
        autoClose: 500,
      });
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const getFileIcon = (fileName) => {
    const fileExtension = fileName?.split(".").pop().toLowerCase();

    if (fileExtension === "pdf") {
      return <FontAwesomeIcon icon={faFilePdf} className="text-red-500" />;
    }
    if (fileExtension === "doc" || fileExtension === "docx") {
      return <FontAwesomeIcon icon={faFileWord} className="text-blue-500" />;
    }
    if (
      fileExtension === "xls" ||
      fileExtension === "xlsx" ||
      fileExtension === "csv"
    ) {
      return <FontAwesomeIcon icon={faFileExcel} className="text-green-500" />;
    }
    if (["jpg", "jpeg", "png", "gif", "bmp", "tiff"].includes(fileExtension)) {
      return <FontAwesomeIcon icon={faFileImage} className="text-purple-500" />;
    }
    if (["mp3", "wav", "ogg", "flac"].includes(fileExtension)) {
      return <FontAwesomeIcon icon={faFileAudio} className="text-yellow-500" />;
    }
    if (fileExtension === "txt") {
      return <FontAwesomeIcon icon={faFileText} className="text-gray-700" />;
    }
    return <FontAwesomeIcon icon={faTimes} className="text-gray-500" />;
  };

  console.log("formattedFiles\n", formattedFiles);

  return (
    <div
      className="fixed top-0 right-0 h-full p-4 overflow-y-auto scrollbar-custom bg-white shadow-lg transition-transform duration-300"
      style={{ width: "275px", maxWidth: "80vw" }}
    >
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-bold">Patient Reports</h3>
        <button
          onClick={() => setIsReportsSidebarOpen(false)}
          className="text-[#F2911B] hover:text-black focus:outline-none"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>

      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => setShowUploadBox(!showUploadBox)}
          className="flex items-center justify-center w-full text-xs md:text-sm text-white font-bold bg-[#f2911b] border-2 border-[#f2911b] hover:bg-white hover:text-[#F2911B] rounded-md px-4 py-2 ml-auto"
          title="View Reports"
        >
          <FontAwesomeIcon icon={faPlus} />
          &nbsp;Add New File
        </button>
      </div>
      {showUploadBox && (
        <div className="mt-2 p-4 text-center border border-gray-300 shadow-md rounded-md bg-white flex flex-col items-center">
          <label
            htmlFor="fileInput"
            className="flex flex-col items-center cursor-pointer text-sm font-medium text-gray-700 hover:text-[#f2911b] mb-2"
          >
            <FontAwesomeIcon
              icon={faFolderOpen}
              className="text-4xl text-[#f2911b] mb-1"
            />
            Upload a file
          </label>
          <input
            type="file"
            id="fileInput"
            className="hidden"
            multiple
            onChange={handleFileChange}
          />

          {selectedFile?.map((file) => (
            <p className="space-y-1 text-sm text-gray-500">{file.name}</p>
          ))}

          <button
            onClick={handleFileUpload}
            className="flex items-center justify-center w-full mt-3 text-xs md:text-sm text-white font-bold bg-[#f2911b] border-2 border-[#f2911b] hover:bg-white hover:text-[#F2911B] rounded-md px-4 py-2 ml-auto"
            disabled={isUploading || selectedFile.length === 0}
          >
            {isUploading ? (
              <BeatLoader color="white" size={10}/>
            ) : (
              <>
                <FontAwesomeIcon icon={faCheck} />
                &nbsp;Submit
              </>
            )}
          </button>
        </div>
      )}

      {formattedFiles?.length > 0 ? (
        formattedFiles.map((file, index) => (
          <div
            key={file.id || index}
            className="flex flex-col w-full border border-gray-300 bg-white shadow-md rounded-lg p-2 my-2 space-y-2"
          >
            <div className="flex items-start w-full space-x-2">
              <div className="text-3xl text-blue-500">
                {getFileIcon(file.file)}
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-semibold text-gray-700 break-words w-full">
                  {file.file.split("/").pop() || "No File Name"}
                </p>
                <p className="text-xs text-gray-500 break-words w-full">
                  Uploaded At: {file?.uploaded_at}
                </p>
              </div>
            </div>

            <div className="flex items-center ml-auto space-x-1">
              <a
                href={file.file_url}
                target="_blank"
                rel="noopener noreferrer"
                className="px-2 py-1 text-xs bg-green-500 text-white rounded-sm hover:bg-green-600 transition duration-200"
                title="Download"
              >
                <FontAwesomeIcon icon={faDownload} />
              </a>
              <button
                onClick={() => handleDeleteFile(file.id)}
                className="px-2 py-1 text-xs bg-red-500 text-white rounded-sm hover:bg-red-600 transition duration-200"
                title="Delete"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))
      ) : (
        <p className="text-gray-500 text-center">No reports available.</p>
      )}
      <div className="pagination flex justify-center space-x-2 mt-4 text-white">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={!previousPage}
          className={`px-3 py-1 rounded ${
            !previousPage ? "bg-gray-300" : "bg-[#F2911B] text-white"
          }`}
        >
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </button>

        {[...Array(Math.ceil(totalCount / 10)).keys()].map((page) => (
          <button
            key={page + 1}
            onClick={() => handlePageChange(page + 1)}
            className={`px-3 py-1 rounded ${
              page + 1 === currentPage
                ? "bg-[#F2911B] text-white"
                : "bg-gray-200"
            }`}
          >
            {page + 1}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={!nextPage}
          className={`px-3 py-1 rounded ${
            !nextPage ? "bg-gray-300" : "bg-[#F2911B] text-white"
          }`}
        >
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </button>
      </div>
    </div>
  );
};

export default ReportsView;
