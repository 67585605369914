import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FaArrowLeft, FaEnvelope, FaPhone, FaMapMarkerAlt, FaGlobe } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SinglePatient = () => {
    const { id } = useParams();
    const [userData, setUserData] = useState(null);
    const Dytoken = localStorage.getItem('token');
    const token =
    `Bearer ${Dytoken}`;
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`https://astrascribe-india-be.azurewebsites.net/patient_profile_list/${id}/retrieve/`, {
                    headers: {
                        'Authorization': token,
                        'accept': 'application/json'
                    }
                });
                setUserData(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [id]);

    if (!userData) {
        return <div>Loading...</div>;
    }

    const initials = userData.legal_name ? userData.legal_name.charAt(0).toUpperCase() : '';
    const defaultEmail = userData.email || userData.emergency_contact_email || 'Not Available';
    const defaultPhone = userData.mobile_phone || userData.home_phone || userData.work_phone || 'Not Available';
    const defaultAddress = userData.address || userData.emergency_contact_address || 'Not Available';

    return (
        <div className="min-h-screen bg-gray-100 p-4 sm:p-8">
            <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg">
                {/* Back Link */}
                <Link to="/patient-dashboard" className="text-gray-600 hover:underline flex items-center p-4 sm:p-6 hover:text-[#F2911B]">
                    <FaArrowLeft className="mr-2" /> Back to Patient Dashboard
                </Link>
                <div className="flex flex-col md:flex-row border-b border-gray-200 p-4 sm:p-8 bg-gradient-to-t from-orange-500 via-orange-400 to-orange-300 ">
                    <div className="w-full md:w-1/2 mb-4 md:mb-0">
                        <div className="flex items-center">
                            <div className="bg-gray-300 w-20 h-20 sm:w-24 sm:h-24 flex items-center justify-center text-3xl sm:text-4xl font-bold text-black rounded-full mr-4">
                                {initials}
                            </div>
                            <div>
                                <h2 className="text-xl sm:text-2xl font-bold text-gray-800">{userData.legal_name}</h2>
                                <p className="text-gray-500"> <span className='font-semibold text-black' >D.O.B : </span>{userData.date_of_birth || 'Profession not specified'}</p>
                                <p className="text-gray-500"><span className='font-semibold text-black' >Gender : </span> {userData.legal_sex || 'Not specified'}</p>
                                <p className="text-gray-500 mb-1"><span className='font-semibold text-black' >Email : </span>{userData.emergency_contact_email || 'Not specified'}</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2">
                        <div className="flex flex-col">
                            <div>
                                <p className="text-lg text-gray-800"><span className='font-semibold text-black' >Contact Number</span></p>
                                <p className="text-gray-500 mb-1"><FaPhone className="inline mr-2" />{defaultPhone}</p>
                                <p className="text-lg text-gray-800"><span className='font-semibold text-black' >Address </span></p>
                                <p className="text-gray-500"><FaMapMarkerAlt className="inline mr-2" />{defaultAddress}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-4 sm:p-8">
                    <div className="flex items-center mb-6">
                        <button className="text-[#F2911B] border-b-2 border-[#F2911B]">About</button>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                        <div>
                            <h3 className="text-lg font-semibold text-gray-800 mb-3">Contact Information</h3>
                            <p className="text-gray-500 mb-1"><FaPhone className="inline mr-2" />{defaultPhone}</p>
                            <p className="text-gray-500 mb-1"><FaMapMarkerAlt className="inline mr-2" />{defaultAddress}</p>
                            <p className="text-gray-500 mb-1"><FaEnvelope className="inline mr-2" />{defaultEmail}</p>
                            <p className="text-gray-500"><FaGlobe className="inline mr-2" />{userData.website || 'Website not available'}</p>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold text-gray-800 mb-3">Basic Information</h3>
                            <p className="text-gray-500 mb-1">Birthday: {userData.date_of_birth || 'Not specified'}</p>
                            <p className="text-gray-500">Gender: {userData.legal_sex || 'Not specified'}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-6">
                        <div>
                            <h3 className="text-lg font-semibold text-gray-800 mb-3">Additional Information</h3>
                            <p className="text-gray-500 mb-1">Sexual Orientation: {userData.sexual_orientation || 'Not specified'}</p>
                            <p className="text-gray-500 mb-1">Gender Identity: {userData.gender_identity || 'Not specified'}</p>
                            <p className="text-gray-500 mb-1">Assigned Sex at Birth: {userData.assigned_sex_at_birth || 'Not specified'}</p>
                            <p className="text-gray-500 mb-1">Pronouns: {userData.pronouns || 'Not specified'}</p>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold text-gray-800 mb-3">Emergency Contact</h3>
                            <p className="text-gray-500 mb-1">Name: {userData.emergency_contact_name || 'Not specified'}</p>
                            <p className="text-gray-500 mb-1">Relationship: {userData.emergency_contact_relationship || 'Not specified'}</p>
                            <p className="text-gray-500 mb-1">Phone: {userData.emergency_contact_phone || 'Not specified'}</p>
                            <p className="text-gray-500 mb-1">Email: {userData.emergency_contact_email || 'Not specified'}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SinglePatient;
