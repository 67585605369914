import { IoMdSunny, IoMdMoon } from "react-icons/io";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Recording from "../../Assets/images/recordIcon.png";
import DashboardMain from "../../Assets/images/dashboardmain.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Dashboard = () => {
  const history = useHistory();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.warn("Session Expired. Please log in again.", {
        onClose: () => {
          history.push("/");
        },
      });
    }
  }, [history]);

  const handleLiveRecordClick = () => {
    history.push("/live-record");
  };

  const handleGeminiRecordClick = () => {
    history.push("/gemini");
  };

  return (
    <div
      className="flex flex-col items-center mt-4 p-8 bg-[#E8ECF4] rounded-2xl"
      style={{ height: "93vh" }}
    >
      <img
        src={DashboardMain}
        alt="Change Your Speech Into Ideas"
        className="hidden lg:block md:block w-1/4 mb-8"
      />
      <div className="text-center">
        <h2 className="text-xl font-semibold">Automate Clinical Notes </h2>
        <p className="text-gray-500 mt-8">
          Instantly Transform conversation between clinicians and patients into
          clinical notes
        </p>
      </div>
   <div className="flex justify-center items-center w-full mt-8 lg:mt-20">
  <div
    className="flex flex-col items-center p-6 bg-[#1E232C] text-white rounded-3xl cursor-pointer transform transition-transform hover:scale-105 shadow-lg w-full max-w-xs"
    style={{ boxShadow: "10px 10px 0px #f2911b" }}
    onClick={handleLiveRecordClick}
  >
    <div className="flex items-center justify-center w-16 h-16 bg-[#F2911B] rounded-full mb-4">
      <img src={Recording} alt="Live Recording" className="h-10 w-10" />
    </div>
    <h3 className="text-xl font-semibold">Start Listening</h3>
  </div>
</div>

    </div>
  );
};

export default Dashboard;
