// AudioVisualizer.js
import React, { useRef, useEffect } from 'react';

function AudioVisualizer() {
  const canvasRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const animationRef = useRef(null);
  const amplitudeRef = useRef(0); // For smoothing amplitude changes
  const trailingAmplitudesRef = useRef([0, 0, 0, 0]); // For background lines
  let phase = 0; // For animating the sine wave

  useEffect(() => {
    let audioContext;
    let analyser;
    let dataArray;
    let source;

    async function setupAudio() {
      try {
        // Request access to the microphone
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        // Create a new audio context
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
        // Create a media stream source from the microphone input
        source = audioContext.createMediaStreamSource(stream);
        // Create an analyser node
        analyser = audioContext.createAnalyser();
        analyser.fftSize = 2048;
        const bufferLength = analyser.fftSize;
        dataArray = new Uint8Array(bufferLength);

        // Connect the source to the analyser
        source.connect(analyser);

        // Store references for cleanup
        audioContextRef.current = audioContext;
        analyserRef.current = analyser;
        dataArrayRef.current = dataArray;

        // Start drawing the waveform
        draw();
      } catch (err) {
        console.error('Error accessing the microphone', err);
      }
    }

    function draw() {
      const canvas = canvasRef.current;
      const canvasCtx = canvas.getContext('2d');

      let previousTime = performance.now();

      function renderFrame(currentTime) {
        animationRef.current = requestAnimationFrame(renderFrame);

        const deltaTime = currentTime - previousTime;
        previousTime = currentTime;

        analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

        const { width, height } = canvas;
        canvasCtx.clearRect(0, 0, width, height);

        // Compute average amplitude
        let sum = 0;
        for (let i = 0; i < dataArrayRef.current.length; i++) {
          const v = dataArrayRef.current[i] - 128; // Center around zero
          sum += Math.abs(v);
        }
        const avg = sum / dataArrayRef.current.length;

        // Set a threshold to determine silence
        const silenceThreshold = 5; // Adjust as needed

        // Compute intensity as a value between 0 and 1
        const intensity = avg < silenceThreshold ? 0 : avg / 128;

        // Smooth amplitude changes
        const targetAmplitude = intensity * (height / 0.375); // Max amplitude is half the container height
        const smoothingFactor = 0.05; // Adjust for slower/faster smoothing
        amplitudeRef.current += (targetAmplitude - amplitudeRef.current) * smoothingFactor;

        // Ensure amplitude is within bounds
        amplitudeRef.current = Math.max(0, Math.min(amplitudeRef.current, height / 2));

        // Update trailing amplitudes
        trailingAmplitudesRef.current = trailingAmplitudesRef.current.map((amp, index) => {
          const factor = smoothingFactor / (index + 2); // Slower smoothing for trailing lines
          return amp + (amplitudeRef.current - amp) * factor;
        });

        // Ensure trailing amplitudes are within bounds
        trailingAmplitudesRef.current = trailingAmplitudesRef.current.map(amp => {
          return Math.max(0, Math.min(amp, height / 2));
        });

        // Update phase for sine wave animation
        phase += deltaTime * 0.005; // Adjust speed as needed

        // Draw the lines
        const frequencies = [4, 4, 4, 4, 4]; // Number of sine wave cycles
        const colors = ['#FFA500', '#FF8C00', '#FF7F50', '#FFD700', '#FFDAB9']; // Different orange and yellow shades

        // Draw main line and trailing lines
        [amplitudeRef.current, ...trailingAmplitudesRef.current].forEach((amplitude, index) => {
          if (amplitude === 0) {
            // Silence detected, draw horizontal line in the middle
            const y = height / 2;
            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = colors[index];
            canvasCtx.beginPath();
            canvasCtx.moveTo(0, y);
            canvasCtx.lineTo(width, y);
            canvasCtx.stroke();
          } else {
            // Sound detected, draw sinusoidal wave with envelope
            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = colors[index];
            canvasCtx.beginPath();

            const frequency = frequencies[index]; // Number of sine waves across the canvas
            const numPoints = 1000; // Number of points in the sine wave
            const sliceWidth = width / numPoints;
            let x = 0;

            for (let i = 0; i <= numPoints; i++) {
              // Center the waves in the middle of the canvas
              const xPos = x - width / 2;

              // Calculate envelope (amplitude tapering)
              const envelope = Math.cos((xPos / (width / 2)) * (Math.PI / 2));

              // Apply envelope to amplitude
              const y =
                height / 2 +
                amplitude *
                  envelope *
                  Math.sin(((xPos / (width / 2)) * frequency * Math.PI) + phase);

              if (i === 0) {
                canvasCtx.moveTo(x, y);
              } else {
                canvasCtx.lineTo(x, y);
              }

              x += sliceWidth;
            }

            canvasCtx.stroke();
          }
        });
      }

      renderFrame(performance.now());
    }

    setupAudio();

    return () => {
      // Clean up audio context and animation frame
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="visualizer-canvas"
      width={window.innerWidth}
      height={200}
    />
  );
}

export default AudioVisualizer;
