import React, { useState, useRef, useEffect } from "react";
import "../../App.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import * as speechsdk from "microsoft-cognitiveservices-speech-sdk";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPause,
  faRedo,
  faMicrophone,
  faStop,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL, ENDPOINTS } from "../../apiConfig";
import HistoryDetailsMain from "../History/HistoryDetailsMain";

const Translation = () => {
  const token = localStorage.getItem("token");
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      history.push("/");
    }
  }, [history, token]);

  const [showOnlyHistory, setShowOnlyHistory] = useState(false);
  const [isRecordingSaved, setIsRecordingSaved] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [recognizer, setRecognizer] = useState(null);
  const [realTimeTranscript, setRealTimeTranscript] = useState("");
  const [finalTranscript, setFinalTranscript] = useState("");
  const [ShowHistory, setShowHistory] = useState(false);
  const [speechthreadData, setSpeechthreadData] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isStartButtonDisabled, setIsStartButtonDisabled] = useState(false);
  const [isLiveRecordOpen, setIsLiveRecordOpen] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [time, setTime] = useState(0);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const intervalRef = useRef(null);
  const audioRef = useRef(null);
  const [selectedPat, setSelectedPat] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [patientThread, setPatientThread] = useState(null);
  const [patientSessions, setPatientSessions] = useState([]);
  const [patientPlan, setPatientPlan] = useState();
  const [expandedSessionId, setExpandedSessionId] = useState(null);
  const [visibleSessionsCount, setVisibleSessionsCount] = useState(5);

  const transcriptContainerRef = useRef();

  useEffect(() => {
    const tempPatient = JSON.parse(localStorage.getItem("Patient"));

    if (!tempPatient) {
      history.push("/search-patient");
    }
    if (!selectedPat) {
      setSelectedPat(tempPatient);
    }

    getPatientHistory(tempPatient.id);
  }, [selectedPat, history]);

  // Auto-scroll transcription container
  useEffect(() => {
    if (transcriptContainerRef.current) {
      transcriptContainerRef.current.scrollTop =
        transcriptContainerRef.current.scrollHeight;
    }
  }, [realTimeTranscript]);

  const handleStart = async () => {
    const storedPatient = localStorage.getItem("Patient");

    if (storedPatient) {
      try {
        const Dytoken = localStorage.getItem("token");
        const tokenResponse = await axios.get(
          ENDPOINTS.GET_AZURE_SPEECH_TOKEN,
          {
            headers: {
              Authorization: `Bearer ${Dytoken}`,
            },
          }
        );

        const { token, region } = tokenResponse.data;
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((audioStream) => {
            setIsRecording(true);
            setIsPaused(false);
            setTime(0);

            intervalRef.current = setInterval(() => {
              setTime((prevTime) => prevTime + 1);
            }, 1000);

            const newMediaRecorder = new MediaRecorder(audioStream);
            setMediaRecorder(newMediaRecorder);

            newMediaRecorder.ondataavailable = (event) => {
              if (event.data.size > 0) {
                setAudioChunks((prevChunks) => [...prevChunks, event.data]);
                saveRecordingLocally([...audioChunks, event.data]);
              }
            };

            newMediaRecorder.start();

            const speechTranslationConfig =
              speechsdk.SpeechTranslationConfig.fromAuthorizationToken(
                token,
                region
              );

            speechTranslationConfig.speechRecognitionLanguage = "en-US";
            speechTranslationConfig.addTargetLanguage("en");

            const audioConfig =
              speechsdk.AudioConfig.fromDefaultMicrophoneInput();
            const autoDetectSourceLanguageConfig =
              speechsdk.AutoDetectSourceLanguageConfig.fromLanguages([
                "en-US",
                "hi-IN",
                "gu-IN",
              ]);

            const translator = speechsdk.TranslationRecognizer.FromConfig(
              speechTranslationConfig,
              autoDetectSourceLanguageConfig,
              audioConfig
            );

            setRecognizer(translator);

            translator.recognizing = (s, e) => {
              const newText = e.result.translations.get("en").trim();
              console.log("english realtime:", newText);
              setRealTimeTranscript((prevText) => prevText + " " + newText);
            };

            translator.recognized = (s, e) => {
              const newText = e.result.translations.get("en").trim();
              console.log("english recognized:", newText);
              if (newText) {
                setFinalTranscript((prevText) => prevText + " " + newText);
              }
            };

            translator.canceled = (s, e) => {
              console.error(`Translation canceled: ${e.reason}`);
              if (e.reason === speechsdk.CancellationReason.Error) {
                console.error(`Error details: ${e.errorDetails}`);
              }
              translator.stopContinuousRecognitionAsync();
            };

            translator.sessionStopped = (s, e) => {
              translator.stopContinuousRecognitionAsync();
            };

            translator.startContinuousRecognitionAsync(
              () => console.log("Speech translation started"),
              (err) => console.error("Error starting speech translation:", err)
            );
          })
          .catch((error) => {
            console.error("Error accessing microphone:", error);
          });
      } catch (error) {
        console.error("Error during recording setup:", error);
        setIsStartButtonDisabled(false);
      }
    } else {
      console.log("Patient selection was cancelled or invalid.");
      setIsStartButtonDisabled(false);
    }
  };

  const handlePause = () => {
    if (isRecording && !isPaused) {
      setIsPaused(true);
      clearInterval(intervalRef.current);
      mediaRecorder.pause();

      if (recognizer) {
        recognizer.stopContinuousRecognitionAsync(
          () => {
            console.log("Speech recognition paused");
          },
          (err) => {
            console.error("Error pausing speech recognition:", err);
          }
        );
      }
    } else if (isRecording && isPaused) {
      setIsPaused(false);
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
      mediaRecorder.resume();

      if (recognizer) {
        recognizer.startContinuousRecognitionAsync(
          () => {
            console.log("Speech recognition resumed");
          },
          (err) => {
            console.error("Error resuming speech recognition:", err);
          }
        );
      }
    }
  };

  const handleSave = async () => {
    if (!isRecording) {
      return;
    }
    setIsRecordingSaved(true);
    setIsRecording(false);
    setIsPaused(false);
    clearInterval(intervalRef.current);
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setMediaRecorder(null);
    }
    setAudioChunks([]);
    if (audioRef.current) {
      audioRef.current.src = "";
    }

    setRealTimeTranscript("");

    if (recognizer) {
      recognizer.stopContinuousRecognitionAsync(
        () => {},
        (err) => {
          console.error("Error stopping speech recognition:", err);
        }
      );
    }

    setIsSubmitDisabled(false);
  };

  const handleReset = () => {
    if (isRecording || isPaused) {
      setIsRecording(false);
      setIsPaused(false);
      clearInterval(intervalRef.current);
      if (mediaRecorder) {
        mediaRecorder.stop();
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
        setMediaRecorder(null);
      }
      setAudioChunks([]);
      if (audioRef.current) {
        audioRef.current.src = "";
      }
    }

    setTime(0);
    setIsSubmitDisabled(false);
    setAudioChunks([]);
    window.location.reload();
  };

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const Submit = async () => {
    try {
      await handleSave();
      await handleUploadClick(selectedPat);

      localStorage.removeItem("Patient");

      // Reset states as needed
      setIsRecording(false);
      setIsPaused(false);
      setTime(0);
      setRealTimeTranscript("");
      setAudioChunks([]);
      setIsSubmitDisabled(false);
      setIsSubmitted(false);
      setIsRecordingSaved(false);

      if (audioRef.current) {
        audioRef.current.src = "";
      }

      setIsLiveRecordOpen(true);
      setShowOnlyHistory(true);
    } catch (error) {
      console.error("Error during submission:", error);
      toast.error(
        "There was an error processing your request. Please try again.",
        {
          position: "top-center",
          autoClose: 3000,
        }
      );
    }
  };

  const handleUploadClick = async (patient) => {
    if (!token) {
      console.error("No token found. Please login first.");
      alert("Please login to perform this action.");
      return;
    }

    const transcriptString = finalTranscript.trim();

    if (!transcriptString || transcriptString.length === 0) {
      Swal.fire({
        title:
          "<span class='text-xl font-semibold text-[#f2911b] '>Error</span>",
        html: "Transcription text is empty. Please provide a transcription. Restart it?",
        icon: false,
        buttonsStyling: false,
        customClass: {
          title: "text-lg font-semibold mb-2",
          content: "text-sm",
          confirmButton:
            "bg-[#f2911b] hover:bg-gray-400 text-white font-bold py-2 px-4 rounded",
          popup: "max-w-xs",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });

      return;
    }

    let formData = new FormData();
    formData.append("patient_id", `${selectedPat?.id}`);
    formData.append("transcription_text", transcriptString);

    try {
      const response = await axios.post(ENDPOINTS.TRANSCRIBE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      });

      if (mediaRecorder) {
        mediaRecorder.stop();
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
        setMediaRecorder(null);
      }

      if (recognizer) {
        recognizer.stopContinuousRecognitionAsync();
      }

      setIsRecording(false);
      setIsPaused(false);
      clearInterval(intervalRef.current);
      setAudioChunks([]);

      Swal.close();

      console.log("Response\n", response.data);
      const speechThreadID = response.data.SpeechThread.id;
      const sessions = response.data.SpeechThread.sessions;
      const sessionID = sessions[0]?.id;

      console.log("speechThreadID\n", speechThreadID);
      console.log("sessionID\n", sessionID);

      const url = localStorage.getItem("recording");

      if (!url) {
        console.error("No audio recording found.");
        return;
      }

      const blobResponse = await fetch(url);
      const audioBlob = await blobResponse.blob();

      console.log("audioBlob\n", audioBlob);

      let formData2 = new FormData();
      formData2.append("files", audioBlob);

      const apiResponse = await axios.post(
        `${ENDPOINTS.POST_RECORDING_FILE}?session_id=${sessionID}&speechthread_id=${speechThreadID}`,
        formData2,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("Second API Response\n", apiResponse.data);

      localStorage.setItem("transcriptionSuccess", "true");
      setShowHistory(true);
      setSpeechthreadData(response.data.SpeechThread);
      history.push(`/conversation/${selectedPat?.id}`);

      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    } catch (error) {
      console.error("Error in transcription:", error);
      Swal.fire({
        title: "Error",
        text:
          "Transcription failed: " +
          (error.response?.data?.error || error.message),
        icon: null,
        confirmButtonColor: "#f2911b",
        customClass: {
          popup: "max-w-xs",
        },
      });
    }

    setIsLiveRecordOpen(!isLiveRecordOpen);
    setIsSubmitted(true);
  };

  const saveRecordingLocally = (chunks) => {
    const audioBlob = new Blob(chunks, { type: "audio/wav" });
    const url = URL.createObjectURL(audioBlob);
    localStorage.setItem("recording", url);
  };

  // PATIENT HISTORY DISPLAY

  const getPatientHistory = async (id) => {
    setIsLoading(true);

    try {
      const threadResponse = await axios.get(ENDPOINTS.GET_PATIENT_THREAD, {
        headers: { Authorization: `Bearer ${token}` },
        params: { patient_id: id },
      });

      setPatientThread(threadResponse.data);
      setPatientPlan(threadResponse.data.patient_plan || "N/A");

      const sessionsResponse = await axios.get(ENDPOINTS.GET_PATIENT_SESSIONS, {
        params: {
          patient_id: id,
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      setPatientSessions(sessionsResponse.data.results);
      setExpandedSessionId(sessionsResponse.data.results[0]?.id || null);
    } catch (error) {
      console.error("Error fetching history details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleSessionExpansion = (sessionId) => {
    setExpandedSessionId(expandedSessionId === sessionId ? null : sessionId);
  };

  const handleLoadMoreSessions = () => {
    setVisibleSessionsCount((prevCount) => prevCount + 5);
  };

  return (
    <div className="container mx-auto pe-0 ps-8">
      {selectedPat && (selectedPat.firstname || selectedPat.lastname) ? (
        <div
          className="patient-info bg-[#F2911B] text-white py-2 px-4 flex justify-center items-center"
          style={{
            backgroundColor: "#f2911b",
          }}
        >
          <div className="flex flex-col lg:flex-row items-center text-sm sm:text-base lg:gap-8 gap-1">
            <span>
              <strong>Patient:</strong>{" "}
              {selectedPat.firstname + " " + selectedPat.lastname}
            </span>
            <span>
              <strong>DOB:</strong> {selectedPat.date_of_birth}
            </span>
          </div>
        </div>
      ) : null}

      <div className="flex flex-col items-center mt-2 p-4 bg-[#E8ECF4] rounded-2xl">
        <div className="space-y-6 mx-auto w-full px-0">
          <div className="flex items-start">
            <div className="relative bg-white shadow-md rounded-3xl p-6 overflow-hidden w-full">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center justify-center w-full">
                  <p className="font-bold text-lg text-center">
                    {!isRecording
                      ? "Press Start for Listening"
                      : "Press Submit to Generate Clinical Notes"}
                  </p>
                </div>
              </div>
              {isLiveRecordOpen && (
                <div className="flex flex-col">
                  <div className="p-4 md:p-8 flex flex-col items-center justify-center w-full rounded-lg">
                    <div className="text-center w-full">
                      <div className="flex items-center justify-center mb-2">
                        <span className="text-xl font-bold">
                          {formatTime(time)}
                        </span>
                      </div>

                      <div className="flex flex-col items-center justify-center space-y-4 ">
                        <div className="flex items-center justify-center -space-x-3 lg:space-x-8 xl:space-x-8 2xl:space-x-8">
                          {!isSubmitted ? (
                            !isRecording && audioChunks.length === 0 ? (
                              <button
                                onClick={handleStart}
                                className="w-36 h-12 flex items-center justify-center bg-[#F2911B] rounded-full text-white text-2xl shadow-lg hover:bg-white hover:border-2 hover:border-[#F2911B] hover:text-[#F2911B] relative"
                                title="Click to start"
                                disabled={
                                  isRecordingSaved || isStartButtonDisabled
                                }
                              >
                                <FontAwesomeIcon icon={faMicrophone} />
                                <span className="ml-2">Start</span>
                              </button>
                            ) : (
                              <>
                                <button
                                  onClick={handlePause}
                                  disabled={isRecordingSaved}
                                  title={isPaused ? "Resume" : "Pause"}
                                  className={`flex items-center justify-center w-40 h-12 text-gray-700 text-xl transition-opacity rounded-full hover:bg-gray-200 border border-gray-500  ${
                                    isPaused
                                      ? "opacity-50 animate-pulse-2 bg-gray-200"
                                      : "opacity-100 bg-transparent"
                                  } ${
                                    isRecordingSaved
                                      ? "opacity-50 cursor-not-allowed"
                                      : ""
                                  }`}
                                >
                                  <FontAwesomeIcon icon={faPause} />
                                  <span className="ml-2">
                                    {isPaused ? "Resume" : "Pause"}
                                  </span>
                                </button>
                                <div className="relative flex items-center justify-center">
                                  <button
                                    onClick={Submit}
                                    className={`w-40 h-12  flex items-center justify-center bg-[#F2911B] rounded-full text-white text-xl shadow-lg z-10 mx-4 hover:animate-pulse hover:delay-200 ${
                                      isRecordingSaved
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                    }`}
                                    disabled={isRecordingSaved}
                                  >
                                    <FontAwesomeIcon icon={faStop} />
                                    <span className="ml-2">Submit</span>
                                  </button>
                                </div>
                              </>
                            )
                          ) : (
                            <button
                              onClick={handleReset}
                              className="w-40 h-12 flex items-center justify-center bg-[#F2911B] rounded-lg text-white text-xl shadow-lg z-10 hover:bg-white hover:border-2 hover:border-[#F2911B] hover:text-[#F2911B]"
                            >
                              <FontAwesomeIcon icon={faRedo} />
                              <span className="ml-2">Reset It</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    <audio
                      ref={audioRef}
                      controls
                      className="mt-4 w-full hidden"
                    ></audio>
                  </div>
                  {isRecording ? (
                    <div
                      ref={transcriptContainerRef}
                      className="transcript-container"
                      style={{
                        maxHeight: "300px",
                        overflowY: "auto",
                      }}
                    >
                      <textarea
                        value={finalTranscript}
                        readOnly
                        className="w-full h-64 p-2 border rounded resize-none"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isLoading && !patientThread && !patientSessions ? (
        <div className="w-7 h-7 border-4 border-t-4 border-t-transparent border-[#f2911b] rounded-full animate-spin"></div>
      ) : (
        <div className="flex flex-col items-center my-4 p-4 bg-[#E8ECF4] rounded-2xl">
          <div className="space-y-6 mx-auto w-full">
            <div className="relative bg-white shadow-md rounded-3xl p-6 overflow-hidden w-full">
              <h2 className="text-xl font-bold mb-4">Patient Plan</h2>
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-4">
                  <p className="w-full text-justify">{patientPlan}</p>
                </div>
              </div>
            </div>

            <div className="relative bg-white shadow-md rounded-3xl p-6 overflow-hidden w-full">
              <div className="w-full">
                <div>
                  {patientSessions.length !== 0 ? (
                    patientSessions
                      ?.slice(0, visibleSessionsCount)
                      .map((session) => (
                        <div
                          key={session.id}
                          className="mb-4 border rounded-md p-4"
                        >
                          <div
                            className="flex justify-between items-center cursor-pointer"
                            onClick={() => toggleSessionExpansion(session.id)}
                          >
                            <h3 className="text-lg font-semibold">
                              {moment(session.created_at).format(
                                "MMM DD, YYYY hh:mm A"
                              ) +
                                " - " +
                                session.title}
                            </h3>
                            <FontAwesomeIcon
                              icon={
                                expandedSessionId === session.id
                                  ? faChevronUp
                                  : faChevronDown
                              }
                            />
                          </div>
                          {expandedSessionId === session.id && (
                            <div
                              className="mt-4 w-full bg-gray-100 p-4 rounded"
                              style={{ minWidth: "full" }} // You can adjust the height as needed
                            >
                              {/* Session Content */}
                              <div
                                className="prose max-w-none leading-tight mt-4 space-y-0"
                                style={{ color: "black" }}
                                dangerouslySetInnerHTML={{
                                  __html: session.notes,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ))
                  ) : (
                    <p className="text-gray-400 my-4">No Sessions</p>
                  )}
                  {visibleSessionsCount < patientSessions.length && (
                    <button
                      onClick={handleLoadMoreSessions}
                      className="bg-[#f2911b] text-white px-4 py-2 rounded mt-4"
                    >
                      Load More
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Translation;
