import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import { ENDPOINTS } from "../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../Assets/images/astra-scribe-logo.png";
import Google from "../../Assets/images/google.png";
import Athena from "../../Assets/images/athena.jpg"; // Add Athena logo

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [nextUrl, setNextUrl] = useState("");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [loader, setLoader] = useState(false);
  const [googleLoader, setGoogleLoader] = useState(false);
  const [athenaLoader, setAthenaLoader] = useState(false); // Added state for Athena loader
  const [googleLink, setGoogleLink] = useState("");
  const [athenaLink, setAthenaLink] = useState(""); // Added state for Athena link
  const history = useHistory();
  const location = useLocation();

  // Function to generate a unique state value
  const generateState = () => {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    const trimmedUsername = formData.username.trim();

    if (trimmedUsername === formData.password) {
      toast.error("Fill out valid username and password");
      setLoader(false);
      return;
    }

    try {
      const response = await axios.post(
        ENDPOINTS.LOGIN,
        {
          username: trimmedUsername,
          password: formData.password,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
            "X-CSRFToken":
              "Wdz2FF7IfhClKygAS1kqxaIVJJaxnHTwMM4SagZWTRlRqxbBucWvPvEiY8qY4IBQ",
          },
        }
      );
      toast.success("Login successful! Redirecting to dashboard...");
      localStorage.setItem("GoogleProfileURL", ""); // Clear Google profile URL
      localStorage.setItem("Username", trimmedUsername);
      localStorage.setItem("token", response.data.token);
      localStorage.removeItem("GoogleProfileURL");
      setTimeout(() => {
        setLoader(false);
        if (nextUrl) {
          window.location.href = nextUrl;
        } else {
          history.push("/search-patient");
        }
      }, 1000);
    } catch (error) {
      toast.error(error.response?.data?.error || "An error occurred");
      setLoader(false);
    }
  };

  const getGoogleLink = () => {
    axios
      .get(ENDPOINTS.GOOGLE_REDIRECT)
      .then((res) => {
        setGoogleLink(res.data.url);
      })
      .catch((err) => {
        console.error("Error fetching Google link:", err);
      });
  };
  const checkGoogleLogin = async () => {
    const searchParams = new URLSearchParams(location.search);

    const code = searchParams.get("code");
    const state = searchParams.get("state");
    const error = searchParams.get("error");
    setNextUrl(searchParams.get("next"));
    console.log("wat is coming in next : ", nextUrl);

    if (code && state) {
      setGoogleLoader(true); // Show spinner for Google login
      try {
        const response = await axios.get(
          `${ENDPOINTS.GOOGLE_CALLBACK}/?code=${encodeURIComponent(
            code
          )}&state=${encodeURIComponent(state)}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        );
        const { token, user_info } = response.data;
        // console.log('Google Callback Response:', response.data);
        // console.log('User Info:', user_info);
        localStorage.setItem("Username", user_info.username);
        localStorage.setItem("Email", user_info.email);
        localStorage.setItem("GoogleProfileURL", user_info.google_picture_url);
        localStorage.setItem("token", token);
        toast.success("Login successful! Redirecting to dashboard...");
        setGoogleLoader(false);
        history.push("/search-patient");
      } catch (err) {
        console.error(
          "Error during Google callback:",
          err.response ? err.response.data : err.message
        );
        setGoogleLoader(false);
      }
    } else if (error) {
      console.error("Error fetching Google link:", error);
    }
  };

  const getAthenaLink = () => {
    const athenaClientId = "0oasj7ml8gJm8GHd2297";
    const redirectUri = "http://localhost:3000/iframe";
    const state = generateState(); // Generate a unique state for CSRF protection
    localStorage.setItem("oauth_state", state); // Store the state in local storage
    const athenaUrl = `https://api.preview.platform.athenahealth.com/oauth/v1/authorize?client_id=${athenaClientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_type=code&state=${state}`;

    setAthenaLink(athenaUrl); // Store the generated URL for later use
  };

  const checkAthenaLogin = async () => {
    const athenaClientId = "0oasj7ml8gJm8GHd2297";
    const athenaClientSecret =
      "LoB3WHQWX5MYcB1dWldOz2bfYADgEns4TT8z5g-KlYTPKbdDV7P58YgniT1W-_m-";
    const redirectUri = "http://localhost:3000/iframe";

    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code"); // Get the authorization code from the URL
    const returnedState = searchParams.get("state");
    const storedState = localStorage.getItem("oauth_state");

    if (returnedState !== storedState) {
      console.error("State mismatch, potential CSRF attack.");
      toast.error("Login failed due to state mismatch.");
      return;
    }

    try {
      // Make the POST request to exchange the code for an access token
      const response = await axios.post(
        "https://api.preview.platform.athenahealth.com/oauth2/v1/token",
        null,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          auth: {
            username: athenaClientId,
            password: athenaClientSecret,
          },
          params: {
            grant_type: "authorization_code",
            code: code,
            redirect_uri: redirectUri,
          },
        }
      );

      const { access_token } = response.data; // Extract the access token
      localStorage.setItem("token", access_token); // Store the access token in local storage
      toast.success("Login successful! Redirecting to dashboard...");
      history.push("/search-patient");
    } catch (err) {
      console.error(
        "Error during Athena callback:",
        err.response ? err.response.data : err.message
      );
      toast.error("Login failed during Athena OAuth.");
    }
  };

  useEffect(() => {
    getGoogleLink();
    // getAthenaLink(); // Fetch the Athena authorization link on component mount
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    setNextUrl(searchParams.get("next"));
    if (code && state) {
      // checkAthenaLogin();
      checkGoogleLogin();
    }
  }, [location.search]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-white">
      <ToastContainer />
      <div className="w-full max-w-xl p-8 space-y-1 bg-white">
        <div className="flex justify-center mb-4">
          <img src={Logo} alt="Farz AI Logo" className="h-16" />
        </div>
        <h2 className="text-2xl font-bold text-center">
          Login to Your Account
        </h2>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="username" className="sr-only">
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Username or Email"
              value={formData.username}
              onChange={handleChange}
              className="w-full px-4 py-4 text-gray-700 border border-gray-200 rounded-md bg-[#F7F8F9] focus:outline-none focus:ring-2 focus:ring-[#f2911b54] focus:border-transparent"
            />
          </div>
          <div className="relative">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className="w-full px-4 py-4 text-gray-700 border border-gray-200 rounded-md bg-[#F7F8F9] focus:outline-none focus:ring-2 focus:ring-[#f2911b54] focus:border-transparent"
            />
            <div className="absolute inset-y-0 right-0 flex items-center px-4">
              <i
                className={`fas ${
                  showPassword ? "fa-eye-slash" : "fa-eye"
                } cursor-pointer text-gray-600`}
                onClick={togglePasswordVisibility}
              ></i>
            </div>
          </div>
          <button
            type="submit"
            className="w-full py-4 font-bold text-white bg-[#F2911B] rounded focus:ring-4 focus:ring-[#F2911B] flex justify-center items-center"
          >
            {loader ? (
              <div className="w-7 h-7 border-4 border-t-4 border-t-transparent border-white rounded-full animate-spin"></div>
            ) : (
              "Login"
            )}
          </button>
          <div className="flex items-center justify-center space-x-4">
            <div className="border-t w-24"></div>
            <span className="text-[#F2911B] text-xs md:text-base lg:text-base">
              Or Login with
            </span>
            <div className="border-t w-24"></div>
          </div>
          <div className="flex items-center justify-center space-x-4 bg-[#f4f4f4]">
            <a
              href={googleLink}
              className="flex items-center justify-center w-full px-4 py-3 border rounded hover:bg-gray-200"
              onClick={() => setGoogleLoader(true)}
            >
              {googleLoader ? (
                <div className="w-7 h-7 border-4 border-t-4 border-t-transparent border-gray-600 rounded-full animate-spin"></div>
              ) : (
                <>
                  <img src={Google} alt="Google" className="h-5 mr-2" />
                  Google
                </>
              )}
            </a>
            {/* <a
  href={athenaLink}
  className={`relative flex items-center justify-center w-full px-4 py-3 border rounded ${
    athenaLoader ? 'bg-gray-100 cursor-not-allowed opacity-50' : 'hover:bg-gray-200'
  }`}
  onClick={(e) => {
    e.preventDefault(); // Prevent default action when disabled
    setAthenaLoader(true);
  }}
  disabled={athenaLoader} // Disable the button when loader is active
>
  {athenaLoader && (
    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 text-white font-bold rounded">
      Coming Soon
    </div>
  )}
  {athenaLoader ? (
    <div className="w-7 h-7 border-4 border-t-4 border-t-transparent border-gray-600 rounded-full animate-spin"></div>
  ) : (
    <>
      <img src={Athena} alt="Athena" className="h-5 mr-2" />
      Athena
    </>
  )}
</a> */}
          </div>
        </form>
        <p className="text-center">
          <NavLink
            to="/forgot-password"
            className="text-[#F2911B] hover:underline"
          >
            Forgot Password?
          </NavLink>
        </p>
        <p className="text-center">
          Don't have an account?{" "}
          <NavLink to="/register" className="text-[#F2911B] hover:underline">
            Register Now
          </NavLink>
        </p>
      </div>
    </div>
  );
};

export default Login;
