import React, { useState, useRef, useEffect } from "react";
import "../../App.css";
import { useHistory } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as speechsdk from "microsoft-cognitiveservices-speech-sdk";
import {
  faPause,
  faRedo,
  faMicrophone,
  faStop,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { API_BASE_URL, ENDPOINTS } from "../../apiConfig";

const RecordScreen = () => {
  const token = localStorage.getItem("token");
  const history = useHistory();

  const [showOnlyHistory, setShowOnlyHistory] = useState(false);
  const [notes, setNotes] = useState("");

  const [patientThread, setPatientThread] = useState(null);
  const [patientSessions, setPatientSessions] = useState([]);

  const [isPostGenerated, setIsPostGenerated] = useState(false);
  const [postTitle, setPostTitle] = useState("");
  const [postPatientName, setPostPatientName] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(""); // D.O.B
  const [isRecordingSaved, setIsRecordingSaved] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [recognizer, setRecognizer] = useState(null);
  const [realTimeTranscript, setRealTimeTranscript] = useState("");
  const [finalTranscript, setFinalTranscript] = useState([]);
  const [ShowHistory, setShowHistory] = useState(false);
  const [speechthreadData, setSpeechthreadData] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isStartButtonDisabled, setIsStartButtonDisabled] = useState(false);

  const currentUrl = window.location.href;
  const [files, setFiles] = useState([]);
  const [isLiveRecordOpen, setIsLiveRecordOpen] = useState(true);
  const [isGenerateOpen, setIsGenerateOpen] = useState(false);
  const [isImgOpen, setIsImgOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [time, setTime] = useState(0);
  const [transcript, setTranscript] = useState([]);

  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [speechThreadId, setSpeechThreadId] = useState(null);
  const [generatedContent, setGeneratedContent] = useState("");

  const [lastSpeaker, setLastSpeaker] = useState(null);
  const [currentText, setCurrentText] = useState("");
  const [savedGeneratedPosts, setSavedGeneratedPosts] = useState({});
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);
  const [profilePictureName, setProfilePictureName] = useState("");
  const uploadedFile = useRef(null);
  const [dummyState, setDummyState] = useState(false);
  const [isEditingGenerated, setIsEditingGenerated] = useState(false);
  const [generatedPost, setGeneratedPost] = useState(null);
  const intervalRef = useRef(null);
  const inputRef = useRef(null);
  const profilePictureInputRef = useRef(null);
  const audioRef = useRef(null);
  const generatedPostRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState("Notes");
  const [selectedPatient, setSelectedPatient] = useState([]);
  const transcriptContainerRef = useRef();

  const [selectedPat, setSelectedPat] = useState();
  const [loading, setLoading] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [patientPlan, setPatientPlan] = useState();
  const [expandedSessionId, setExpandedSessionId] = useState(null);
  const [visibleSessionsCount, setVisibleSessionsCount] = useState(5);

  useEffect(() => {
    const tempPatient = JSON.parse(localStorage.getItem("Patient"));

    if (!tempPatient) {
      history.push("/search-patient");
    }
    if (!selectedPat) {
      setSelectedPat(tempPatient);
    }

    getPatientHistory(tempPatient.id);
  }, [selectedPat, history]);

  useEffect(() => {
    if (transcriptContainerRef.current) {
      transcriptContainerRef.current.scrollTop = -10000;
    }
  }, [realTimeTranscript]);

  const switchSlide = (slide) => {
    setCurrentSlide(slide);

    // Check if the post for the current slide has already been generated
    if (slide === "Notes" && savedGeneratedPosts["Notes"]) {
      setGeneratedPost({
        title: "Notes",
        content: savedGeneratedPosts["Notes"],
        isHtmlContent: true,
      });
    } else if (slide === "Summary" && savedGeneratedPosts["Summary"]) {
      setGeneratedPost({
        title: "Summary",
        content: savedGeneratedPosts["Summary"],
        isHtmlContent: true,
      });
    } else {
      setGeneratedPost(null);
    }
  };

  const scrollToGeneratedPost = () => {
    setTimeout(() => {
      if (generatedPostRef.current) {
        generatedPostRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }, 100);
  };
  useEffect(() => {
    console.log("Final Transcript:", finalTranscript);
  }, [finalTranscript]);

  useEffect(() => {
    if (!token) {
      console.error("No token found. Please login first.");
    }
  }, [token]);

  // useEffect(() => {
  //   setProfilePictureUrl(null);
  //   if (!token) {
  //     console.error("No token found. Please login first.");
  //   } else {
  //     axios
  //       .get(
  //         "https://astrascribe-india-be.azurewebsites.net/profile_picture/",
  //         {
  //           headers: {
  //             Authorization: "Bearer " + token,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         // setProfilePictureUrl(response.data.image);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching profile picture:", error);
  //       });
  //   }
  // }, [token]);

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    uploadedFile.current = event.target.files[0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      if (validImageTypes.includes(file.type)) {
        setProfilePictureName(file.name);
        const formData = new FormData();
        formData.append("image", file);
        formData.append("user", 1);

        axios
          .post(
            "https://astrascribe-india-be.azurewebsites.net/profile_picture/",
            formData,
            {
              headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            setProfilePictureUrl(response.data.image);
          })
          .catch((error) => {
            console.error("Error uploading profile picture:", error);
          });
      } else {
        console.error("Invalid file type. Please select an image file.");
      }
    }
  };

  const handleFileChange = (event) => {
    setFiles([...files, ...event.target.files]);
  };

  // Fetch Athena Token
  const getAthenaToken = async () => {
    try {
      const response = await axios.get(
        "https://astrascribe-india-be.azurewebsites.net/athena_token/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-CSRFToken":
              "zlSnHrrhqim1cTn3jS8f3DFAB7XithUXbJnPWyEokqsJVXPpesY9R4ntXI1aiBQG",
          },
        }
      );
      const athenaToken = response.data.access_token;
      localStorage.setItem("athenaToken", athenaToken);
      return athenaToken;
    } catch (error) {
      console.error("Error fetching Athena token:", error);
      Swal.fire("Error", "Unable to fetch Athena token.", "error");
      return null;
    }
  };

  const handleStart = async () => {
    // Check if "Patient" exists in localStorage
    const storedPatient = localStorage.getItem("Patient");

    if (storedPatient) {
      console.log("Stored Patient Data:", storedPatient); // Log the raw data

      // Parse the stored patient data
      let patientData;
      try {
        patientData = JSON.parse(storedPatient);
        console.log("Parsed Patient Data:", patientData); // Log the parsed data
      } catch (error) {
        console.error("Error parsing patient data from localStorage:", error);
        return;
      }
      // Proceed with recording setup
      // Start Recording Code Here (Include recording functionality inside handleStart)
      console.log("Fetching Azure Speech token...");
      const Dytoken = localStorage.getItem("token");
      const tokenResponse = await axios.get(ENDPOINTS.GET_AZURE_SPEECH_TOKEN, {
        headers: {
          Authorization: `Bearer ${Dytoken}`,
        },
      });

      const { token, region } = tokenResponse.data;
      console.log(`Fetched token and region: ${token}, ${region}`);
      try {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((audioStream) => {
            console.log("Microphone access granted:", audioStream);

            setIsRecording(true);
            setIsPaused(false);
            setTime(0);

            intervalRef.current = setInterval(() => {
              setTime((prevTime) => prevTime + 1);
            }, 1000);

            const newMediaRecorder = new MediaRecorder(audioStream);
            setMediaRecorder(newMediaRecorder);

            newMediaRecorder.ondataavailable = (event) => {
              if (event.data.size > 0) {
                setAudioChunks((prevChunks) => [...prevChunks, event.data]);
                saveRecordingLocally([...audioChunks, event.data]);
              }
            };

            newMediaRecorder.start();
            console.log("Started media recording.");

            // Set up Azure Speech Translation configuration
            const speechTranslationConfig =
              speechsdk.SpeechTranslationConfig.fromAuthorizationToken(
                token,
                region
              );

            speechTranslationConfig.speechRecognitionLanguage = "en-US";
            speechTranslationConfig.addTargetLanguage("en");

            const audioConfig =
              speechsdk.AudioConfig.fromDefaultMicrophoneInput();
            const autoDetectSourceLanguageConfig =
              speechsdk.AutoDetectSourceLanguageConfig.fromLanguages([
                "hi-IN",
                "en-US",
                "gu-IN",
              ]);

            const translator = speechsdk.TranslationRecognizer.FromConfig(
              speechTranslationConfig,
              autoDetectSourceLanguageConfig,
              audioConfig
            );

            setRecognizer(translator);
            const speakers = ["Speaker 1", "Speaker 2"];
            const backgroundColors = ["#f0f0f0", "#fffdd0"]; // Gray for Speaker 1, Cream for Speaker 2

            // Function to toggle between Speaker 1 and Speaker 2
            const toggleSpeaker = (currentSpeaker) => {
              return currentSpeaker === speakers[0] ? speakers[1] : speakers[0];
            };

            let accumulatedText = "";
            translator.recognizing = (s, e) => {
              const newText = e.result.text.trim();

              setLastSpeaker((prevSpeaker) => {
                // If this is the first segment, set it to Speaker 1
                let nextSpeaker =
                  prevSpeaker === null ? speakers[0] : prevSpeaker;

                if (prevSpeaker !== nextSpeaker) {
                  // If the speaker changes, finalize the previous speaker's dialogue if it's not empty
                  if (accumulatedText.trim()) {
                    setRealTimeTranscript((prev) => [
                      {
                        speaker: prevSpeaker,
                        text: accumulatedText,
                        bgColor:
                          backgroundColors[speakers.indexOf(prevSpeaker)],
                      },
                      ...prev, // Prepend the new entry instead of appending
                    ]);
                  }

                  // Reset the accumulated text for the new speaker
                  accumulatedText = newText;
                } else {
                  // If the speaker hasn't changed, accumulate the text
                  accumulatedText += " " + newText;
                }

                setCurrentText(newText);
                return nextSpeaker;
              });
            };

            translator.recognized = (s, e) => {
              const newText = e.result.text.trim();

              setLastSpeaker((prevSpeaker) => {
                // Determine the speaker (toggle between Speaker 1 and Speaker 2)
                let nextSpeaker =
                  prevSpeaker === null ? speakers[0] : prevSpeaker;

                if (newText) {
                  // Create a segment object with speaker info, text, and background color
                  const segment = {
                    speaker: nextSpeaker,
                    text: newText,
                    bgColor: backgroundColors[speakers.indexOf(nextSpeaker)],
                  };

                  // Update realTimeTranscript for live updates
                  setRealTimeTranscript((prev) => [...prev, segment]);

                  // Update finalTranscript for use after recording
                  setFinalTranscript((prev) => [...prev, segment]);
                }

                return toggleSpeaker(nextSpeaker); // Toggle the speaker for the next segment
              });
            };

            translator.canceled = (s, e) => {
              console.error(`Translation canceled: ${e.reason}`);
              if (e.reason === speechsdk.CancellationReason.Error) {
                console.error(`Error details: ${e.errorDetails}`);
              }
              translator.stopContinuousRecognitionAsync();
            };

            translator.sessionStopped = (s, e) => {
              console.log("Translation session stopped.");
              translator.stopContinuousRecognitionAsync();
            };

            translator.startContinuousRecognitionAsync(
              () => console.log("Speech translation started"),
              (err) => console.error("Error starting speech translation:", err)
            );
          })
          .catch((error) => {
            console.error("Error accessing microphone:", error);
          });
      } catch (error) {
        console.error("Error during recording setup:", error);
        setIsStartButtonDisabled(false);
      }
    } else {
      console.log("Patient selection was cancelled or invalid.");
      setIsStartButtonDisabled(false);
    }
  };

  function createEncounterTable(encounters, patientName, onEncounterSelect) {
    let tableHtml = `<h3 class="text-lg font-semibold mb-4">Encounters for ${patientName}</h3>
<div class="overflow-x-auto">
  <div class="min-w-full max-h-96 overflow-y-auto border border-gray-200 rounded-md">
    <table class="min-w-full table-auto">
      <thead class="bg-gray-100 sticky top-0">
        <tr>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Encounter ID</th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Encounter Date</th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Provider Last Name</th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Status</th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Select</th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        ${encounters
          .map(
            (encounter) => `
        <tr>
          <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">${encounter.encounterid}</td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${encounter.encounterdate}</td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${encounter.providerlastname}</td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${encounter.status}</td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <input type="radio" name="encounter" value="${encounter.encounterid}" id="encounter-${encounter.encounterid}">
          </td>
        </tr>`
          )
          .join("")}
      </tbody>
    </table>
  </div>
</div>
`;
    document.getElementById("encounterTable").innerHTML = tableHtml;

    // Attach event listeners for each radio button after rendering the table
    encounters.forEach((encounter) => {
      const radioButton = document.getElementById(
        `encounter-${encounter.encounterid}`
      );
      if (radioButton) {
        radioButton.addEventListener("click", () =>
          onEncounterSelect(
            encounter.encounterid,
            encounter.encounterdate,
            encounter.providerlastname,
            encounter.status,
            patientName
          )
        );
      }
    });
  }

  // Function to fetch and display encounters for the selected patient
  // Function to fetch and display encounters for the selected patient

  const fetchAndDisplayEncounters = async (accessToken, selectedPatient) => {
    const { id: patientId, name } = selectedPatient;
    const patientName = `${name[0].given[0]} ${name[0].family}`;

    try {
      const response = await fetch(
        "https://astrascribe-india-be.azurewebsites.net/encounter/retrieve-all-athenaone-encounters/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            access_token: accessToken,
            patient_id: patientId,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched Encounters Data:", data);

        createEncounterTable(
          data.encounters.encounters,
          patientName,
          selectEncounter
        );
        // In the handleStart or anywhere you use createEncounterTable
      } else {
        console.error("Failed to fetch encounters:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching encounters:", error);
    }
  };

  function selectEncounter(
    encounterId,
    encounterDate,
    providerLastName,
    status,
    patientName
  ) {
    console.log(`Selected Encounter ID: ${encounterId}`);
    console.log(`Encounter Date: ${encounterDate}`);
    console.log(`Provider Last Name: ${providerLastName}`);
    console.log(`Status: ${status}`);
    console.log(`Patient Name: ${patientName}`);
  }

  // Define the selectEncounter function

  // Call this function inside your handleStart function

  useEffect(() => {
    const handleStartButton = document.getElementById("handleStart");
    const handleStartClick = async () => {
      console.log("handleStart clicked");

      const accessToken = await getAthenaToken(); // Your logic to get Athena token
      console.log("Access Token:", accessToken);

      // Use the selectedPatient from your component's state
      if (selectedPatient) {
        console.log("Selected Patient in handleStart:", selectedPatient);

        // Fetch and display encounters for the selected patient
        await fetchAndDisplayEncounters(accessToken, selectedPatient);
      } else {
        console.error("No patient selected.");
      }
    };

    if (handleStartButton) {
      handleStartButton.addEventListener("click", handleStartClick);
    } else {
      console.error("Element with ID 'handleStart' not found.");
    }

    // Clean up event listener when component unmounts
    return () => {
      if (handleStartButton) {
        handleStartButton.removeEventListener("click", handleStartClick);
      }
    };
  }, [selectedPatient]); // Make sure to update this based on the dependencies like selectedPatient

  //Encounter Id Scenerio End

  const getCaretPosition = (el) => {
    let caretOffset = 0;
    const doc = el.ownerDocument || el.document;
    const win = doc.defaultView || doc.parentWindow;
    const sel = win.getSelection();
    if (sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(el);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
    return caretOffset;
  };

  const setCaretPosition = (el, offset) => {
    const doc = el.ownerDocument || el.document;
    const win = doc.defaultView || doc.parentWindow;
    if (offset >= 0) {
      const sel = win.getSelection();
      const range = doc.createRange();
      range.setStart(el, 0);
      range.setEnd(el, 0);
      let charIndex = 0,
        nodeStack = [el],
        node,
        foundStart = false;
      while ((node = nodeStack.pop())) {
        if (node.nodeType === 3) {
          const nextCharIndex = charIndex + node.length;
          if (!foundStart && offset >= charIndex && offset <= nextCharIndex) {
            range.setStart(node, offset - charIndex);
            range.setEnd(node, offset - charIndex);
            foundStart = true;
          }
          charIndex = nextCharIndex;
        } else {
          let i = node.childNodes.length;
          while (i--) {
            nodeStack.push(node.childNodes[i]);
          }
        }
      }
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const handlePause = () => {
    if (isRecording && !isPaused) {
      setIsPaused(true);
      clearInterval(intervalRef.current);
      mediaRecorder.pause();

      if (recognizer) {
        recognizer.stopContinuousRecognitionAsync(
          () => {
            console.log("Speech recognition paused");
          },
          (err) => {
            console.error("Error pausing speech recognition:", err);
          }
        );
      }
    } else if (isRecording && isPaused) {
      setIsPaused(false);
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
      mediaRecorder.resume();

      if (recognizer) {
        recognizer.startContinuousRecognitionAsync(
          () => {
            console.log("Speech recognition resumed");
          },
          (err) => {
            console.error("Error resuming speech recognition:", err);
          }
        );
      }
    }
  };

  const handleSave = async () => {
    if (!isRecording) {
      return; // Guard clause to prevent double execution
    }
    // if (audioChunks.length > 0) {
    //   const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
    //   const file = new File([audioBlob], "recording.wav", {
    //     type: "audio/wav",
    //   });
    //   setFiles([file]);
    //   setDummyState((prev) => !prev);
    // }

    // toast.success("Your recording is saved", {
    //   position: "top-right",
    //   autoClose: 500,
    // });
    setIsRecordingSaved(true);

    setIsRecording(false);
    setIsPaused(false);
    clearInterval(intervalRef.current);
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setMediaRecorder(null);
    }
    setAudioChunks([]);
    if (audioRef.current) {
      audioRef.current.src = "";
    }

    setTranscript((prev) => prev);
    // console.log(realTimeTranscript);
    setRealTimeTranscript("");

    if (recognizer) {
      recognizer.stopContinuousRecognitionAsync(
        () => {
          // console.log('Speech recognition stopped');
        },
        (err) => {
          console.error("Error stopping speech recognition:", err);
        }
      );
    }

    setIsSubmitDisabled(false);
  };

  const handleTranscriptChange = (event) => {
    const caretPosition = getCaretPosition(event.target);
    setTranscript(event.target.innerText);
    requestAnimationFrame(() => setCaretPosition(event.target, caretPosition));
  };

  const handleReset = () => {
    if (isRecording || isPaused) {
      setIsRecording(false);
      setIsPaused(false);
      clearInterval(intervalRef.current);
      if (mediaRecorder) {
        mediaRecorder.stop();
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
        setMediaRecorder(null);
      }
      setAudioChunks([]);
      if (audioRef.current) {
        audioRef.current.src = "";
      }
    }

    setTime(0);
    setFiles([]);
    setDummyState((prev) => !prev);
    setIsSubmitDisabled(false);
    setAudioChunks([]);

    // Reset the transcript
    setTranscript("");

    // Refresh the page
    window.location.reload();
  };

  const toggleLiveRecordSection = () => {
    setIsLiveRecordOpen(!isLiveRecordOpen);
  };

  const toggleGenerateSection = () => {
    setIsGenerateOpen(!isGenerateOpen);
  };

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };
  const handleGenerateContent = async (
    url,
    text,
    speechThreadId,
    index,
    title
  ) => {
    if (!token) {
      console.error("No token found. Please login first.");
      alert("Please login to perform this action.");
      return;
    }

    if (!speechThreadId) {
      Swal.fire(
        "Error",
        "Oops! Please upload the correct audio. Thank you!",
        "error"
      );
      return;
    }

    try {
      // Display a loading animation or message while the content is being generated
      const customLoader = `<div class="relative flex items-center justify-center overflow-hidden mt-4">
            <div class="w-16 h-16 border-4 border-solid border-transparent border-t-orange-500 rounded-full animate-spin"></div>
            <div class="absolute flex items-center justify-center">
              <div class="w-12 h-12 border-4 border-solid border-transparent border-t-black rounded-full animate-spin m-2"></div>
              <div class="absolute w-8 h-8 border-4 border-solid border-transparent border-t-orange-500 rounded-full animate-spin m-2"></div>
            </div>
          </div>`;

      const customHeader = `<div class="flex justify-between items-center w-full">
            <div class="text-lg">Generating ${title}</div>
            <button id="close-btn" class="text-gray-500 hover:text-gray-700 focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
          <hr class="border-gray-300 w-full my-2">
          <p class="text-gray-400 text-xs"> Please hold on for a moment, we are diligently processing your request and ensuring everything is accurate...</p>`;

      // Create form data to send the text and speechThreadId as form fields
      let formData = new FormData();
      formData.append("text", text);
      formData.append("SpeechThread_id", speechThreadId);

      // Make the POST request to the server with the correct headers
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data", // Ensure the correct content type
        },
      });
      // Handle the response and update the UI
      const generatedContent = response.data.notes || response.data.summary; // Adjust according to actual response structure
      setGeneratedContent(generatedContent);
      // console.log(generatedContent);
      // Update the saved generated posts
      setSavedGeneratedPosts((prev) => ({
        ...prev,
        [title]: generatedContent,
      }));

      // Set the generated post in the state
      setGeneratedPost({
        title,
        content: generatedContent,
        isHtmlContent: true,
      });
      setIsEditingGenerated(false);

      // Scroll to the generated post
      scrollToGeneratedPost();

      Swal.close();
    } catch (error) {
      console.error("Error in generating content:", error);

      if (error.response && error.response.status === 415) {
        Swal.fire(
          "Error",
          `The server could not process the request. Please check if the content type is correct.`,
          "error"
        );
      } else {
        Swal.fire(
          "Error",
          "Failed to generate content: " +
            (error.response?.data?.error || error.message),
          "error"
        );
      }
    }
  };

  // const Submit = async () => {
  //   // First, save the current recording
  //   await handleSave();

  //   // Then, upload the recording
  //   await handleUploadClick(selectedPatient);

  //   // After both operations are completed, reset the live listening section
  //   setIsRecording(false);
  //   setIsPaused(false);
  //   setTime(0);
  //   setTranscript([]);
  //     setRealTimeTranscript(""); // Clear the real-time transcript
  //   setAudioChunks([]);
  //   setIsSubmitDisabled(false);
  //   setIsSubmitted(false); // Reset submission state to allow new recording
  //   setIsRecordingSaved(false); // Reset recording saved state

  //   // Optionally, clear or reset other states
  //   if (audioRef.current) {
  //     audioRef.current.src = ""; // Clear audio preview
  //   }

  //   // Reset UI elements for a new recording

  //   // You can also reset the display if needed
  //   setIsLiveRecordOpen(true); // Re-open the live listening section if it was closed
  // };

  // Example usage within the handleUploadClick function
  const Submit = async () => {
    try {
      const transcriptString = finalTranscript
        .map((entry) => `${entry.speaker}: ${entry.text}`)
        .join(" ");

      // Check if the transcript is empty before proceeding
      if (!transcriptString || transcriptString.trim().length === 0) {
        if (mediaRecorder) {
          mediaRecorder.stop();
          mediaRecorder.stream.getTracks().forEach((track) => track.stop());
          setMediaRecorder(null);
        }
        if (recognizer) {
          recognizer.stopContinuousRecognitionAsync();
        }
        setIsRecording(false);
        setIsPaused(false);
        clearInterval(intervalRef.current);
        setAudioChunks([]);
        Swal.fire({
          title:
            "<span class='text-xl font-semibold text-[#f2911b] '>Error</span>",
          html: "Transcription text is empty. Please provide a transcription. Restart it?",
          icon: false,
          buttonsStyling: false,
          customClass: {
            title: "text-lg font-semibold mb-2",
            content: "text-sm",
            confirmButton:
              "bg-[#f2911b] hover:bg-gray-400 text-white font-bold py-2 px-4 rounded",
            popup: "max-w-xs",
          },
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        return;
      }

      // Process steps: Save and Upload with toast.promise
      await handleSave();

      const uploadToastId = toast.promise(
        handleUploadClick(selectedPatient),
        {
          pending: "Your request is being processed...",
          success: "Redirecting...",
          error: "Unable to process, please try again.",
        },
        { position: "top-center" }
      );

      toast.dismiss(uploadToastId);

      // Resetting the state and UI elements after upload
      setIsRecording(false);
      setIsPaused(false);
      setTime(0);
      setTranscript([]);
      setRealTimeTranscript("");
      setAudioChunks([]);
      setIsSubmitDisabled(false);
      setIsSubmitted(false);
      setIsRecordingSaved(false);

      if (audioRef.current) {
        audioRef.current.src = "";
      }

      setIsLiveRecordOpen(true);
      setShowOnlyHistory(true);

      // Navigate to the conversation page after the upload is complete
      history.push(`/conversation/${selectedPat?.id}`);
    } catch (error) {
      console.error("Error during submission:", error);
      toast.error(
        "There was an error processing your request. Please try again.",
        {
          position: "top-center",
          autoClose: 3000,
        }
      );
    }
  };

  const handleUploadClick = async (patient) => {
    if (!token) {
      alert("Please login to perform this action.");
      return;
    }

    // Combine the entries from finalTranscript into a single string
    const transcriptString = finalTranscript
      .map((entry) => `${entry.speaker}: ${entry.text}`)
      .join(" ");

    // Update the transcript state with the combined string
    setTranscript(transcriptString);

    // Check if the transcript is empty
    if (!transcriptString || transcriptString.trim().length === 0) {
      Swal.fire({
        title:
          "<span class='text-xl font-semibold text-[#f2911b] '>Error</span>",
        html: "Transcription text is empty. Please provide a transcription. Restart it?",
        icon: false,
        buttonsStyling: false,
        customClass: {
          title: "text-lg font-semibold mb-2",
          content: "text-sm",
          confirmButton:
            "bg-[#f2911b] hover:bg-gray-400 text-white font-bold py-2 px-4 rounded",
          popup: "max-w-xs",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(); // Refresh the current page
        }
      });

      return;
    }

    let formData = new FormData();

    if (profilePictureUrl) {
      formData.append("profile_picture", profilePictureUrl);
    }
    // Add conditional check
    let patientName =
      selectedPatient?.legal_name ||
      `${selectedPatient?.firstname || ""} ${
        selectedPatient?.lastname || ""
      }`.trim();
    let patientDOB =
      selectedPatient?.date_of_birth || selectedPatient?.dob || "1990-01-01";

    // Reformat patientDOB to YYYY-MM-DD if it's in MM/DD/YYYY format
    if (patientDOB && patientDOB.includes("/")) {
      const [month, day, year] = patientDOB.split("/");
      patientDOB = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
    if (
      patient &&
      patient.name &&
      patient.name[0] &&
      patient.name[0].given &&
      patient.name[0].family
    ) {
      patientName = `${patient.name[0].given[0]} ${patient.name[0].family}`;
      patientDOB = patient.birthDate;
    } else {
      console.warn("Invalid or missing patient data. Using default values.");
    }

    formData.append("patient_id", `${selectedPat?.id}`);
    if (transcriptString.trim().length > 0) {
      formData.append("transcription_text", transcriptString);
    } else {
      console.error("Transcription text is empty.");
      toast.error("Transcription text is empty.", {
        position: "top-right",
        autoClose: 500,
      });
      return;
    }

    try {
      const response = await axios.post(ENDPOINTS.POST_TRANSCRIBE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      });

      setTranscript(response.data.SpeechThread.text || []);
      setSpeechThreadId(response.data.SpeechThread.id);

      const speechThread = response.data.SpeechThread;
      setPostTitle(speechThread.title);
      setCreatedDate(speechThread.created_at);
      setPostPatientName(patientName);
      setDateOfBirth(patientDOB);
      setNotes(response.data.SpeechThread.notes || ""); // Store notes
      setIsPostGenerated(true);
      // Stop the recording and speech recognition
      if (mediaRecorder) {
        mediaRecorder.stop();
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
        setMediaRecorder(null);
      }

      if (recognizer) {
        recognizer.stopContinuousRecognitionAsync();
      }

      setIsRecording(false);
      setIsPaused(false);
      clearInterval(intervalRef.current);
      setAudioChunks([]);

      Swal.close(); // Close the Swal popup after generating the posts

      const url = localStorage.getItem("recording");

      if (!url) {
        console.error("No audio recording found.");
        return;
      }

      const blobResponse = await fetch(url);
      const audioBlob = await blobResponse.blob();

      let formData2 = new FormData();
      formData2.append("files", audioBlob);

      // const apiResponse = await axios.post(
      //   `${ENDPOINTS.POST_RECORDING_FILE}?session_id=${sessionID}&speechthread_id=${speechThreadID}`,
      //   formData2,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //       Authorization: "Bearer " + token,
      //     },
      //   }
      // );

      localStorage.setItem("transcriptionSuccess", "true");
      setShowHistory(true);
      setSpeechthreadData(response.data.SpeechThread);

      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    } catch (error) {
      console.error("Error in transcription:", error);
      Swal.fire({
        title: "Error",
        text:
          "Transcription failed: " +
          (error.response?.data?.error || error.message),
        icon: null,
        confirmButtonColor: "#f2911b",
        customClass: {
          popup: "max-w-xs",
        },
      });
    }

    setIsLiveRecordOpen(!isLiveRecordOpen);
    setIsImgOpen(!isImgOpen);
    setIsSubmitted(true);
  };

  const saveRecordingLocally = (chunks) => {
    const audioBlob = new Blob(chunks, { type: "audio/wav" });
    const url = URL.createObjectURL(audioBlob);
    localStorage.setItem("recording", url);
  };

  // PATIENT HISTORY DISPLAY

  const getPatientHistory = async (id) => {
    setIsLoading(true);

    try {
      const threadResponse = await axios.get(ENDPOINTS.GET_PATIENT_THREAD, {
        headers: { Authorization: `Bearer ${token}` },
        params: { patient_id: id },
      });

      setPatientThread(threadResponse.data);
      setPatientPlan(threadResponse.data.patient_plan || "N/A");

      const sessionsResponse = await axios.get(ENDPOINTS.GET_PATIENT_SESSIONS, {
        params: {
          patient_id: id,
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      setPatientSessions(sessionsResponse.data.results);
      setExpandedSessionId(sessionsResponse.data.results[0]?.id || null);
    } catch (error) {
      console.error("Error fetching history details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleSessionExpansion = (sessionId) => {
    setExpandedSessionId(expandedSessionId === sessionId ? null : sessionId);
  };

  const handleLoadMoreSessions = () => {
    setVisibleSessionsCount((prevCount) => prevCount + 5);
  };

  return (
    <div className="container mx-auto px-0">
      {selectedPat && (selectedPat.firstname || selectedPat.lastname) ? (
        <div
          className="patient-info bg-[#F2911B] text-white py-2 px-4 flex justify-center items-center"
          style={{
            backgroundColor: "#f2911b", // Custom orange color
          }}
        >
          <div className="flex flex-col lg:flex-row items-center text-sm sm:text-base lg:gap-8 gap-1">
            <span>
              <strong>Patient:</strong>{" "}
              {selectedPat.firstname + " " + selectedPat.lastname}
            </span>
            <span>
              <strong>DOB:</strong> {selectedPat.date_of_birth}
            </span>
          </div>
        </div>
      ) : null}

      <p
        className="text-lg font-semibold my-4 cursor-pointer"
        onClick={() =>
          profilePictureInputRef.current &&
          profilePictureInputRef.current.click()
        }
      >
        {profilePictureName && (
          <span className="ml-2 text-sm text-gray-600">
            {profilePictureName}
          </span>
        )}
        {profilePictureName && (
          <img
            alt="not found"
            src={URL.createObjectURL(uploadedFile.current)}
          />
        )}
      </p>
      <input
        type="file"
        ref={profilePictureInputRef}
        accept="image/*"
        className="hidden"
        onChange={handleProfilePictureChange}
      />

      <div className="flex flex-col items-center  mt-2  p-4 bg-[#E8ECF4] rounded-2xl">
        <div className="space-y-6 mx-auto w-full px-0">
          <div className="flex items-start">
            <div className="relative bg-white shadow-md rounded-3xl p-6 overflow-hidden w-full">
              <div
                className={`flex items-center justify-between mb-4 cursor-pointer ${
                  isLiveRecordOpen ? "bg-transparent" : ""
                }`}
                // onClick={toggleLiveRecordSection}
              >
                <div className="flex items-center justify-center w-full">
                  <p className="font-bold text-lg text-center">
                    {!isRecording
                      ? "Press Start for Listening"
                      : "Press Submit to Generate Clinical Notes"}
                  </p>
                </div>

                {/* <FontAwesomeIcon
                  icon={isLiveRecordOpen ? faAngleUp : faAngleDown}
                /> */}
              </div>
              {isLiveRecordOpen && (
                <div className="flex flex-col">
                  <div className="p-4 md:p-8 flex flex-col items-center justify-center w-full rounded-lg">
                    <div className="text-center w-full">
                      <div className="flex items-center justify-center mb-2">
                        <span className="text-xl font-bold">
                          {formatTime(time)}
                        </span>
                      </div>

                      <div className="flex flex-col items-center justify-center space-y-4 ">
                        <div className="flex items-center justify-center -space-x-3 lg:space-x-8 xl:space-x-8 2xl:space-x-8">
                          {!isSubmitted ? (
                            !isRecording && audioChunks.length === 0 ? (
                              <button
                                onClick={handleStart}
                                className="w-36 h-12 flex items-center justify-center bg-[#F2911B] rounded-full text-white text-2xl shadow-lg hover:bg-white hover:border-2 hover:border-[#F2911B] hover:text-[#F2911B] relative"
                                title="Click to start"
                                disabled={
                                  isRecordingSaved || isStartButtonDisabled
                                }
                              >
                                <FontAwesomeIcon icon={faMicrophone} />
                                <span className="ml-2">Start</span>
                              </button>
                            ) : (
                              <>
                                <button
                                  onClick={handlePause}
                                  disabled={isRecordingSaved} // Disable after recording is saved
                                  title={isPaused ? "Resume" : "Pause"}
                                  className={`flex items-center justify-center w-40 h-12 text-gray-700 text-xl transition-opacity rounded-full hover:bg-gray-200 border border-gray-500  ${
                                    isPaused
                                      ? "opacity-50 animate-pulse-2 bg-gray-200"
                                      : "opacity-100 bg-transparent"
                                  } ${
                                    isRecordingSaved
                                      ? "opacity-50 cursor-not-allowed"
                                      : ""
                                  }`}
                                >
                                  <FontAwesomeIcon icon={faPause} />
                                  <span className="ml-2">
                                    {isPaused ? "Resume" : "Pause"}
                                  </span>
                                </button>
                                <div className="relative flex items-center justify-center">
                                  <button
                                    onClick={Submit}
                                    className={`w-40 h-12  flex items-center justify-center bg-[#F2911B] rounded-full text-white text-xl shadow-lg z-10 mx-4 hover:animate-pulse hover:delay-200 ${
                                      isRecordingSaved
                                        ? "opacity-50 cursor-not-allowed"
                                        : ""
                                    }`}
                                    disabled={isRecordingSaved} // Disable Save button after saving
                                  >
                                    <FontAwesomeIcon icon={faStop} />
                                    <span className="ml-2">Submit</span>
                                  </button>
                                </div>
                              </>
                            )
                          ) : (
                            // Display the Reset button after submission
                            <button
                              onClick={handleReset}
                              className="w-40 h-12 flex items-center justify-center bg-[#F2911B] rounded-lg text-white text-xl shadow-lg z-10 hover:bg-white hover:border-2 hover:border-[#F2911B] hover:text-[#F2911B]"
                            >
                              <FontAwesomeIcon icon={faRedo} />
                              <span className="ml-2">Reset It</span>
                            </button>
                          )}
                        </div>

                        {audioChunks.length > 0 && (
                          <div className="mt-4 pt-8 hidden">
                            <button
                              onClick={handleUploadClick}
                              className={`bg-${
                                isSubmitDisabled
                                  ? "gray-400 cursor-not-allowed"
                                  : "[#F2911B]"
                              } text-white px-6 py-2 rounded-lg w-36 h-12 text-xl border-2 hover:text-[#f2911b] hover:bg-white hover:border-[#f2911b]`}
                              disabled={isSubmitDisabled} // Only disable if submission is in process
                            >
                              Submit
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <audio
                      ref={audioRef}
                      controls
                      className="mt-4 w-full hidden"
                    ></audio>
                  </div>
                  {isRecording ? (
                    <div
                      ref={transcriptContainerRef}
                      className="transcript-container scrollbar-transcript"
                      style={{
                        maxHeight: "300px",
                        overflowY: "auto",
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      {realTimeTranscript && realTimeTranscript.length > 0 ? (
                        realTimeTranscript.map((entry, index) => (
                          <div
                            key={index}
                            className="p-2 mb-2 rounded-lg border border-gray-300 text-gray-800"
                            style={{
                              backgroundColor: entry.bgColor || "#f0f0f0",
                            }}
                          >
                            {/* <strong className="block mb-1">{entry.speaker}:</strong> */}
                            <p className="ml-4">{entry.text}</p>
                          </div>
                        ))
                      ) : (
                        <div className="text-gray-500">
                          Loading transcription...
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="transcript-section">
                      {Array.isArray(finalTranscript) &&
                      finalTranscript.length > 0 ? (
                        <div>
                          {finalTranscript.map((entry, index) => (
                            <div
                              key={index}
                              className="p-2 mb-2 rounded-lg border border-gray-300 text-gray-800"
                              style={{
                                backgroundColor: entry.bgColor || "#f0f0f0", // Apply background color
                              }}
                            >
                              {/* <strong className="block mb-1">{entry.speaker}:</strong> */}
                              <p className="ml-4">{entry.text}</p>{" "}
                              {/* Indent text for better visual separation */}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="text-gray-500"></div>
                      )}
                    </div>
                  )}

                  <div className="flex flex-col items-center w-full">
                    <input
                      type="file"
                      ref={inputRef}
                      className="hidden"
                      onChange={handleFileChange}
                    />
                    <div className="flex flex-wrap  space-x-2 w-full ">
                      {files.map((file, index) => (
                        <div
                          key={index}
                          className="relative flex flex-col items-center mb-2"
                        ></div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isLoading && !patientThread && !patientSessions ? (
        <div className="w-7 h-7 border-4 border-t-4 border-t-transparent border-[#f2911b] rounded-full animate-spin"></div>
      ) : (
        <div className="flex flex-col items-center my-4 p-4 bg-[#E8ECF4] rounded-2xl">
          <div className="space-y-6 mx-auto w-full">
            <div className="relative bg-white shadow-md rounded-3xl p-6 overflow-hidden w-full">
              <h2 className="text-xl font-bold mb-4">Patient Plan</h2>
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-4">
                  <p className="w-full text-justify">{patientPlan}</p>
                </div>
              </div>
            </div>

            <div className="relative bg-white shadow-md rounded-3xl p-6 overflow-hidden w-full">
              <div className="w-full">
                <div>
                  {patientSessions.length !== 0 ? (
                    patientSessions
                      ?.slice(0, visibleSessionsCount)
                      .map((session) => (
                        <div
                          key={session.id}
                          className="mb-4 border rounded-md p-4"
                        >
                          <div
                            className="flex justify-between items-center cursor-pointer"
                            onClick={() => toggleSessionExpansion(session.id)}
                          >
                            <h3 className="text-lg font-semibold">
                              {moment(session.created_at).format(
                                "MMM DD, YYYY hh:mm A"
                              ) +
                                " - " +
                                session.title}
                            </h3>
                            <FontAwesomeIcon
                              icon={
                                expandedSessionId === session.id
                                  ? faChevronUp
                                  : faChevronDown
                              }
                            />
                          </div>
                          {expandedSessionId === session.id && (
                            <div
                              className="mt-4 w-full bg-gray-100 p-4 rounded"
                              style={{ minWidth: "full" }} // You can adjust the height as needed
                            >
                              {/* Session Content */}
                              <div
                                className="prose max-w-none leading-tight mt-4 space-y-0"
                                style={{ color: "black" }}
                                dangerouslySetInnerHTML={{
                                  __html: session.notes,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ))
                  ) : (
                    <p className="text-gray-400 my-4">No Sessions</p>
                  )}
                  {visibleSessionsCount < patientSessions.length && (
                    <button
                      onClick={handleLoadMoreSessions}
                      className="bg-[#f2911b] text-white px-4 py-2 rounded mt-4"
                    >
                      Load More
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecordScreen;
