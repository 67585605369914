import React, { useState, useEffect } from "react";
import "./LandingPage.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ENDPOINTS } from "../../apiConfig.js";
import AddPatientPopup from "../AddPatientPopup.jsx";
import { FiSearch, FiPlus } from "react-icons/fi";

const LandingPage = () => {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [patientData, setPatientData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const fetchData = async (url = null, search = "") => {
    try {
      setIsLoading(true);
      const response = await axios.get(ENDPOINTS.GET_PATIENT_PROFILE_LIST,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: search ? { search } : {},
        }
      );
      setData(response.data);
      setPatientData(
        url
          ? [...patientData, ...response.data.results.data]
          : response.data.results.data
      );
      setIsLoading(false);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleInputSearch = (e) => {
    setSearchTerm(e.target.value);
    fetchData(null, e.target.value);
  };

  const handleSearch = (e) => {
    fetchData(null, searchTerm);
  };

  const handlePatientSelect = (patientId) => {
    const selectedPat = patientData.find((patient) => patient.id === patientId);
    console.log("Selected Patient:", selectedPat);
    localStorage.setItem("Patient", JSON.stringify(selectedPat));
    history.push("/transcription");
  };

  const handlePatientAdded = (newPatient) => {
    setPatientData((prev) => [newPatient, ...prev]);
  };

  return (
    <div className="min-h-screen bg-[#f5f5f5] flex justify-center items-center">
      <div className="w-[90%] sm:w-[75%] md:w-[60%] lg:w-[40%] p-8 bg-white rounded-lg shadow-xl">
        <p className="sm:text-sm md:text-md lg:text-xl text-gray-600 mb-6">
          Please enter the patient's name to search, or click below to add a new
          patient.
        </p>
        <div className="flex flex-wrap gap-2 items-center mb-4 w-full">
          <input
            type="text"
            className="flex-grow px-4 py-2 border rounded-md outline-none"
            placeholder="Search Patient..."
            value={searchTerm}
            onChange={handleInputSearch}
          />
          <button
            className="bg-[#f2911b] hover:bg-[#f2911be5] text-2xl text-white font-bold py-2 px-4 rounded-md"
            title="Search"
            onClick={handleSearch}
          >
            <FiSearch className="" />
          </button>
          <button
            className="bg-[#f2911b] hover:bg-[#f2911be5] text-2xl text-white font-bold py-2 px-4 rounded-md"
            title="Add New Patient"
            onClick={() => setIsPopupOpen(true)}
          >
            <FiPlus className="" />
          </button>
        </div>

        {searchTerm && (
          <div className="overflow-x-auto landing-scrollbar max-h-[50vh]">
            <table className="w-full divide-y divide-white">
              <thead className="bg-[#f2911b] text-white">
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-semibold">
                    Patient Name
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-semibold">
                    Date of Birth
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-semibold">
                    Select
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
                {isLoading ? (
                  <tr>
                    <td
                      colSpan={4}
                      className="text-center py-4 flex justify-center items-center"
                    >
                      <div className="w-6 h-6 border-4 border-t-4 border-t-transparent border-[#f2911b] rounded-full animate-spin"></div>
                    </td>
                  </tr>
                ) : patientData.length > 0 ? (
                  patientData.map((patient, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {patient.firstname + " " + patient.lastname}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {patient.date_of_birth}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-4 py-1 inline-flex text-sm font-medium rounded-full">
                          <button
                            className="select-patient-btn px-3 py-1 bg-[#f2911b] hover:bg-gray-400 text-white font-bold rounded-sm"
                            data-id={patient.id}
                            onClick={() => handlePatientSelect(patient.id)}
                          >
                            Select
                          </button>
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      className="px-4 py-2 text-center text-gray-600"
                    >
                      No patients found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        {searchTerm && data?.next && (
          <button
            id="loadMoreBtn"
            className="mt-4 bg-[#f2911b] hover:bg-[#f2911be5] text-white font-bold py-2 px-4 rounded-sm mb-4 flex items-center justify-center"
            onClick={async () => {
              setIsButtonLoading(true);
              try {
                const response = await axios.get(data.next, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                });

                setData(response.data);

                setPatientData((prevData) => {
                  const previousDataArray = Array.isArray(prevData)
                    ? prevData
                    : [];
                  return [...previousDataArray, ...response.data.results.data];
                });
              } catch (err) {
                console.error("Error loading more data:", err.message);
              } finally {
                setIsButtonLoading(false);
              }
            }}
          >
            {isButtonLoading ? (
              <div className="w-5 h-5 border-4 border-t-4 border-t-transparent border-white rounded-full animate-spin"></div>
            ) : (
              "Load More"
            )}
          </button>
        )}
      </div>

      <AddPatientPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onPatientAdded={handlePatientAdded}
      />
    </div>
  );
};

export default LandingPage;
