import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FaEllipsisH,
  FaEye,
  FaEdit,
  FaTrash,
  FaUserPlus,
  FaTimes,
  FaArrowLeft,
} from "react-icons/fa";
import logo from "../../Assets/images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const ManagePatients = () => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [activeMenu, setActiveMenu] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [formData, setFormData] = useState({
    legal_name: "",
    address: "",
    first_name_used: "",
    date_of_birth: "",
    legal_sex: "",
    sexual_orientation: "",
    gender_identity: "",
    assigned_sex_at_birth: "",
    pronouns: "",
    marital_status: "",
    language: "",
    race: "",
    ethnicity: "",
    email: "",
    home_phone: "",
    mobile_phone: "",
    work_phone: "",
    emergency_contact_name: "",
    emergency_contact_relationship: "",
    emergency_contact_phone: "",
    emergency_contact_email: "",
    emergency_contact_address: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [editUserId, setEditUserId] = useState(null);

  const MySwal = withReactContent(Swal);
  const Dytoken = localStorage.getItem("token");
  const token = `Bearer ${Dytoken}`;
  const apiUrl =
    "https://astrascribe-india-be.azurewebsites.net/patient_profile_list/";

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async (searchTermParam = "", url = null) => {
    const search = searchTermParam || searchTerm;
    try {
      const requestUrl = url || apiUrl;
      const response = await axios.get(requestUrl, {
        headers: {
          Authorization: token,
          accept: "application/json",
        },
        params: url ? {} : { search: search },
      });

      // Update pagination info
      setNextPageUrl(response.data.next);

      const fetchedPatients = response.data.results.data;

      // If url is provided (i.e., loading more), append patients
      if (url) {
        setUsers((prevUsers) => [...prevUsers, ...fetchedPatients]);
        setFilteredUsers((prevUsers) => [...prevUsers, ...fetchedPatients]);
      } else {
        // New search or initial load
        setUsers(fetchedPatients);
        setFilteredUsers(fetchedPatients);
      }
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchTerm(query);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      // Reset users and fetch new data
      fetchUsers(query);
    }, 500);

    setDebounceTimeout(timeout);
  };

  const handleLoadMore = () => {
    if (nextPageUrl) {
      fetchUsers("", nextPageUrl);
    }
  };

  const toggleMenu = (id) => {
    if (activeMenu === id) {
      setActiveMenu(null);
    } else {
      setActiveMenu(id);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (!isModalOpen) {
      resetFormData();
      setEditMode(false);
    }
  };

  const resetFormData = () => {
    setFormData({
      legal_name: "",
      address: "",
      first_name_used: "",
      date_of_birth: "",
      legal_sex: "",
      sexual_orientation: "",
      gender_identity: "",
      assigned_sex_at_birth: "",
      pronouns: "",
      marital_status: "",
      language: "",
      race: "",
      ethnicity: "",
      email: "",
      home_phone: "",
      mobile_phone: "",
      work_phone: "",
      emergency_contact_name: "",
      emergency_contact_relationship: "",
      emergency_contact_phone: "",
      emergency_contact_email: "",
      emergency_contact_address: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      setUsers([...users, response.data]);
      setFilteredUsers([...users, response.data]);
      toggleModal();
      toast.success("Patient added successfully!");
    } catch (error) {
      console.error("Error adding patient:", error);
      if (error.response) {
        console.error("Full Error Response:", error.response);
        alert(
          `Failed to add patient: Add complete data you missed some fields`
        );
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    }
  };

  const handleEditUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${apiUrl}${editUserId}/update/`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      setUsers(
        users.map((user) => (user.id === editUserId ? response.data : user))
      );
      setFilteredUsers(
        users.map((user) => (user.id === editUserId ? response.data : user))
      );
      toggleModal();
      toast.success("Patient updated successfully!");
    } catch (error) {
      console.error("Error updating patient:", error);
    }
  };

  const openEditModal = (user) => {
    setFormData(user);
    setEditMode(true);
    setEditUserId(user.id);
    setIsModalOpen(true);
  };

  const handleDeleteUser = async (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${apiUrl}${id}/delete/`, {
            headers: {
              Authorization: token,
              accept: "application/json",
            },
          });
          setUsers(users.filter((user) => user.id !== id));
          setFilteredUsers(users.filter((user) => user.id !== id));
          MySwal.fire("Deleted!", "Patient has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting patient :", error);
          MySwal.fire(
            "Error!",
            "There was an error deleting the patient.",
            "error"
          );
        }
      }
    });
  };

  const [profileImage, setProfileImage] = useState(null);
  const [username, setUsername] = useState("");

  useEffect(() => {
    // Get profile image and username from localStorage
    const storedProfileImage = localStorage.getItem("GoogleProfileURL");
    const storedUsername = localStorage.getItem("Username");

    if (storedProfileImage) {
      setProfileImage(storedProfileImage);
    }

    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  const renderProfileIcon = () => {
    if (profileImage) {
      return (
        <img
          src={profileImage}
          alt="Profile"
          className="w-10 h-10 rounded-full"
        />
      );
    } else if (username) {
      return (
        <div className="bg-gradient-to-t from-orange-500 via-orange-400 to-orange-300  p-2 rounded-full w-10 h-10 flex items-center justify-center">
          <span className="text-white font-bold">
            {username.charAt(0).toUpperCase()}
          </span>
        </div>
      );
    } else {
      return (
        <div className="bg-gray-700 p-2 rounded-full w-10 h-10 flex items-center justify-center">
          <span className="text-white font-bold">F</span>
        </div>
      );
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <ToastContainer />
      {/* Navbar */}
      <nav className="bg-[#1E232C] p-4 flex justify-between items-center shadow-lg">
        <div>
          <img src={logo} alt="Helia Admin Logo" className="h-8" />
        </div>
        <div className="flex space-x-4 text-white">{renderProfileIcon()}</div>
      </nav>

      {/* Header with Search and Add Patient */}
      <div className="p-6 flex justify-between items-center bg-white shadow-md mb-4">
        <div className="flex items-center space-x-4">
          <h1 className="text-2xl font-bold hidden lg:block">
            Manage Patients
          </h1>
          <input
            type="text"
            placeholder="Search by Name or Date of Birth"
            value={searchTerm}
            onChange={handleSearch}
            className="border border-gray-300 rounded-lg py-2 px-4 focus:outline-none "
          />
        </div>
        <button
          className="bg-gradient-to-b from-orange-500 via-orange-400 to-orange-300 p-2  text-white px-4 py-2 rounded flex items-center space-x-2 hover:bg-gradient-to-t transition duration-300 shadow-md"
          onClick={toggleModal}
        >
          <FaUserPlus />
          <span className="hidden lg:block">Add Patient</span>
        </button>
      </div>
      <div>
        <Link
          to="/setting"
          className="text-[#f2911b] hover:underline flex items-center m-6"
        >
          <FaArrowLeft className="mr-2" /> Back
        </Link>
      </div>

      {/* Table */}
      <div className="p-6">
        <div className="overflow-x-auto rounded-lg">
          {/* Header Row */}
          <div className="grid grid-cols-5 gap-4 text-left border-b pb-2 mb-4 min-w-[600px]">
            <div className="text-gray-700 font-bold ml-8">Name</div>
            <div className="text-gray-700 font-bold">Date of Birth</div>
            <div className="text-gray-700 font-bold">Marital Status</div>
            <div className="text-gray-700 font-bold">Contact Phone</div>
            <div className="text-gray-700 font-bold text-right mr-8">
              Actions
            </div>
          </div>
          <div className="space-y-4 min-w-[600px]">
            {filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <div
                  key={user.id}
                  className="grid grid-cols-5 gap-4 items-center bg-white p-4 rounded-lg shadow-md border-l-4 hover:border-l-8 border-gray-300 hover:border-orange-500 transition-all group cursor-pointer"
                >
                  <div className="flex items-center space-x-4">
                    <div
                      className={`w-10 h-10 rounded-full bg-gray-200 group-hover:bg-[#f2911b] text-[#8f8c8c] group-hover:text-white font-bold flex items-center justify-center shadow-md transition-colors`}
                    >
                      {user.legal_name.charAt(0).toUpperCase()}
                    </div>
                    <span>{user.legal_name}</span>
                  </div>
                  <div className="text-gray-700">{user.date_of_birth}</div>
                  <div className="text-gray-700">{user.marital_status}</div>
                  <div className="text-gray-700">
                    {user.mobile_phone || user.home_phone || user.work_phone}
                  </div>
                  <div className="relative text-right mr-8">
                    <button
                      className="text-gray-500 hover:text-black transition-transform transform hover:scale-110"
                      onClick={() => toggleMenu(user.id)}
                    >
                      <FaEllipsisH />
                    </button>

                    {activeMenu === user.id && (
                      <div className="absolute right-0 mt-2 w-40 bg-white border rounded-lg shadow-lg z-10">
                        <button
                          className="flex items-center w-full px-4 py-2 text-gray-700 hover:bg-gray-100"
                          onClick={() => history.push(`/patient/${user.id}`)}
                        >
                          <FaEye className="mr-2" /> Show
                        </button>

                        <button
                          className="flex items-center w-full px-4 py-2 text-gray-700 hover:bg-gray-100"
                          onClick={() => openEditModal(user)}
                        >
                          <FaEdit className="mr-2" /> Edit
                        </button>
                        <button
                          className="flex items-center w-full px-4 py-2 text-red-600 hover:bg-gray-100"
                          onClick={() => handleDeleteUser(user.id)}
                        >
                          <FaTrash className="mr-2" /> Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500">No patients found.</div>
            )}
          </div>
          {nextPageUrl && (
            <div className="flex justify-center mt-4">
              <button
                className="bg-gradient-to-b from-orange-500 via-orange-400 to-orange-300 p-2 text-white px-4 py-2 rounded hover:bg-gradient-to-t transition duration-300 shadow-md"
                onClick={handleLoadMore}
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 overflow-y-auto">
          <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-4xl w-full">
            <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center bg-gradient-to-t from-orange-500 via-orange-400 to-orange-300 p-2 rounded">
              <h2 className="text-xl font-semibold ">
                {editMode ? "Edit Patient" : "Add New Patient"}
              </h2>

              <button
                onClick={toggleModal}
                className="text-gray-600 hover:text-gray-900"
              >
                <FaTimes size={20} />
              </button>
            </div>
            <div className="px-6 py-4 overflow-y-auto max-h-[70vh]">
              <form onSubmit={editMode ? handleEditUser : handleAddUser}>
                {/* Form Fields with Flexible Layout */}
                <div className="grid grid-cols-3 gap-4">
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">Legal Name</label>
                    <input
                      type="text"
                      name="legal_name"
                      value={formData.legal_name}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="Hassaan Gul"
                      required
                    />
                  </div>
                  <div className="mb-4 col-span-2">
                    <label className="block text-gray-700">Address</label>
                    <input
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="1234 Main St"
                      required
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">
                      First Name Used
                    </label>
                    <input
                      type="text"
                      name="first_name_used"
                      value={formData.first_name_used}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="John"
                    />
                  </div>
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">Date of Birth</label>
                    <input
                      type="date"
                      name="date_of_birth"
                      value={formData.date_of_birth}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      required
                    />
                  </div>
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">Legal Sex</label>
                    <input
                      type="text"
                      name="legal_sex"
                      value={formData.legal_sex}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="Male"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">
                      Sexual Orientation
                    </label>
                    <input
                      type="text"
                      name="sexual_orientation"
                      value={formData.sexual_orientation}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="Heterosexual"
                    />
                  </div>
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">
                      Gender Identity
                    </label>
                    <input
                      type="text"
                      name="gender_identity"
                      value={formData.gender_identity}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="Male"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">
                      Assigned Sex at Birth
                    </label>
                    <input
                      type="text"
                      name="assigned_sex_at_birth"
                      value={formData.assigned_sex_at_birth}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="Male"
                    />
                  </div>
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">Pronouns</label>
                    <input
                      type="text"
                      name="pronouns"
                      value={formData.pronouns}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="He/Him"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">
                      Marital Status
                    </label>
                    <input
                      type="text"
                      name="marital_status"
                      value={formData.marital_status}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="Single"
                    />
                  </div>
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">Language</label>
                    <input
                      type="text"
                      name="language"
                      value={formData.language}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="English"
                    />
                  </div>
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">Race</label>
                    <input
                      type="text"
                      name="race"
                      value={formData.race}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="Asian"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">Ethnicity</label>
                    <input
                      type="text"
                      name="ethnicity"
                      value={formData.ethnicity}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="Not Hispanic or Latino"
                    />
                  </div>
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="user@example.com"
                      required
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">Home Phone</label>
                    <input
                      type="text"
                      name="home_phone"
                      value={formData.home_phone}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="(123) 456-7890"
                    />
                  </div>
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">Mobile Phone</label>
                    <input
                      type="text"
                      name="mobile_phone"
                      value={formData.mobile_phone}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="(123) 456-7890"
                    />
                  </div>
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">Work Phone</label>
                    <input
                      type="text"
                      name="work_phone"
                      value={formData.work_phone}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="(123) 456-7890"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">
                      Emergency Contact Name
                    </label>
                    <input
                      type="text"
                      name="emergency_contact_name"
                      value={formData.emergency_contact_name}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="Jane Doe"
                    />
                  </div>
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">
                      Emergency Contact Relationship
                    </label>
                    <input
                      type="text"
                      name="emergency_contact_relationship"
                      value={formData.emergency_contact_relationship}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="Spouse"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">
                      Emergency Contact Phone
                    </label>
                    <input
                      type="text"
                      name="emergency_contact_phone"
                      value={formData.emergency_contact_phone}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="(123) 456-7890"
                    />
                  </div>
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">
                      Emergency Contact Email
                    </label>
                    <input
                      type="email"
                      name="emergency_contact_email"
                      value={formData.emergency_contact_email}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="user@example.com"
                    />
                  </div>
                  <div className="mb-4 col-span-1">
                    <label className="block text-gray-700">
                      Emergency Contact Address
                    </label>
                    <input
                      type="text"
                      name="emergency_contact_address"
                      value={formData.emergency_contact_address}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-lg py-2 px-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      placeholder="1234 Main St"
                    />
                  </div>
                </div>
                <div className="flex justify-end space-x-2">
                  <button
                    className="bg-gradient-to-b from-orange-500 via-orange-400 to-orange-300 p-2  text-white px-4 py-2 rounded hover:bg-gradient-to-t transition duration-300"
                    type="submit"
                  >
                    {editMode ? "Update" : "Save"}
                  </button>
                  <button
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition duration-300"
                    type="button"
                    onClick={toggleModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManagePatients;
